import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Visitor {
  _id: string;
  staff: string;
  visitorName: string;
  visitorEmail: string;
  reasonForVisiting: string;
  timeOfVisiting: string;
  status: string;
  organization: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface VisitorsState {
  visitors: Visitor[];
  page: number;
  resultsPerPage: number;
  totalPages: number;
}

const initialState: VisitorsState = {
  visitors: [],
  page: 1,
  resultsPerPage: 10,
  totalPages: 1,
};

const visitorsFilterSlice = createSlice({
  name: 'visitors',
  initialState,
  reducers: {
    SET_VISITORS: (state, action: PayloadAction<Visitor[]>) => {
      state.visitors = action.payload;
    },
    SET_PAGE: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    SET_RESULT_PERPAGE: (state, action: PayloadAction<number>) => {
      state.resultsPerPage = action.payload;
    },
    SET_TOTAL_PAGES: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
  },
});

export const { SET_VISITORS, SET_PAGE, SET_RESULT_PERPAGE, SET_TOTAL_PAGES } = visitorsFilterSlice.actions;
export default visitorsFilterSlice.reducer;
