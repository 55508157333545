import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TextField, Button } from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircleLoader from "react-spinners/CircleLoader";

const initialState = {
  message: "",
  department: [],
};

const Broadcast = () => {
  type Department = {
    _id: string;
  };

  
  type Level = {
    _id: string;
  };

  const departments = useSelector(
    (state: any) => state.departments?.departments?.data
  );

  // const expiry = 10 * 60 * 1000;

  // useEffect(() => {
  //   dispatch<any>(setTokenWithExpiry(token, expiry)); // Explicitly type dispatch as 'any'
  // }, [dispatch, token, expiry]);

  const deptIds: Department[] = departments;

  const levels = useSelector((state: any) => state.levels?.levels?.data);
 const levelIds: Level[] = levels;

  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
  const [selectAllDepartments, setSelectAllDepartments] = useState(false);
  const [selectAllLevels, setSelectAllLevels] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const { message, department } = formData;
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const handleDepartmentCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const departmentId = e.target.value;
    if (e.target.checked) {
      setSelectedDepartments((prevDepartments) => [
        ...prevDepartments,
        departmentId,
      ]);
    } else {
      setSelectedDepartments((prevDepartments) =>
        prevDepartments.filter((id) => id !== departmentId)
      );
    }
  };

  useEffect(() => {
    setValue("department", selectedDepartments);
  }, [selectedDepartments]);

  useEffect(() => {
    const allDepartmentsSelected =
      selectedDepartments.length === deptIds.length;
    setSelectAllDepartments(allDepartmentsSelected);
  }, [selectedDepartments, deptIds]);

  const handleSelectAllDepartmentsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      setSelectedDepartments(deptIds.map((department) => department._id));
    } else {
      setSelectedDepartments([]);
    }
  };

  const handleLevelCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const levelId = e.target.value;
    if (e.target.checked) {
      setSelectedLevels((prevLevels) => [...prevLevels, levelId]);
    } else {
      setSelectedLevels((prevLevels) =>
        prevLevels.filter((id) => id !== levelId)
      );
    }
  };

   useEffect(() => {
     setValue("level", selectedLevels);
   }, [selectedLevels]);

   useEffect(() => {
     const allLevelsSelected = selectedLevels.length === levelIds.length;
     setSelectAllLevels(allLevelsSelected);
   }, [selectedLevels, levelIds]);

  
const handleSelectAllLevelsChange = (
  e: React.ChangeEvent<HTMLInputElement>
) => {
  if (e.target.checked) {
    setSelectedLevels(levelIds.map((level) => level._id));
  } else {
    setSelectedLevels([]);
  }
};

  const handleBroadcastSubmit = handleSubmit(async (formData) => {
     setLoading(true);
    const userData = {
      message: formData.message,
      department: selectedDepartments,
      level: selectedLevels,
    };


    reset();
    setSelectedDepartments([]);
    setSelectedLevels([]);
    setLoading(false);
  });


  return (
    <div>
      <div className="adminDash profilePage  broadcast-message-page mt-[42px] pt-[80px]">
        <div className="w-[47%] profile-form-container bg-white rounded-[10px] shadow-md border-0 mx-auto  mb-[86.53px] px-[31px] py-[34px] broadcast-form-container ">
          <h1 className="mb-[38px] text-[#44566C] text-[30px] font-bold text-center">
            Broadcast Message
          </h1>
          <form className="block" onSubmit={handleBroadcastSubmit}>
            <div>
              <label className="block text-[#8697A8] font-normal text-[15px] mb-3">
                Message
              </label>
              <Controller
                name="message"
                control={control}
                defaultValue=""
                rules={{
                  required: "Broadcast message is required",
                  minLength: {
                    value: 3,
                    message: "Broadcast message must be at least 3 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={5}
                    placeholder="Enter your message here"
                    className="w-full px-[16px] py-[13px] rounded text-[#44566C] broadcast-message-textfield"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="flex justify-between broadcast-checkbox-content h-[150px] mt-[38px] p-[10px] border border-solid border-[#e2e3e4] rounded-[8px]">
              <div>
                <Controller
                  name="department"
                  control={control}
                  rules={{ required: "Select at least one department" }}
                  render={({ field, fieldState }) => (
                    <div>
                      {fieldState.error && (
                        <FormHelperText error>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                      <FormGroup className="font-normal text-[15px] block">
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                              checked={selectAllDepartments}
                              onChange={handleSelectAllDepartmentsChange}
                            />
                          }
                          label="Select All Departments"
                          className="text-[#44566C] text-[14px] font-medium"
                        />
                        {deptIds?.map((department: any, _id: any) => (
                          <FormControlLabel
                            key={_id}
                            control={
                              <Checkbox
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                                value={department?._id}
                                checked={selectedDepartments.includes(
                                  department?._id
                                )}
                                onChange={handleDepartmentCheckboxChange}
                              />
                            }
                            className="capitalize text-[#44566C] text-[14px] font-medium"
                            label={department?.departmentName}
                          />
                        ))}
                      </FormGroup>
                    </div>
                  )}
                />
              </div>

              <div>
                <Controller
                  name="level"
                  control={control}
                  rules={{ required: "Select at least one level" }}
                  render={({ field, fieldState }) => (
                    <div>
                      {fieldState.error && (
                        <FormHelperText error>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                      <FormGroup className="font-normal text-[15px] block">
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                              checked={selectAllLevels}
                              onChange={handleSelectAllLevelsChange}
                            />
                          }
                          label="Select All Levels"
                          className="text-[#44566C] text-[14px] font-medium"
                        />
                        {levelIds?.map((levelIdss: any, _id: any) => (
                          <FormControlLabel
                            key={_id}
                            control={
                              <Checkbox
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                                value={levelIdss?._id}
                                checked={selectedLevels.includes(
                                  levelIdss?._id
                                )}
                                onChange={handleLevelCheckboxChange}
                              />
                            }
                            className="capitalize text-[#44566C] text-[14px] font-medium"
                            label={levelIdss?.levelName}
                          />
                        ))}
                      </FormGroup>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="w-full flex justify-center">
              <button
                className="mt-[38px]  w-[167px] h-[44px] bg-[#0081FF] rounded-[5px] text-[#fff] text-[15px] font-medium cursor-pointer hover:opacity-80"
                style={{
                  boxShadow: "0px 8px 16px 0px rgba(0, 129, 255, 0.20)",
                }}
              >
                {loading ? (
                  <CircleLoader color="#ffffff" loading={loading} size={20} />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Broadcast;
