import axios from "axios";
import { toast } from "react-toastify";

export const Backend_URL = process.env.REACT_APP_AUTH_URL;

export const emailValidation = (email: any) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  
  export const addNewSchedule = async (userData: any, token: any) => {
    try {
      const response = await axios.post(
        `${Backend_URL}/visit`,
        userData,
        {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": 'application/json'
            }
          }
      );
      if (response.status >= 200 && response.status < 300) {
        toast.success("Visitor added successfully");
      }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
         if (error?.response?.data?.error) {
           toast.error(error?.response?.data?.error);
         } else {
          toast.error(message); 
          throw new Error(message); 
         }
    }
  };

    
  export const fetch1DaySchedule = async (token: any) => {
    try {
      const response = await axios.get(
        `${Backend_URL}/visit/today`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        }
      );
      // if (response.statusText === "OK") {
      //   toast.success("Successful...");
      // }
      return response.data;
    }  catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        // toast.error("Failed");
      throw error;
    }
  };

  export const fetchAllSchedule = async (token: any) => {
    try {
      const response = await axios.get(
        `${Backend_URL}/visit`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        }
      );
      return response.data;
    }  catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        // toast.error("Failed");
      throw error;
    }
  };
  
  export const visitorStatus = async (userData: any, token: string,) =>{
    try {
      const response = await axios.put(
        `${Backend_URL}/visit/${userData.id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        }
      );
    
      if (response.statusText === "OK") {
        toast.success("Update Successful");
      }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        // toast.error("Failed to Update. You cannot change the status");
        throw error;
    }
  };

  export const createVisitor = async (userData: any, token: any) => {
    try {
      const response = await axios.post(
        `${Backend_URL}/visit/${userData.id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        }
      )
      if (response.status >= 200 && response.status < 300) {
        toast.success('Schedule created successfully');
      }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error);
      } else {
        toast.error(message); 
        throw new Error(message); 
      }
      //  toast.error('Schedule already exists');
    }
  };

  export const createVisitorLink = async (token: any) => {
    try {
      const response = await axios.post(
        `${Backend_URL}/visit/link`,{},
        {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": 'application/json'
            }
          }
      )
      if (response.status >= 200 && response.status < 300) {
        toast.success('Link created successfully');
      }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        toast.error(message); 
        throw new Error(message); 
    }
  };

  export const getVisitorLink = async (token: any) => { 
    try {
      const response = await axios.get(`${Backend_URL}/visit/link`,
       {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      }
       );
      //  if (response.statusText === "OK") {
      //   toast.success("successful...");
      // }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      // toast.error('Failed. Try again');
    }
  };

  export const deleteVisitorLink = async (token: any) => { 
    try {
      const response = await axios.delete(`${Backend_URL}/visit/link`,
       {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      }
       );
       if (response.status >= 200 && response.status < 300) {
        toast.success(" Link deleted successfully");
      }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error);
      } else {
        toast.error(message); 
        throw new Error(message); 
      }
    }
  };

  export const verifyVisitLink = async (org: any) => {
    try {
      const response = await axios.get(`${Backend_URL}/visit/link/verify`, {
        params: {
          org: org,
        },
      });
  
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        
  
      // Handle error...
    }
  };

  export const visitFilter = async (token: string, startDate?: any, endDate?: any, status?: string,  page?: number, limit?: number) => {
    try {
      const queryParams = new URLSearchParams();
      if (startDate) queryParams.append('start', startDate);
      if (endDate) queryParams.append('end', endDate);
      if (status) queryParams.append('status', status);
      if (page) queryParams.append('page', String(page));
      if (limit) queryParams.append('limit', String(limit));
      const response = await axios.get(
        `${Backend_URL}/visit/filter?${queryParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      // if (response.statusText === "OK") {
      //   toast.success("Successful...");
      // }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      //   toast.error('Failed. Try again');
      throw error;
    }
  };
  
  export const visitFilters = async (token: any, search?: string, status?: string,  page?: number, limit?: number, ) => {
    try {
      const queryParams = new URLSearchParams();
      if (search) queryParams.append('search', search);
      if (status) queryParams.append('status', status);
      if (page) queryParams.append('page', String(page));
      if (limit) queryParams.append('limit', String(limit));
      const response = await axios.get(
        `${Backend_URL}/visit/filter?${queryParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      //   toast.error('Failed. Try again');
      throw error;
    }
  };

  export const visitReceptionistFilter = async (token: string, startDate?: any, endDate?: any, status?: string,  page?: number, limit?: number) => {
    try {
      const queryParams = new URLSearchParams();
      if (startDate) queryParams.append('start', startDate);
      if (endDate) queryParams.append('end', endDate);
      if (status) queryParams.append('status', status);
      if (page) queryParams.append('page', String(page));
      if (limit) queryParams.append('limit', String(limit));
      const response = await axios.get(
        `${Backend_URL}/visit/filter?${queryParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      // if (response.statusText === "OK") {
      //   toast.success("Successful...");
      // }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      //   toast.error('Failed. Try again');
      throw error;
    }
  };
  
  export const filterGraph = async (token: string, startDate?: any, endDate?: any) => {
    try {
      const queryParams = new URLSearchParams();
      if (startDate) queryParams.append('start', startDate);
      if (endDate) queryParams.append('end', endDate);
  
      const response = await axios.get(
        `${Backend_URL}/visit/graph?${queryParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      // if (response.statusText === "OK") {
      //   toast.success("Successful...");
      // }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      throw error;
    }
  };

  export const visitStat = async (token: any, type:any) => {
    try {
      const response = await axios.get(`${Backend_URL}/visit/stats?type=${type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.statusText === "OK") {
      
      }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        // toast.error("Failed to Update. You cannot change the status");
        // throw error;
    }
  };