import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import { CloseIcon } from "../../icons/icon";
import { useModalContext } from "../../components/utilities/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import CircleLoader from "react-spinners/CircleLoader";
import { set_Link } from "../../redux/features/LinkSlice";
import {
  createVisitorLink,
  deleteVisitorLink,
  getVisitorLink,
} from "../../services/VisitorService";
import { FaRegCopy } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";

const CreateVisitLink = () => {
  const { modalIsOpened, modalWillClose, modalWillOpen } = useModalContext();
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const dispatch = useDispatch();

  const token = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });
  const role = useSelector((state: any) => state.auth?.user?.role);
  const secretaryUser = role === "secretary";
  const adminUser = role === "admin";

  const tokenFromStorage = localStorage.getItem('token') || null;

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      let tokenToUse;

     
      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const data = await createVisitorLink( tokenToUse);
      dispatch(set_Link(data));
      await fetchLink();
      setLoading(false);
      // window.location.reload();
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchLink = async () => {
    try {
      let tokenToUse;

     
      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const urlLink = await getVisitorLink( tokenToUse);
      setLink(urlLink?.data?.url);
    } catch (err) {}
  };
  useEffect(() => {
    fetchLink();
  }, []);

  const copyToClipboard = () => {
    if (link) {
      const textArea = document.createElement("textarea");
      textArea.value = link;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);

      setIsCopied(true);
      toast.success("Link Copied to Clipboard!");

      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  const handleDeleteSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      let tokenToUse;

     
      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const data = await deleteVisitorLink(tokenToUse);
      setLoading(false);
      if (data.status === true) {
        setLink("");
      }
      //   window.location.reload();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <button
        className="bg-[#0081ff] w-[170px] h-[40px] text-center rounded text-white shadow-md mr-7 cursor-pointer hover:opacity-80"
        onClick={modalWillOpen}
      >
        Create a Visitor Link
      </button>
      <Modal
        isOpen={modalIsOpened}
        onRequestClose={modalWillClose}
        contentLabel="Add New Staff Modal"
        className="w-[42%] rounded-[12px] py-[45px] bg-[white] max-w-[880px] create-dept-modal"
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className="mx-auto z-50 w-[79.5%] justify-center justify-items-center">
          <div className="flex justify-end mb-[30px]">
            <div
              onClick={() => {
                modalWillClose();
                // setName("");
              }}
              className="cursor-pointer"
            >
              <CloseIcon />
            </div>
          </div>
          <h1 className="text-center text-[#44566c] text-3xl  font-bold">
            Create a<span className="text-[#0081ff]"> Link</span>
          </h1>
          <div className=" h-[47px] bg-[#0081ff] pt-2.5 text-center text-white rounded-[5px] mt-[21px]">
            <button className="text-[18px] w-full" onClick={handleSubmit}>
              {loading ? (
                <CircleLoader color="#ffffff" loading={loading} size={20} />
              ) : (
                "Create"
              )}
            </button>
          </div>
          <div className="flex">
            {link && (
              <div className="gap-4 w-full">
                <div className="w-full flex mt-[20px] bg-[#F8FAFB] h-[42px] rounded items-center px-[19px] py-2 pr-[11px]  text-[#44566C] text-[15px] font-normal">
                  <input
                    ref={inputRef}
                    value={link}
                    className="flex-1 bg-[#F8FAFB] mr-2 py-1"
                    onClick={() => inputRef?.current?.select()}
                  />
                  <Tooltip title="Copy" arrow>
                    <button
                      onClick={copyToClipboard}
                      className="copy-to-clipboard-btn px-2 h-full"
                    >
                      <FaRegCopy />
                    </button>
                  </Tooltip>
                </div>
                {/* <button
                  className="bg-[#0081ff] w-20 rounded text-white text-center h-8 text-sm relative btn"
                  onClick={copyToClipboard}
                >
                  {isCopied ? "Copied" : "Copy Link"}
                  <span className="tooltip absolute bottom-full left-1/2 transform -translate-x-1/2  text-white px-2 py-1 rounded opacity-0 transition-opacity duration-300">
                    {isCopied ? "Link Copied" : "Copy to Clipboard"}
                  </span>
                </button> */}
                <button
                  onClick={handleDeleteSubmit}
                  className="bg-[#FF0101] px-[20px] rounded text-center h-[42px] text-sm relative mt-[20px] text-[18px] text-[#FCFCFD]"
                >
                  Delete Link
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateVisitLink;
