import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import defaultImage from "../../icons/image 89.png";
import binIcon from "../../icons/bin 1.svg";
import { toast } from "react-toastify";
import CircleLoader from "react-spinners/CircleLoader";
import axios from "axios";
import { updateLogo, updateName } from "../../services/ProfileService";
import {
  LOGOUT,
  selectToken,
  setTokenWithExpiry,
  UPDATE_NAME,
  UPDATE_LOGO,
} from "../../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import UpdateEditDeleteDepartmentLevel from "./update";
export const Backend_URL = process.env.REACT_APP_AUTH_URL;

const initialState = {
  id: "",
};

const Upload = () => {
  const companyLogo = useSelector(
    (state: any) => state.auth?.user?.organization?.logo
  );

  //SignUp
  const companyIcon = useSelector(
    (state: any) => state.auth?.newOrganization?.logo
  );
  const [selectedItemPopUpId, setSelectedItemPopUpId] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(companyLogo || companyIcon);

  const [logo, setLogo] = useState("");
  const [names, setName] = useState("");

  const [formData, setFormData] = useState(initialState);
  const {  id } = formData;
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const companyEmail = useSelector(
    (state: any) => state.auth?.user?.organization?.email
  );

  const companyName = useSelector(
    (state: any) => state.auth?.user?.organization?.organizationName
  );

  //SIGNUP
  const companyNames = useSelector(
    (state: any) => state.auth?.newOrganization?.organizationName
  );
  const name = companyNames;


  const companyMail = useSelector(
    (state: any) => state.auth?.newOrganization?.email
  );
  const mail = companyMail;

  


  const token = useSelector((state: any) => {
    if (state?.auth?.isLoggedIn) {
      return state?.auth?.token;
    } else {
      return null;
    }
  });

  const adminSignUp = useSelector(
    (state: any) => state.auth?.newOrganization?.admin?.role
  );
  const role = useSelector((state: any) => state.auth?.user?.role);
    
  const secretaryUser = role === "secretary";
  const adminUser = role === "admin";

  const tokenFromStorage = localStorage.getItem("token") || null;

  const expiry = 47 * 60 * 60 * 1000 + 50 * 60 * 1000;

  useEffect(() => {
    let tokenToUse;

    // Check if the user has signed up (using adminSignUp)
    if (adminSignUp === true) {
      // User has signed up
      tokenToUse = tokenFromStorage;
    } else {
      // User has logged in
      tokenToUse = token;
    }
    dispatch<any>(
      setTokenWithExpiry(tokenToUse, expiry, handleLogoutAndNavigate)
    );
  }, [dispatch, token, expiry]);

  const handleLogoutAndNavigate = () => {
    dispatch(LOGOUT());
    navigate("/login"); // Navigate to the login page when session expires
  };

  const handleImageDelete = () => {
    if (!uploadedImage) {
      toast.error("No image to delete");
      return;
    }
    setUploadedImage("");
    toast.success("Image Deleted Successfully");
  };

  const uploadImageToCloudinary = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];

      setImageLoading(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (!file) {
        return null;
      }
      const dataForm = new FormData();
      dataForm.append("files", file);

      try {
        const response = await axios.post(
          `${Backend_URL}/upload`,
          dataForm,
          config
        );
        const uploadedImageUrl = response.data.data[0];
        if (response.data.status === true) {
          toast.success("Image uploaded successfully");
          setUploadedImage(uploadedImageUrl);
        }
        setImageLoading(false);
      } catch (error) {
        setImageLoading(false);
        toast.error('Image size exceeds 5mb, please reduce image size and try again.');
      }
    } else {
      toast.error("Please upload an image");
    }
  };

  const handleUploadForm = handleSubmit(async (formData) => {
    let logoBeingUploaded = uploadedImage;

    if (!logoBeingUploaded && companyLogo) {
      logoBeingUploaded = companyLogo;
      setUploadedImage(logoBeingUploaded);
    }

    const nameData = {
      name: formData.name,
    };

    const logoData = {
      logo: logoBeingUploaded,
    };
    setUpdateLoading(true);
    const updateNameAsync = () =>
      new Promise((resolve, reject) => {
        let tokenToUse;
      if (adminUser) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
        const newOrgName = updateName(nameData, tokenToUse);
        resolve(newOrgName);
      }).catch((error) => {
        setUpdateLoading(false);
        // toast.error("An error occurred");
      });

    const updateLogoAsync = () =>
      new Promise((resolve, reject) => {
        let tokenToUse;

     
      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
       
        tokenToUse = tokenFromStorage;
      }
        const newLogo = updateLogo(
          logoData,
          tokenToUse
        );
        resolve(newLogo);
        
      }).catch((error) => {
        setUpdateLoading(false);
        toast.error("An error occurred");
      });
    const promises = [];
    promises.push(updateNameAsync());
    promises.push(updateLogoAsync());
    await Promise.all(promises).then((values) => {
      const logo: any = values.find(
        (value: any) =>
          value?.message === "Organization logo updated successfully"
      );
      const name: any = values.find(
        (value: any) =>
          value?.message === "Organization name updated successfully"
      );
      dispatch(
        UPDATE_NAME(
          name?.data?.organization?.organizationName ||
            name?.data?.newOrganization?.organizationName
        )
      );
      dispatch(UPDATE_LOGO(logo?.data?.organization?.logo));
      setUpdateLoading(false);
    });
  });

  const handleTokenExpiration = () => {
    dispatch({
      type: selectToken,
      navigateCallback: () => {
        // Use the navigate function from react-router-dom to redirect to the login page
        navigate("/login");
      },
    });
  };
  useEffect(() => {
    handleTokenExpiration();
  }, []);

  const departments = useSelector(
    (state: any) => state.departments?.departments?.data
  );
  const deptIds = departments;


  const levels = useSelector((state: any) => state.levels?.levels?.data);
  const levelIds = levels;

  const popUpRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(popUpRef, () => {
    setSelectedItemPopUpId("");
  });



  return (
    <div>
      <div className="w-full bg-white rounded-[20px] py-[22px] pr-[24px] pl-[14px] text-[#44566C] mt-[59px]">
        <p className="ml-[10px] text-[#44566C] font-semibold text-[17px]">
          Company Details
        </p>
        <form>
          <div className="mt-8 flex items-end upload-picture-container">
            <div style={{ position: "relative" }}>
              <img
                src={uploadedImage ? uploadedImage : defaultImage}
                alt="default-image"
                width={252}
                height={228}
                className="rounded-[12px] shadow"
                style={{ maxWidth: "252px", height: "228px" }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "14px",
                  left: "14px",
                  cursor: "pointer",
                }}
                className="bg-white rounded-[12px] p-[13px] shadow-lg"
                onClick={handleImageDelete}
              >
                <img src={binIcon} alt="bin-icon" width={24} height={24} />
              </div>
            </div>
            <div className="ml-[31px] btn-profile">
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="contained-button-file"
                onChange={uploadImageToCloudinary}
              />
              <label htmlFor="contained-button-file">
                <Button className="upload-profile-btn" component="span">
                  {imageLoading ? (
                    <CircleLoader
                      color="#0081ff"
                      loading={imageLoading}
                      size={20}
                    />
                  ) : (
                    "Upload Profile File"
                  )}
                </Button>
              </label>
              <p className="mt-[31px] text-[#44566C] text-sm font-normal">
                Photos must be JPEG or PNG format and at least 5MB
              </p>
            </div>
          </div>
          <div className="flex justify-between mt-8 font-normal text-[15px] ml-[10px] upload-text-container">
            <div className="w-[42%]">
              <label className="block mb-2 text-[#8697A8]">Company Name</label>
              <Controller
                name="name"
                control={control}
                defaultValue={companyName ? companyName : name}
                rules={{
                  required: "Company Name is required",
                  minLength: {
                    value: 3,
                    message: "Comapany Name must be at least 3 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Company Name"
                    className="px-[19px] py-3 rounded no-outline"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="w-[42%]">
              <label className="block mb-2 text-[#8697A8]">Email</label>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    className="px-[19px] py-3 rounded no-outline"
                    value={companyEmail ? companyEmail : mail}
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                )}
              />
            </div>
          </div>
          <div className="mt-8 flex justify-center items-center">
            <Button
              className="profile-page-btn mx-auto"
              onClick={handleUploadForm}
            >
              {updateLoading ? (
                <CircleLoader
                  color="#ffffff"
                  loading={updateLoading}
                  size={20}
                />
              ) : (
                "Update"
              )}
            </Button>
          </div>
        </form>
      </div>
      <UpdateEditDeleteDepartmentLevel />
    </div>
  );
};

export default Upload;
