import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StaffData {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  levelId: string;
  departmentId: string;
}

interface AddStaffState {
  newStaff: StaffData | null;
}

const initialState: AddStaffState = {
  newStaff: null,
};

const addStaffSlice = createSlice({
  name: "addStaff",
  initialState,
  reducers: {
    SET_NEW_STAFF: (state, action: PayloadAction<StaffData>) => {
      state.newStaff = action.payload;
    },
    CLEAR_NEW_STAFF: (state) => {
      state.newStaff = null;
    },
  },
});

export const { SET_NEW_STAFF, CLEAR_NEW_STAFF } = addStaffSlice.actions;

export default addStaffSlice.reducer;
