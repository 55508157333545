import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface Secretary {
  _id: string;
  email: string;
  role: string;
  organization: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  status: string;
}

interface SecretaryState{
  secretaries: Secretary[];
  }

  const initialState: SecretaryState = {
    secretaries: [],
  };

  const secSlice = createSlice({
    name: "secretary",
    initialState,
    reducers: {
      SET_SECRETARY: (state, action: PayloadAction<Secretary[]>) => {
        state.secretaries = action.payload;
      },
    },
  });

  export const {SET_SECRETARY} = secSlice.actions;

  export default secSlice.reducer
