import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CreateDept from "../create/CreateDept";
import Cards from "./Cards";
import Cards2 from "./Cards2";
import AreaChart from "../../components/utilities/AreaChart";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CreateLevels from "../create/CreateLevels";
import { CloseIcon } from "../../icons/icon";
import {
  fetchAllSchedule,
  filterGraph,
  visitStat,
} from "../../services/VisitorService";
import { SET_ALL_SCHEDULE } from "../../redux/features/allSchedules1DaySlice";
import { fetchStaff } from "../../services/staffService";
import { SET_ALL_STAFF } from "../../redux/features/allStaffSlice";
import { useModalContext } from "../../components/utilities/ModalContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LOGOUT, setTokenWithExpiry } from "../../redux/features/authSlice";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { Controller, useForm } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { isAfter, isToday } from "date-fns";

interface GraphDataItem {
  date: string;
  count: number;
}

interface GraphData {
  status: boolean;
  message: string;
  data: {
    graph: GraphDataItem[];
    visits: number;
    staff: number;
  };
}
interface CardData {
  visits: number;
  cancelled: number;
  absent: number;
  staff: number;
}

const AdminDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState<GraphData | null>(null);
  const [filteredTodayData, setFilteredTodayData] = useState<GraphData | null>(
    null
  );
  const [cardData, setCardData] = useState<CardData>({
    visits: 0,
    cancelled: 0,
    absent: 0,
    staff: 0,
  });
  const navigate = useNavigate();
  const { filterChartOpen, modalFilterOpen, modalFilterClose } =
    useModalContext();
  const dispatch = useDispatch();
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const role = useSelector((state: any) => state.auth?.user?.role);

  const secretaryUser = role === "secretary";
  const adminUser = role === "admin";

  const token = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  const tokenFromStorage = localStorage.getItem("token");
  let parsedToken = null;

  if (tokenFromStorage !== null) {
    try {
      parsedToken = JSON.parse(tokenFromStorage);
    } catch (error) {}
  }

  const tokenStorage = localStorage.getItem("authToken");
  let parsedToke = null;

  if (tokenStorage !== null) {
    try {
      parsedToke = JSON.parse(tokenStorage);
    } catch (error) {}
  }

  const expiry = 47 * 60 * 60 * 1000 + 50 * 60 * 1000;

  const currentDate = new Date();

  useEffect(() => {
    dispatch<any>(
      setTokenWithExpiry(
        token ? token : tokenFromStorage,
        expiry,
        handleLogoutAndNavigate
      )
    );
  }, [dispatch, token ? token : tokenFromStorage, expiry]);

  const handleLogoutAndNavigate = () => {
    dispatch(LOGOUT());
    navigate("/login");
  };

  const fetchAllDay1Schedule = async () => {
    try {
      let tokenToUse;

      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }

      const data = await fetchAllSchedule(tokenToUse);
      await dispatch(SET_ALL_SCHEDULE(data));
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllDay1Schedule();
    const interval = setInterval(fetchAllDay1Schedule, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchAllStaff = async () => {
    try {
      let tokenToUse;

      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }

      const data = await fetchStaff(tokenToUse);
      await dispatch(SET_ALL_STAFF(data));
      // setStaffData(data);
    } catch (err) {
      // Handle errors here
    }
  };

  useEffect(() => {
    fetchAllStaff();
  }, []);

  const handleFilterClose = () => {
    modalFilterClose();
    setValue("startDate", null);
    setValue("endDate", null);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  function formatDateToBackend(inputDate: any) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}/${month}/${day}`;
  }

  const handleFilterSubmit = async (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    try {
      const formattedStartDate = formatDateToBackend(startDate);
      const formattedEndDate = formatDateToBackend(endDate);
      let tokenToUse;

      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const filteredData = await filterGraph(
        tokenToUse,
        formattedStartDate,
        formattedEndDate
      );
      setFilteredData(filteredData);
      handleFilterClose();
      setStartDate("");
      setEndDate("");
    } catch (error) {}
  };

  useEffect(() => {
    handleFilterSubmit();
  }, []);

  const handleFetch = async () => {
    try {
      const today = new Date();
      today.setHours(1, 1, 1, 1);
      
      const formattedStartDate = today.toISOString().split('T')[0];
  
      let tokenToUse;
      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        tokenToUse = tokenFromStorage;
      }
  
      const res = await filterGraph(tokenToUse, formattedStartDate);
      setFilteredTodayData(res);
    } catch (error) {
      // Handle errors if needed
    }
  };
  
  useEffect(() => {
    handleFetch();
  }, []);
  

  const totalAll = async (e?: any) => {
    try {
      let tokenToUse;
      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const filteredData = await filterGraph(tokenToUse);
      setFilteredData(filteredData);
    } catch (error) {}
  };

  useEffect(() => {
    totalAll();
  }, []);

  //Receptionist
  const totalStaffToday = filteredTodayData ? filteredTodayData.data.staff : 0;

  const totalVisitorsToday = filteredTodayData
    ? filteredTodayData.data.visits
    : 0;

  //Admin
  const totalStaff = filteredData ? filteredData.data.staff : 0;

  const totalVisitors = filteredData ? filteredData.data.visits : 0;

  const CardsStat = async () => {
    try {
      let userType = "day";
      if (!secretaryUser) {
        userType = "all";
      }
      let tokenToUse;
      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const data = await visitStat(tokenToUse, userType);
      setCardData(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    CardsStat();
  }, []);

  return (
    <>
      <div className="ml-[51px] mb-[57px] mt-[42px] mr-[34px] adminDash pt-[80px]">
        <div>
          {!secretaryUser && (
            <div className="flex justify-end mb-5 create-dept-level">
              <CreateDept />
              <CreateLevels />
            </div>
          )}
          <div className="flex justify-between title-date  items-center font-bold">
            <h2 className="text-[#44566c] text-[32px]">Dashboard</h2>
            <p className="text-[#6B7A99] text-base">
              {currentDate.toLocaleDateString()}
            </p>
          </div>
          <Cards cardData={cardData} />
          <div className="flex justify-between items-center mt-[33px] text-[#6B7A99] font-bold today-schedule">
            <p className="text-xl">Today’s Schedule</p>
            <p className="text-base">
              <Link to="/visitors">See all visitors</Link>{" "}
            </p>
          </div>
          <Cards2 />
          <div
            className="bg-white shadow mt-8 text-[#44566C] w-full area-chart-container-div 
            rounded-[10px] px-[53px] pt-[30px] pb-[26px]"
          >
            <div className="flex flex-wrap area-chart-overview justify-between items-center mb-[35px]">
              <div className="overview-text-container">
                <p className="text-xl font-bold">Overview</p>
                <>
                {!secretaryUser && (
                <button
                  className="bg-[#0081FF] w-[114px] text-sm rounded-[4px] py-2 text-white flex items-center justify-center add-schedule-btn"
                  onClick={modalFilterOpen}
                >
                  <p className="ml-2 text-sm font-normal">Filter</p>
                </button>
                )}
                </>
              </div>
              <Modal
                isOpen={filterChartOpen}
                onRequestClose={handleFilterClose}
                contentLabel="Add New Staff Modal"
                className="w-[42%] rounded-[12px] py-[45px] bg-[white] filter-date-modal"
                style={{
                  overlay: {
                    backgroundColor: "rgba(42, 42,42, 0.9)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                }}
              >
                <div className="w-[83%] mx-auto">
                  <div className="flex justify-end mb-[10px]">
                    <div onClick={handleFilterClose} className="cursor-pointer">
                      <CloseIcon />
                    </div>
                  </div>
                  <form onSubmit={handleFilterSubmit}>
                    <h2 className="text-[#44566C] text-[30px] font-bold mx-auto text-center">
                      Filter
                    </h2>
                    <div className="mt-[27px] font-normal text-[15px]">
                      <label className="block text-[#777] text-[16px]">
                        Start Date
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DemoItem>
                            <Controller
                              name="start"
                              control={control}
                              rules={{
                                required: "Start Date is required",
                                validate: {
                                  validTime: (value) => {
                                    const selectedDate = new Date(value);
                                    const currentDate = new Date();
                                    if (
                                      !isAfter(selectedDate, currentDate) &&
                                      !isToday(selectedDate)
                                    ) {
                                      return "Please select a current or future date.";
                                    }
                                    return true;
                                  },
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <div>
                                  <DatePicker
                                    {...field}
                                    value={startDate || ""}
                                    onChange={(newValue) => {
                                      setStartDate(newValue || "");
                                    }}
                                    className="picker rounded"
                                    slotProps={{
                                      textField: { size: "small" },
                                    }}
                                  />
                                  {fieldState.error && (
                                    <p className="text-[#D32F2F] text-xs mt-[3px]">
                                      {fieldState.error.message}
                                    </p>
                                  )}
                                </div>
                              )}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className="mt-[23px] font-normal text-[15px]">
                      <label className="block text-[#777] text-[16px]">
                        End Date
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DemoItem>
                            <Controller
                              name="end"
                              control={control}
                              rules={{
                                required: "End Date is required",
                                validate: {
                                  validTime: (value) => {
                                    const selectedDate = new Date(value);
                                    const currentDate = new Date();

                                    if (
                                      !isAfter(selectedDate, currentDate) &&
                                      !isToday(selectedDate)
                                    ) {
                                      return "Please select a current or future date.";
                                    }
                                    return true;
                                  },
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <div>
                                  <DatePicker
                                    {...field}
                                    value={endDate || ""}
                                    onChange={(newValue) => {
                                      setEndDate(newValue || ""); // Ensure newValue is a string
                                    }}
                                    className="rounded picker"
                                    slotProps={{
                                      textField: { size: "small" },
                                    }}
                                  />
                                  {fieldState.error && (
                                    <p className="text-[#D32F2F] text-xs mt-[3px]">
                                      {fieldState.error.message}
                                    </p>
                                  )}
                                </div>
                              )}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <>
                     
                        <button
                          type="submit"
                          className="w-full rounded-[5px] bg-[#0081FF] h-[53px] mt-[27px] text-[19px] font-medium text-[#fff]"
                          style={{
                            boxShadow:
                              "0px 10.23094px 20.46187px 0px rgba(0, 129, 255, 0.20)",
                          }}
                        >
                          Filter
                        </button>
                      
                    </>
                  </form>
                </div>
              </Modal>
            </div>

            {/* RECEPTIONIST */}
            <section className="flex flex-wrap justify-between gap-y-[50px] overview-stats">
              {secretaryUser && (
                <>
                  <div>
                    <p className="font-medium text-[15px]">Visits</p>
                    <div className="flex items-center flex-wrap gap-y-[10px]">
                      <p className="text-[28px] font-normal mr-[44px]">
                        {totalVisitorsToday}
                      </p>

                      <div className="flex items-center"></div>
                    </div>
                  </div>
                  <div>
                    <p className="font-medium text-[15px]">Staff</p>
                    <div className="flex items-center flex-wrap gap-y-[10px]">
                      <p className="text-[28px] font-normal mr-[44px]">
                        {totalStaffToday}
                      </p>
                      <div className="flex items-center"></div>
                    </div>
                  </div>
                </>
              )}
            </section>

            {/* ADMIN */}
            <section className="flex flex-wrap justify-between gap-y-[50px] overview-stats">
              {!secretaryUser && (
                <>
                  <div>
                    <p className="font-medium text-[15px]">Visits</p>
                    <div className="flex items-center flex-wrap gap-y-[10px]">
                      <p className="text-[28px] font-normal mr-[44px]">
                        {totalVisitors}
                      </p>

                      <div className="flex items-center"></div>
                    </div>
                  </div>
                  <div>
                    <p className="font-medium text-[15px]">Staff</p>
                    <div className="flex items-center flex-wrap gap-y-[10px]">
                      <p className="text-[28px] font-normal mr-[44px]">
                        {totalStaff}
                      </p>
                      <div className="flex items-center"></div>
                    </div>
                  </div>
                </>
              )}
            </section>

            <div>
              {secretaryUser && (
                <AreaChart
                  xAxisData={
                    filteredTodayData?.data.graph.map((item) => item.date) || []
                  }
                  yAxisData={
                    filteredTodayData?.data.graph.map((item) => item.count) ||
                    []
                  }
                />
              )}
            </div>

            <div>
              {!secretaryUser && (
                <AreaChart
                  xAxisData={
                    filteredData?.data.graph.map((item) => item.date) || []
                  }
                  yAxisData={
                    filteredData?.data.graph.map((item) => item.count) || []
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
