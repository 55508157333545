import React, { useState } from "react";
import { verificationCode1 } from "../../services/authService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const initialState = {
    code: ["", "", "", ""],
  };

const Verification = (props: any) => {
    const [loading, setLoading] = useState(false);
  const [formData, setformData] = useState(initialState);
  const { code } = formData;
  const navigate = useNavigate();

  const handleInputChange = (index: number, value: string) => {
    setformData((prevFormData) => {
      const newCode = [...prevFormData.code];
      newCode[index] = value;
      return { ...prevFormData, code: newCode };
    });
  };
  
  const verify = async (e: any) => {
    e.preventDefault();

    // if (password.length < 6) {
    //   return toast.error("Passcode must be up to 5 characters");
    // }
    // if (password !== password2) {
    //   return toast.error("Passwords do not match");
    // }
    const verificationCode = code.join("");

    const userData = {
      code: verificationCode,
    };
 
    setLoading(true);
    try {
      const data = await verificationCode1(userData);
      navigate("/resetpassword");
      setLoading(false);
    //   toast.success(data.message);
    } catch (error) {
   
    }
  };

  return (
    <div>
      <div className="w-[430px] h-[450px] bg-white shadow-lg shadow-slate-100 rounded-[11.3px]  justify-center mr-auto ml-auto mt-20">
        {/* <div>
          <h1 className=" text-3xl font-black text-[#44566C] text-center pt-10">
            Get <span className="text-[#0081FF]">Started</span>{" "}
          </h1>
          <p className="text-[#8697A8]  text-center mr-auto ml-auto mt-6">
            We’ve sent a verification code to your email <br /> {props.email}
          </p>
          <form onSubmit={verify}>
            <div className="passcode-input mt-14">
              <input
                value={code[0]}
                onChange={(e) => handleInputChange(0, e.target.value)}
                type="text"
                name="code"
                maxLength={1}
              />
              <input
                value={code[1]}
                onChange={(e) => handleInputChange(1, e.target.value)}
                type="text"
                name="code"
                maxLength={1}
              />
              <input
                value={code[2]}
                onChange={(e) => handleInputChange(2, e.target.value)}
                type="text"
                name="code"
                maxLength={1}
              />
              <input
               value={code[3]}
               onChange={(e) => handleInputChange(3, e.target.value)}
                type="text"
                name="code"
                maxLength={1}
              />
            </div>
            <div className="ml-auto mr-auto w-[85%] rounded bg-[#0081FF] h-[45px] mt-8">
              <button
                className="text-center w-full mt-2.5 btn text-[#fff]"
                type="submit"
              >
                Login
              </button>
            </div>
          </form>
        </div> */}
      </div>
    </div>
  );
}

export default Verification