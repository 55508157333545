import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { motion } from "framer-motion";
import { SET_SHOW_NAVBAR } from "../redux/features/showNavbarSlice";
import { useDispatch } from "react-redux";

const Layout = ({ children }: any) => {

  const [showSidebar, setShowSidebar] = useState(false);
  const [showDesktopSidebar, setShowDesktopSidebar] = useState(true)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SET_SHOW_NAVBAR(true));
  }, []);

  return (
    <div className="w-full h-screen">
      <Navbar show={showSidebar} setShow={setShowSidebar} />
      <div className="flex bg-[#F7F8FA] w-full">
        <div className="mt-[42px] w-[310px] desktop-sidebar pt-[80px]">
          <Sidebar showDesktop={showDesktopSidebar} />
        </div>
        <motion.div
          className={`mobile-sidebar-container w-100`}
          onClick={() => setShowSidebar(false)}
          initial={false}
          animate={{
            x: showSidebar ? 0 : "-100%",
            opacity: showSidebar ? 1 : 0,
          }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          <Sidebar show={showSidebar} setShow={setShowSidebar} />
        </motion.div>

        <main style={{ flex: 1, flexWrap: "wrap" }} className="w-full">
          {children}
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default Layout;
