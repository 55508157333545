import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import Mail from "../../icons/Dark.svg";
import Eye from "../../icons/Eye.svg";
import Eye2 from "../../icons/eye-regular.svg";
import Org from "../../icons/fluent-mdl2_group.svg";
import User from "../../icons/circle-user-regular (1).svg";
import { Link, useNavigate } from "react-router-dom";
import { registerUser, emailValidation } from "../../services/authService";
import {
  SET_LOGIN,
  SET_TOKEN,
  SET_USER,
  SET_NEWORG,
  UPDATE_LOGO,

} from "../../redux/features/authSlice";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { imageResizeAndCompress } from "../../components/utilities/ImageResize";
import CircleLoader from "react-spinners/CircleLoader";
import { Button, Box, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

const initialState = {
  organizationName: "",
  email: "",
  password: "",
  password2: "",
  logo: "",
};

export const Backend_URL = process.env.REACT_APP_AUTH_URL;
const SignUp = () => {
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [viewPassword, setViewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [finalLoading, setFinalLoading] = useState(false);
  const [confirmViewPassword, setConfirmViewPassword] = useState("");
  const [step, setStep] = useState(1);
  const [selectedFileName, setSelectedFileName] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [formData, setFormData] = useState(initialState);
  const { organizationName, email, password, password2, logo } = formData;
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [selectedImageFileName, setSelectedImageFileName] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileTypeError, setFileTypeError] = useState<string | null>(null);
  const [fileSizeError, setFileSizeError] = useState<string | null>(null);

  const MAX_IMAGE_SIZE = 10 * 1024 * 1024; // 10mb

  const uploadImageToCloudinary = async (file: any) => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    if (!file) {
      return null;
    }
    const dataForm = new FormData();
    dataForm.append("files", file);

    try {
      const response = await axios.post(
        `${Backend_URL}/upload`,
        dataForm,
        config
      );
      const uploadedImageUrl = response.data?.data[0];
      if (response.data.status === true) {
        toast.success("Image uploaded successfully");
      }
      setLoading(false);
      return uploadedImageUrl;
    } catch (error) {
      setLoading(false);
      // toast.error("Image upload failed. Try again");
    }
  };

  const handleImageChange = async (files: File[]) => {
    const file = files[0];
    if (file?.size > MAX_IMAGE_SIZE) {
      setFileSizeError("Image size is larger than 10mb.");
      return;
    }
    if (!file?.type?.startsWith("image/")) {
      setFileTypeError("Invalid file type. Please upload an image.");
      return;
    }

    setFileTypeError(null);

    try {
      const resizedCompressedFile = await imageResizeAndCompress(file);
      const dataUrl = await uploadImageToCloudinary(resizedCompressedFile);
      setUploadedImageUrl(dataUrl);
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataUrl = event.target?.result as string;
        setUploadedImageUrl(dataUrl);
      };
      reader.readAsDataURL(resizedCompressedFile);
    } catch (error) {
      setUploadedImageUrl("");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleImageChange,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/svg+xml": [".svg"],
    },
    multiple: false,
  });

  const token = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });


  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const handleFormSubmit = handleSubmit(async (formData) => {
    setFinalLoading(true);
    try {
      if (fileInputRef.current?.files?.length) {
        // Handle file upload if needed
      } else {
        const userData = {
          organizationName: formData.organizationName,
          email: formData.email,
          password: formData.password,
          logo: uploadedImageUrl,
        };
  
        const data = await registerUser(userData);
  
        if (data?.data?.token) {
          // Store the token in local storage
          localStorage.setItem('token', data?.data?.token);
          // localStorage.setItem('token', JSON.stringify(data?.data?.token));
  
          // Dispatch the token to the Redux store if needed
          await dispatch(SET_TOKEN(data?.data?.token));
  
          // Dispatch other actions if needed
          await dispatch(SET_LOGIN(true));
          await dispatch(SET_NEWORG(data?.data?.newOrganization));
          await dispatch(SET_USER(data?.data?.user));
          // await dispatch(UPDATE_LOGO(data?.data?.logo))
          
          reset();
          setUploadedImageUrl("");
          navigate("/verify");
        } else {
          // Handle the case where no token is received
        }
      }
      setFinalLoading(false);
    } catch (error) {
      setFinalLoading(false);
      setFormData(initialState);
      // Handle any errors that occur during signup
    }
  });
  

  // Form One
  const handleSubmitStepOne = handleSubmit(async () => {
    setStep(step + 1);
  });

  // Form Two
  const handleSubmitStepTwo = handleSubmit(async () => {
    setStep(step + 1);
  });

  // Password Visibility
  const handleShowPassword = () => {
    setViewPassword((previousValue): any => !previousValue);
  };
  const handleConfirmShowPassword = () => {
    setConfirmViewPassword((previousValue): any => !previousValue);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const validatePasswordMatch = (value: string) => {
    return value === watch("password") || "Passwords do not match";
  };

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="w-[40.6%] sign-in-form px-[50px] py-[36px] mr-auto ml-auto  bg-white shadow-lg shadow-slate-100 rounded-[11.3px]">
        <form
          // onSubmit={handleFormSubmit}
          className="w-[80%] mr-auto ml-auto sign-in-form-inner"
        >
          {step === 1 && (
            <div>
              <h1 className=" text-3xl font-bold text-[#44566C] text-center">
                Get <span className="text-[#0081FF]">Started</span>{" "}
              </h1>
              <p className="text-[#8697A8]  text-center mr-auto ml-auto mt-6 font-normal text-base">
                Tell us about your organization
              </p>
              <div className="mt-[55px] email-container orgName-container">
                <Controller
                  name="organizationName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Organization Name is required",
                    minLength: {
                      value: 3,
                      message:
                        "Organization Name must be at least 3 characters",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="w-full">
                      <FormControl fullWidth>
                        <OutlinedInput
                          {...field}
                          startAdornment={
                            <InputAdornment position="start">
                              <img
                                src={Org}
                                alt="Organization Icon"
                                width={30}
                              />
                            </InputAdornment>
                          }
                          placeholder="Organization Name"
                        />
                      </FormControl>
                      {fieldState.error && (
                        <p className="text-[#FF0101] text-xs mt-[3px]">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>

              <div className="mt-[22.86px]  email-container">
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Enter a valid email. E.g, example@gmail.com",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="w-full">
                      <FormControl fullWidth>
                        <OutlinedInput
                          {...field}
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={Mail} alt="Mail" />
                            </InputAdornment>
                          }
                          placeholder="Email"
                        />
                      </FormControl>
                      {fieldState.error && (
                        <p className="text-[#FF0101] text-xs mt-[3px]">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>

              <div className="w-full mt-[25px]">
                <Button
                  className="sign-in-btn"
                  type="submit"
                  onClick={handleSubmitStepOne}
                >
                  Next
                </Button>
              </div>
                <p className="mt-[40px] text-center text-base font-normal text-[#0081FF] ">
                  <Link to="/login" className="hover:opacity-80">
                    {" "}
                    Have an account?
                  </Link>
                </p>
            
            </div>
          )}
          {step === 2 && (
            <div>
              <h1 className=" text-3xl font-bold text-[#44566C] text-center">
                Get <span className="text-[#0081FF]">Started</span>{" "}
              </h1>
              <p className="text-[#8697A8] text-center mr-auto ml-auto mt-6 font-normal text-base">
                Set Password
              </p>
              <div className="mt-[55px]">
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                    maxLength: {
                      value: 13,
                      message: "Password must not be more than 13 characters",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="w-full">
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          {...field}
                          id="outlined-adornment-password"
                          type={viewPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {viewPassword ? (
                                  <img src={Eye2} alt="password" />
                                ) : (
                                  <img src={Eye} alt="password" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          placeholder="Password"
                        />
                      </FormControl>
                      {fieldState.error && (
                        <p className="text-[#FF0101] text-xs mt-[3px]">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>

              <div className="mt-[22.86px]">
                <Controller
                  name="password2"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                    maxLength: {
                      value: 13,
                      message: "Password must not be more than 13 characters",
                    },
                    validate: validatePasswordMatch,
                  }}
                  render={({ field, fieldState }) => (
                    <div className="w-full">
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          {...field}
                          id="outlined-adornment-password"
                          type={confirmViewPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleConfirmShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {confirmViewPassword ? (
                                  <img src={Eye2} alt="password" />
                                ) : (
                                  <img src={Eye} alt="password" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          placeholder="Confirm Password"
                        />
                      </FormControl>
                      {fieldState.error && (
                        <p className="text-[#FF0101] text-xs mt-[3px]">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="flex gap-x-[10px] justify-between mt-[55px]">
                <Button
                  className="sign-up-btn prev"
                  type="submit"
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Prev
                </Button>
                <Button
                  className="sign-up-btn next"
                  type="button"
                  onClick={handleSubmitStepTwo}
                >
                  Next
                </Button>
              </div>
              <p className="mt-[40px] text-center text-base font-normal text-[#0081FF] ">
                  <Link to="/login" className="hover:opacity-80">
                    {" "}
                    Have an account?
                  </Link>
                </p>
            </div>
          )}
          {step === 3 && (
            <div>
              <h1 className=" text-3xl font-bold text-[#44566C] text-center">
                Get <span className="text-[#0081FF]">Started</span>{" "}
              </h1>
              <p className="text-[#777] font-normal text-sm mt-[22px] mb-[12px]">
                Upload Organization Logo
              </p>
              <div className="signup-upload-container">
                <div
                  {...getRootProps()}
                  className="bg-[#F7F9FB] py-[40px] mt-3"
                >
                  <div>
                    <input {...getInputProps()} />
                    <p className=" text-center mx-auto my-auto text-[#8697A8] max-w-[140px] text-sm font-normal">
                      Click to browse or drag and drop your files
                    </p>
                    <div className="flex justify-center">
                      {uploadedImageUrl ? (
                        <img
                          src={uploadedImageUrl}
                          className="h-[90px]  w-[90px] rounded-full shadow  mt-[19px]"
                          alt="Uploaded Logo"
                        />
                      ) : (
                        <img
                          src={User}
                          className="h-[90px]  w-[90px] rounded-full mt-[19px]"
                          alt="Uploaded Logo"
                        />
                      )}
                    </div>
                    <div className="w-[110px] mr-auto ml-auto mt-[19px]">
                      <button
                        type="button"
                        className="bg-[#0081FF] rounded-[3px] w-[119px] h-[35px] text-sm text-white"
                        onClick={() => fileInputRef.current?.click()}
                      >
                        New Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {fileTypeError && (
                <p className="text-[#FF0101] text-xs mt-[3px]">
                  {fileTypeError}
                </p>
              )}
              {fileSizeError && (
                <p className="text-[#FF0101] text-xs mt-[3px]">
                  {fileSizeError}
                </p>
              )}

              <div className="flex gap-x-[10px] justify-between mt-[55px]">
                <Button
                  className="sign-up-btn prev"
                  type="submit"
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Prev
                </Button>
                <Button
                  className="sign-up-btn next"
                  type="button"
                  onClick={handleFormSubmit}
                >
                  {finalLoading ? (
                    <CircleLoader
                      color="#ffffff"
                      loading={finalLoading}
                      size={20}
                    />
                  ) : (
                    "Next"
                  )}
                </Button>
              </div>
              <p className="mt-[40px] text-center text-base font-normal text-[#0081FF] ">
                  <Link to="/login" className="hover:opacity-80">
                    {" "}
                    Have an account?
                  </Link>
                </p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default SignUp;
