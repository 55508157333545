import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Level {
    _id: string;
    levelName: string;
    organization: string;
    staff: string[];
    __v: number;
  }

  interface LevelState {
    levels: Level[];
  }

  
  const initialState: LevelState = {
    levels: [],
  };

  const levelSlice = createSlice({
    name: "levels",
    initialState,
    reducers: {
        SET_LEVELS: (state, action: PayloadAction<Level[]>) => {
            state.levels = action.payload;
          },
    }
  })
export const {SET_LEVELS} = levelSlice.actions
export default levelSlice.reducer;