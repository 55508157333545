import React, { useEffect, useState } from "react";
import Star from "../../icons/Star.svg";
import Arrow from "../../icons/Arrow - Bottom.svg";
import Menu from "../../icons/Menu.svg";


const Cards = ({cardData}: any) => {
  return (
    <div className="dashboard-cards-container w-full flex gap-[33px] flex-wrap mt-[33px]">
      <div className="bg-white rounded-xl shadow p-6 flex-1">
        <img src={Star} alt="star" />
          <h3 className=" text-[#44566c] font-medium mt-2 text-[22px]">
             {cardData?.visitors}
          </h3>
        <p className="text-[#44566c] font-normal text-base">Visitors</p>
      </div>
      <div className="bg-white rounded-xl shadow p-6 flex-1">
        <img src={Arrow} alt="star" />
        <h3 className=" text-[#44566c] font-medium mt-2 text-[22px]">
        {cardData?.staff}
        </h3>
        <p className="text-[#44566c] font-normal text-base">Staff</p>
      </div>
      <div className="bg-white rounded-xl shadow p-6 flex-1">
        <img src={Menu} alt="star" />
       
          <h3 className=" text-[#44566c] font-medium mt-2 text-[22px]">
              {cardData?.visits}
          </h3>
      
        <p className="text-[#44566c] font-normal text-base">Visited</p>
      </div>
      <div className="bg-white rounded-xl shadow p-6 flex-1">
        <img src={Menu} alt="star" />
      
          <h3 className=" text-[#44566c] font-medium mt-2 text-[22px]">
             {cardData?.cancelled}
          </h3>
       
        <p className="text-[#44566c] font-normal text-base">
          Cancelled Appointment
        </p>
      </div>
    </div>
  );
};

export default Cards;
