import React, { useEffect, useState } from "react";
import { useModalContext } from "../../components/utilities/ModalContext";
import Modal from "react-modal";
import { CloseIcon } from "../../icons/icon";
import { toast } from "react-toastify";
import { fetchAllStaff } from "../../services/staffService";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SET_NEW_SCHEDULE } from "../../redux/features/addNewScheduleSlice";
import {
  addNewSchedule,
  createVisitor,
  verifyVisitLink,
} from "../../services/VisitorService";
import FadeLoader from "react-spinners/FadeLoader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { TextField, Button, Grid, Autocomplete } from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import { isAfter, isToday, isBefore } from "date-fns";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LOGOUT, setTokenWithExpiry } from "../../redux/features/authSlice";
import { identifyOrganization } from "../../services/authService";
import Avartar from "../../icons/User-avatar.svg.png";
import { SET_NOTIFICATION } from "../../redux/features/notificationSlice";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  fetchNotifications,
  readNotification,
} from "../../services/notificationService";
Modal.setAppElement("#root");

const initialState = {
  createId: "",
  staffId: "",
  visitorName: "",
  visitorEmail: "",
  visitorPhone: "",
  visitorGender: "",
  reasonForVisiting: "",
  timeOfVisiting: null,
};

interface Organization {
  status: boolean;
  message: string;
  data: {
    _id: string;
    organizationName: string;
    email: string;
    logo: string; // You can update this type if you have a specific type for logos
    verified: boolean;
    departments: string[];
    levels: string[];
    staff: string[];
    visitors: string[];
    createdAt: string; // You can use a Date type if you parse this date string
    updatedAt: string; // You can use a Date type if you parse this date string
    __v: number;
    admin: string;
    secretary: string;
  };
}

const Visit = () => {
  const [formData, setFormData] = useState(initialState);
  const {
    visitorName,
    visitorEmail,
    reasonForVisiting,
    timeOfVisiting,
    visitorPhone,
    visitorGender,
  } = formData;
  const [loading, setLoading] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validationError, setValidationError] = useState(initialState);
  const [stat, setStat] = useState([]);
  const [addVisitors, setAddVisitors] = useState([]);
  const { isModalOpening, modalIsClosing, modalIsOpening } = useModalContext();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const createIdFromQuery = queryParams.get("org");
  const [createId, setCreateId] = useState(createIdFromQuery);
  const [isVerified, setIsVerified] = useState(false);
  const [showStaff, setShowStaff] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [identify, setIdentify] = useState<Organization | null>(null);
  const [orgById, setOrgById] = useState("");

  const dispatch = useDispatch();
  const token = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  const tokenFromStorage = localStorage.getItem("token") || null;
  const role = useSelector((state: any) => state.auth?.user?.role);

  const secretaryUser = role === "secretary";
  const adminUser = role === "admin";

  const {
    handleSubmit,
    control,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const addSchedule = handleSubmit(async (formData) => {
    setLoading(true);
    try {
      const phoneNumber = parsePhoneNumberFromString(
        `${formData.visitorPhone}`,
        "NG" 
      );
      const userData = {
        createId: createId,
        staffId: selectedStaffId,
        visitorName: formData.visitorName,
        visitorEmail:
          formData.visitorEmail !== "" ? formData.visitorEmail : undefined,
        visitorPhone: phoneNumber?.formatInternational() || "",
        visitorGender: formData.visitorGender,
        reasonForVisiting: formData.reasonForVisiting,
        timeOfVisiting: formData.timeOfVisiting?.toISOString(),
      };
      let tokenToUse;
      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const data = await createVisitor(userData, tokenToUse);
      await dispatch(SET_NEW_SCHEDULE(data));
      if (data.status === true) {
        reset();
        setSelectedStaffId("");
        setValue("timeOfVisiting", null);
        verifyGetLink();
        fetchNotification();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  });

  const navigate = useNavigate();
  const expiry = 47 * 60 * 60 * 1000 + 50 * 60 * 1000;

  useEffect(() => {
    dispatch<any>(setTokenWithExpiry(token, expiry, handleLogoutAndNavigate));
  }, [dispatch, token, expiry]);

  const handleLogoutAndNavigate = () => {
    dispatch(LOGOUT());
    navigate("/login"); // Navigate to the login page when session expires
  };
  //Verify Link
  const verifyGetLink = async () => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const org = searchParams.get("org");

      if (org !== null && org.trim() !== "") {
        // Verify the link
        const linkVerificationResult = await verifyVisitLink(org);
        if (linkVerificationResult.status === true) {
          setIsVerified(true);
        } else {
          navigate("/nopage");
        }
      } else {
        navigate("/nopage");
      }
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        navigate("/nopage");
      } else {
        setIsVerified(false);
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    verifyGetLink();
  }, []);

  const identifyOrg = async () => {
    try {
      // Fetch organization
      const data = await identifyOrganization(createIdFromQuery);
      setIdentify(data);

      const organizationId = data.data?._id;
      setOrgById(organizationId);
    } catch (error) {}
  };

  useEffect(() => {
    identifyOrg();
  }, []);

  const fetchEveryStaff = async () => {
    try {
      const data = await fetchAllStaff(orgById);
      setShowStaff(data?.data);
    } catch (err) {}
  };

  useEffect(() => {
    if (orgById) {
      // Call fetchEveryStaff only if orgById is defined
      fetchEveryStaff();
    }
  }, [orgById]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);


  const handleClose = () => {
    modalIsClosing();
    reset();
    setSelectedStaffId("");
    setValue("timeOfVisiting", null);
  };


  const fetchNotification = async () => {
    try {
      const allNotifications = await fetchNotifications(token, false);
      await dispatch(SET_NOTIFICATION(allNotifications));
      await readNotification(allNotifications?.data?.viewed, token);
    } catch (err) {}
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  const genderData = ["male", "female"];

  return (
    <div>
      {isLoading ? (
        <div className="w-[80px] mx-auto mt-[21%]">
          <FadeLoader />
        </div>
      ) : isVerified ? (
        <div>
          <div>
            <div className="flex justify-center items-center h-screen w-screen">
              <div>
                <div>
                  {identify?.data?.logo ? (
                    <img
                      src={identify?.data?.logo}
                      alt="Company Logo"
                      className="h-[200px] w-[200px] rounded-full mx-auto shadow "
                    />
                  ) : (
                    <img
                      src={Avartar}
                      alt="Company Logo"
                      className="h-[200px] w-[200px] rounded-full mx-auto shadow "
                    />
                  )}
                </div>
                <h1 className="text-center text-4xl mt-5 font-bold text-[#44566C] mx-auto">
                  Welcome To{" "}
                  <span className="text-[#0081ff]">
                    {identify?.data?.organizationName}
                  </span>
                </h1>
                <h2 className="text-center mt-5 text-lg font-semibold text-[#44566C] mx-auto">
                  Create Schedule
                </h2>
                <div className="flex justify-center items-center mt-5">
                  <button
                    className="bg-[#0081ff] w-[170px] h-[40px] text-center rounded text-white shadow-md flex justify-center items-center "
                    onClick={modalIsOpening}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
            <Modal
              isOpen={isModalOpening}
              onRequestClose={handleClose}
              contentLabel="Add New Staff Modal"
              className="w-[42.7%] rounded-[12px] py-[30px] pb-[34px] bg-[white] add-schedule-modal"
              // style={{
              //   content: {
              //     overflowY: "auto",
              //   },
              // }}
              style={{
                overlay: {
                  backgroundColor: "rgba(42, 42,42, 0.9)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            >
              <div className="w-[80%] mx-auto">
                <div className="flex justify-end mb-[34px]">
                  <div onClick={handleClose} className="cursor-pointer">
                    <CloseIcon />
                  </div>
                </div>
                {/* Modal Content */}
                <h2 className="text-[#44566C] text-center font-bold text-[30px]">
                  Add a Schedule
                </h2>

                <form
                  onSubmit={addSchedule}
                  className="new-staff-form h-[470px]"
                >
                  <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
                    <label className="block text-[#8697A8] mb-2">
                      {/* Who are you visiting */}
                    </label>
                    <Controller
                      name="staffId"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Select a Staff" }}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          options={showStaff || []}
                          getOptionLabel={(option: {
                            _id: string;
                            firstName: string;
                            lastName: string;
                          }) => `${option?.firstName} ${option?.lastName}`}
                          value={
                            showStaff?.find(
                              (staff: any) => staff._id === selectedStaffId
                            ) || null
                          }
                          onChange={(event, newValue) => {
                            setSelectedStaffId(newValue?._id || "");
                            field.onChange(newValue?._id || ""); // manually trigger the field change
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select a Staff"
                              variant="outlined"
                              error={!!fieldState.error}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                  <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
                    <label className="block text-[#8697A8] mb-2">
                      Visitor's Full Name
                    </label>
                    <Controller
                      name="visitorName"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Visitors name is required",
                        minLength: {
                          value: 3,
                          message: "Name must be at least 3 characters",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          placeholder="Your Name"
                          className="w-full  px-[19px] py-3 rounded"
                          variant="outlined"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
                    <label className="block text-[#8697A8] mb-2">
                      Visitor's Email (optional)
                    </label>
                    <Controller
                      name="visitorEmail"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          placeholder="Email"
                          className="w-full px-[19px] py-3 rounded"
                          variant="outlined"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                          {...field}
                        />
                      )}
                    />
                  </div>

                  <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
                    <Grid item xs={12}>
                      <label className="block mb-2 text-[#8697A8]">
                        Phone Number
                      </label>
                      <Controller
                        name="visitorPhone"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Phone number is required",
                        }}
                        render={({ field, fieldState }) => (
                          <div>
                            <PhoneInput
                              id="phone-input"
                              placeholder="Phone Number"
                              value={field.value}
                              onChange={(value) => field.onChange(value)}
                              defaultCountry="NG"
                              international
                              withCountryCallingCode
                              className={`custom-phone-input ${
                                fieldState.error
                                  ? "error-border-phone-input"
                                  : ""
                              }`}
                            />
                            {fieldState.error && (
                              <FormHelperText error>
                                {fieldState.error.message}
                              </FormHelperText>
                            )}
                          </div>
                        )}
                      />
                    </Grid>
                  </div>
                  <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
                    <label className="block text-[#8697A8] mb-2">Gender</label>
                    <Controller
                      name="visitorGender"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Select a gender" }}
                      render={({ field, fieldState }) => (
                        <div>
                          <Select
                            {...field}
                            className="w-full bg-[#F8FAFB] px-[19px] py-3 rounded capitalize"
                            value={field.value}
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            style={{ height: "42px" }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            error={!!fieldState.error}
                          >
                            <MenuItem disabled value="">
                              Select a gender
                            </MenuItem>
                            {genderData?.map((gender: any, _id: any) => (
                              <MenuItem
                                key={_id}
                                value={gender}
                                className="capitalize"
                              >
                                {gender}
                              </MenuItem>
                            ))}
                          </Select>
                          {fieldState.error && (
                            <FormHelperText error>
                              {fieldState.error.message}
                            </FormHelperText>
                          )}
                        </div>
                      )}
                    />
                  </div>

                  <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
                    <label className="block mb-2 text-[#8697A8]">
                      Reason for Visitation
                    </label>
                    <Controller
                      name="reasonForVisiting"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Reason for visitation is required",
                        minLength: {
                          value: 3,
                          message: "Reason must be at least 3 characters",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          className="w-full px-[19px] py-3 rounded"
                          variant="outlined"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
                    <label className="block text-[#8697A8]">
                      Time of Visitation
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          "DateTimePicker",
                          "DateTimePicker",
                          "DateTimePicker",
                        ]}
                      >
                        <DemoItem>
                          <Controller
                            name="timeOfVisiting"
                            control={control}
                            rules={{
                              required: "Time of visitation is required",
                              validate: {
                                validTime: (value) => {
                                  const selectedDate = new Date(value);
                                  const currentDate = new Date();

                                  if (
                                    !isAfter(selectedDate, currentDate) &&
                                    !isToday(selectedDate)
                                  ) {
                                    return "Please select a current or future date.";
                                  }

                                  if (
                                    isToday(selectedDate) &&
                                    isBefore(selectedDate, currentDate)
                                  ) {
                                    return "Please select a future time for today.";
                                  }

                                  return true;
                                },
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <div>
                                <DateTimePicker
                                  orientation="landscape"
                                  timeSteps={{ hours: 1, minutes: 1 }}
                                  value={field.value}
                                  views={[
                                    "year",
                                    "month",
                                    "day",
                                    "hours",
                                    "minutes",
                                  ]}
                                  onChange={(newValue) => {
                                    field.onChange(newValue);
                                  }}
                                  className="w-full bg-[#F8FAFB] px-[19px] h-[42px] py-3 rounded"
                                  slotProps={{ textField: { size: "small" } }}
                                />
                                {fieldState.error && (
                                  <p className="text-[#D32F2F] text-xs mt-[3px]">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </div>
                            )}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div className="w-full flex justify-center items-center mt-[38px]">
                    <button
                      type="submit"
                      className="h-[42px] flex justify-center items-center bg-[#0081ff] rounded-[5px] text-center text-white text-[15px] font-medium cursor-pointer w-full mr-[10px]"
                    >
                      Create Schedule
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
          </div>
        </div>
      ) : (
        <h1 className="text-center mt-[15%] text-[#44566c] text-[40px] font-bold ">
          404 <br />
          Invalid URL
          <br /> (PAGE NOT FOUND)
        </h1>
      )}
    </div>
  );
};

export default Visit;
