import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Department {
  _id: string;
  departmentName: string;
  organization: string;
  staff: string[];
  __v: number;
}

interface DepartmentState {
  departments: Department[];
}

const initialState: DepartmentState = {
  departments: [],
};

const departmentSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {
    SET_DEPARTMENTS: (state, action: PayloadAction<Department[]>) => {
      state.departments = action.payload;
    },
    // REMOVE_DEPARTMENT: (state, action: PayloadAction<string>) => {
    //   // Remove the department with the specified ID from the state
    //   state.departments = state.departments.filter(
    //     (department) => department?._id !== action.payload
    //   );
    // },
  },
});

export const { SET_DEPARTMENTS } = departmentSlice.actions;

export default departmentSlice.reducer;
