import { useEffect } from "react";

const useOnClickOutside = (ref: any, action: () => void) => {
    useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [action, ref]);
};

export default useOnClickOutside;