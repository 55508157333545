import React from "react";
import ReactECharts from "echarts-for-react";

const AreaChart = ({xAxisData, yAxisData }: any) => {

  const formattedXAxisData = xAxisData.map((dateStr:any) => {
    const [month, day] = dateStr.split('/'); // Split the date string
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const formattedMonth = monthNames[parseInt(month) - 1]; // Get the month name
  
    return `${formattedMonth} ${day}`;
  });

  const areaChartData = {
    grid: {
      left: "5%",
      right: "5%",
    },

    xAxis: {
      type: "category",
      boundaryGap: false,
      data: formattedXAxisData, 
    },
    yAxis: {
      type: "value",
    },
    
    tooltip: {
      trigger: "axis",
      formatter: function (params: any) {
        const rawDateStr = params[0].name;
    
        // Get the current year
        const currentYear = new Date().getFullYear();
    
        // Add the current year to the date string if it's not already included
        const dateStrWithYear = rawDateStr.includes(currentYear)
          ? rawDateStr
          : `${rawDateStr}, ${currentYear}`;
    
        // Get the Y-axis value
        const yAxisValue = params[0].value;
    
        // Construct the tooltip content with center-aligned text
        return `
          <div style="text-align: center;">
            ${yAxisValue} <br/> 
            ${dateStrWithYear}
          </div>
        `;
      },
    },
    
    series: [
      {
        data: yAxisData,
        type: "line",
        areaStyle: {
          color: "rgba(34, 204, 226, 0.15)",
        },
        lineStyle: {
          color: "#22CCE2",
          width: 4,
        },
      },
    ],
  };

  const isDataAvailable = xAxisData.length > 0 && yAxisData.length > 0;


  return (
    <div className="dashboard-area-chart">
      {isDataAvailable ? (
        <ReactECharts option={areaChartData} />
      ) : (
        <div className="w-full flex justify-center items-center my-[60px]">No data available</div>
      )}
    </div>

  );
};

export default AreaChart;
