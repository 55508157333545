import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store, persistor } from "./redux/Store";
import { Provider } from "react-redux";
import { ModalProvider } from "./components/utilities/ModalContext";
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
     <PersistGate loading={null} persistor={persistor}>
    <ModalProvider>
      <App />
    </ModalProvider>
    </PersistGate>
  </Provider>
);

