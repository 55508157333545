import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StaffData {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  organization: string;
  level: string;
  department: string;
  visitors: any[];
  __v: number;
  }

  interface StaffState{
    staffList: StaffData[]
  }

  const initialState: StaffState = {
    staffList: [],
  };


const allStaffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    SET_ALL_STAFF: (state, action: PayloadAction<StaffData[]>) => {
      state.staffList = action.payload;
    },
    CLEAR_ALL_STAFF: (state) => {
      state.staffList = [];
    },
  },
});

export const { SET_ALL_STAFF, CLEAR_ALL_STAFF} = allStaffSlice.actions;

export default allStaffSlice.reducer;