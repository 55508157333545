import React, { useEffect, useState } from "react";
import Group from "../../icons/ic_round-group.svg";
import Add from "../../icons/mingcute_add-fill.svg";
import { AddDept } from "../../types/AddDept";
import {
  createDept,
  deleteDepartments,
  fetchDept,
} from "../../services/authService";
import { useSelector, useDispatch } from "react-redux";
import { SET_NEW_DEPARTMENT_NAME } from "../../redux/features/addDeptSlice";
import { SET_DEPARTMENTS } from "../../redux/features/deptSlice";
import { useModalContext } from "../../components/utilities/ModalContext";
import Modal from "react-modal";
import { CloseIcon } from "../../icons/icon";
import { WhiteCloseIcon } from "../../icons/icon";
import { SET_TOKEN, SET_USER } from "../../redux/features/authSlice";
import { toast } from "react-toastify";
import { TextField, Button } from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import CircleLoader from "react-spinners/CircleLoader";

const initialState = {
  name: "",
};

const CreateDept = ({ onClose }: any) => {
  const [loading, setLoading] = useState(false);
  const {
    izModalOpen,
    closeModall,
    openModall,
  } = useModalContext();
  const [formData, setFormData] = useState(initialState);
  const { name } = formData;
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [departments, setDepartments] = useState<string[]>([]);
  const successMessages: string[] = [];
  const successfulDepartments: any[] = [];

  const dispatch = useDispatch();
  const tokens = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  const token = useSelector((state: any) => state.auth?.token);

  const tokenFromStorage = localStorage.getItem('token') || null;
  const role = useSelector((state: any) => state.auth?.user?.role);
  const secretaryUser = role === "secretary";
  const adminUser = role === "admin";

  const adminSignUp = useSelector(
    (state: any) => state.auth?.newOrganization?.admin?.role
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const handleAddDeptArray = handleSubmit(async (formData) => {
    // const newDepartment = formData.name.toLowerCase();
    const newDepartment = formData.name
    setDepartments([...departments, newDepartment]);
    reset();
  });

  const handleRemoveDeptArray = (index: number) => {
    const updatedDepartments = [...departments];
    updatedDepartments.splice(index, 1);
    setDepartments(updatedDepartments);
  };

  const handleCreateDepts = async () => {
    if (departments.length > 0) {
      setLoading(true);
      try {
        const userData = {
          names: departments,
        };
        let tokenToUse;

     
        if (adminUser || secretaryUser) {
          tokenToUse = token;
        } else {
          // Assume the user has logged in
          tokenToUse = tokenFromStorage;
        }
        const data = await createDept(userData, tokenToUse);
        data?.data.forEach((dept: any) => {
          if (dept.error === true) {
            toast.error(dept?.message);
          } else {
            successMessages.push(dept?.departmentName);
            successfulDepartments.push(dept);
          }
        });

        if (successMessages.length > 0) {
          toast.success(
            `Departments ${successMessages.join(", ")} created successfully.`
          );
        }
        await fetchAllDept();

        // await dispatch(SET_NEW_DEPARTMENT_NAME(data));
        setDepartments([]);
        closeModall();
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Please click the add button before submitting");
    }
  };

  const fetchAllDept = async () => {
    let tokenToUse;

     
    if (adminUser || secretaryUser) {
      tokenToUse = token;
    } else {
      // Assume the user has logged in
      tokenToUse = tokenFromStorage;
    }
    try {
      const allDept = await fetchDept(tokenToUse);
      await dispatch(SET_DEPARTMENTS(allDept));
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllDept();
  }, []);


  return (
    <div>
      <button
        className="bg-[#0081ff] w-[190px] h-[40px] text-center rounded text-white shadow-md mr-7 create-dept-btn hover:opacity-80"
        onClick={openModall}
      >
        Create Department <img className="inline " src={Add} />
      </button>

      <Modal
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        isOpen={izModalOpen}
        onRequestClose={() => {
          closeModall();
          reset();
          setDepartments([]);
        }}
        contentLabel="Add New Staff Modal"
        className="w-[42%] rounded-[12px] py-[45px] bg-[white] max-w-[880px]  create-dept-modal"
      >
        <div className="mx-auto z-50 w-[79.5%] justify-center justify-items-center">
          <div className="flex justify-end mb-[30px]">
            <div
              onClick={() => {
                closeModall();
                reset();
                setDepartments([]);
              }}
              className="cursor-pointer"
            >
              <CloseIcon />
            </div>
          </div>
          <h1 className="text-center text-[#44566c] text-3xl  font-bold">
            Create <span className="text-[#0081ff]">Department</span>
          </h1>
          <form>
            <div className=" h-[47px] border-[0.639px] border-solid border-[#C4C4C4] rounded-[5px] mt-[51px] pl-[20px] pr-[5px]">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: "Department name is required",
                  validate: (value) => {
                    // const newDepartment = value.toLowerCase();
                    const newDepartment = value
                    if (departments.includes(newDepartment)) {
                      return "Department already added";
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState }) => (
                  <div>
                    <div className="flex items-center">
                      <div className="flex flex-1">
                        <img src={Group} alt="group" />
                        <TextField
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                          placeholder="Department Name"
                          className="department-textfield"
                          variant="outlined"
                          fullWidth
                          error={!!fieldState.error}
                          {...field}
                        />
                      </div>
                      <button
                        className="bg-[#0081ff] w-[78px] rounded-[5px] h-[40px] flex justify-center items-center text-white text-[13px] font-bold ml-[10px] hover:opacity-80"
                        onClick={handleAddDeptArray}
                      >
                        <p className="mr-[9px]">Add</p>
                        <img src={Add} />
                      </button>
                    </div>
                    {fieldState.error && (
                      <FormHelperText error>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>
          </form>
          {departments.length > 0 && (
            <div className="h-[150px] mt-4 department-content py-[10px]">
              <ul className=" grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                {departments?.map((dept: any, index: any) => (
                  <li
                    className="bg-[#44566c] p-[10px] rounded-[7px] text-white flex justify-center items-center capitalize"
                    key={index}
                    style={{
                      wordWrap: "break-word",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <p
                      style={{
                        overflowWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        width: "100%",
                        height: "fit-content",
                      }}
                    >
                      {dept}
                    </p>
                    <button onClick={() => handleRemoveDeptArray(index)}>
                      <WhiteCloseIcon />
                    </button>
                    <div></div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="w-full mt-[25px]">
            <Button
              className="create-dept-level-submit-btn"
              type="submit"
              disabled={loading}
              onClick={handleCreateDepts}
            >
              {loading ? (
                <CircleLoader color="#ffffff" loading={loading} size={20} />
              ) : (
                "Done"
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default CreateDept;
