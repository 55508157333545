import { Button, FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CircleLoader from 'react-spinners/CircleLoader';
import Eye from "../../icons/Eye.svg";
import Eye2 from "../../icons/eye-regular.svg";
import { changePassword } from '../../services/ProfileService';
import { useNavigate } from 'react-router-dom';

type Props = {}
const adminInitialState = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
const ChangePassword = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const currentDate = new Date();
    const [adminFormData, setAdminFormData] = useState(adminInitialState);
    const [adminViewPassword, setAdminViewPassword] = useState(false);
    const [adminConfirmViewPassword, setAdminConfirmViewPassword] =useState(false);
    const [adminViewOldPassword, setAdminViewOldPassword] = useState(false);

    const navigate = useNavigate();
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  }

  const handleAdminShowPassword = () => {
    setAdminViewPassword((previousValue) => !previousValue);
  };
  const handleAdminConfirmShowPassword = () => {
    setAdminConfirmViewPassword((previousValue) => !previousValue);
  };

  const handleAdminOldShowPassword = () => {
    setAdminViewOldPassword((previousValue) => !previousValue);
  };

    const userId = useSelector((state: any) => {
        if (state.auth?.isLoggedIn) {
          return state.auth?.user?._id;
        } else {
          return null;
        }
      });

    const {
        handleSubmit: handleAdminSubmit,
        control: adminControl,
        reset: adminReset,
        formState: adminFormState,
      } = useForm();
    
      const handleResetSubmit = handleAdminSubmit(async (adminFormData) => {
        setLoading(true);
        const userData = {
          oldPassword: adminFormData.oldPassword,
          newPassword: adminFormData.newPassword,
          confirmPassword: adminFormData.confirmPassword,
          id: userId,
        };
        try {
          const data = await changePassword(userData);
          if (data.status === true) {
            setLoading(false);
            adminReset();
            setAdminFormData(adminInitialState);
            navigate('/login')
          }
        } catch (error) {
          setLoading(false);
        }
      });
  return (
    <div className="ml-[55px] mb-[57px] mt-[42px] mr-[40px] adminDash staff-page pt-[80px]">
         <div className="flex justify-between  items-center font-bold create-dept-level staff-title-text px-6  py-[5px] pt-[19px] mb-[33px]">
        <h2 className="text-[#44566c] text-[32px]">Change Password</h2>
        <p className="text-[#6B7A99] text-base">
          {currentDate.toLocaleDateString()}
        </p>
      </div>
      <div className="profile-form-container shadow-md border-0 mt-[37px] w-full bg-white rounded-[20px] py-[22px] px-[24px] text-[#44566C] mb-[169px]">
        <div className="pt-[16px]">
          <h2 className="text-[#44566C] text-[15px] font-semibold leading-6">
            Change Password
          </h2>
          <form className="px-[44px] pt-[46px] pb-[29px] profile-form">
            <div className="w-[45%]">
              <label className="block text-[#8697A8] font-normal text-[15px]">
                Old Password
              </label>
              <div>
                <Controller
                  name="oldPassword"
                  control={adminControl}
                  defaultValue=""
                  rules={{
                    required: "Old Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                    maxLength: {
                      value: 13,
                      message: "Password must not be more than 13 characters",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="w-full mt-[12px]">
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          {...field}
                          id="outlined-adornment-password"
                          className="no-outline"
                          type={adminViewOldPassword ? "text" : "password"}
                          sx={{
                            border: "none",
                            "& fieldset": { border: "none" },
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleAdminOldShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {adminViewOldPassword ? (
                                  <img src={Eye2} alt="password" width={15} />
                                ) : (
                                  <img src={Eye} alt="password" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          placeholder="Old Password"
                        />
                      </FormControl>
                      {fieldState.error && (
                        <p className="text-[#FF0101] text-xs mt-[3px]">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="flex justify-between mt-[42px]">
              <div className="w-[45%]">
                <label className="block text-[#8697A8] font-normal text-[15px]">
                  New Password
                </label>
                <div className="mt-[12px]">
                  <Controller
                    name="newPassword"
                    control={adminControl}
                    defaultValue=""
                    rules={{
                      required: "New Password is required",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters",
                      },
                      maxLength: {
                        value: 13,
                        message: "Password must not be more than 13 characters",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <div className="w-full">
                        <FormControl variant="outlined" fullWidth>
                          <OutlinedInput
                            {...field}
                            id="outlined-adornment-password"
                            className="no-outline"
                            type={adminViewPassword ? "text" : "password"}
                            sx={{
                              border: "none",
                              "& fieldset": { border: "none" },
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleAdminShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {adminViewPassword ? (
                                    <img src={Eye2} alt="password" width={15} />
                                  ) : (
                                    <img src={Eye} alt="password" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            placeholder="New Password"
                          />
                        </FormControl>
                        {fieldState.error && (
                          <p className="text-[#FF0101] text-xs mt-[3px]">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="w-[45%]">
                <label className="block text-[#8697A8] font-normal text-[15px]">
                  Confirm New Password
                </label>
                <div className="mt-[12px]">
                  <Controller
                    name="confirmPassword"
                    control={adminControl}
                    defaultValue=""
                    rules={{
                      required: "Confirm New Password is required",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters",
                      },
                      maxLength: {
                        value: 13,
                        message: "Password must not be more than 13 characters",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <div className="w-full">
                        <FormControl variant="outlined" fullWidth>
                          <OutlinedInput
                            {...field}
                            id="outlined-adornment-password"
                            className="no-outline"
                            type={
                              adminConfirmViewPassword ? "text" : "password"
                            }
                            sx={{
                              border: "none",
                              "& fieldset": { border: "none" },
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleAdminConfirmShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {adminConfirmViewPassword ? (
                                    <img src={Eye2} alt="password" width={15} />
                                  ) : (
                                    <img src={Eye} alt="password" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            placeholder="New Password"
                          />
                        </FormControl>
                        {fieldState.error && (
                          <p className="text-[#FF0101] text-xs mt-[3px]">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-center items-center">
              <Button
                className="profile-page-btn mx-auto"
                onClick={handleResetSubmit}
              >
                {loading ? (
                  <CircleLoader color="#ffffff" loading={loading} size={20} />
                ) : (
                  "Update"
                )}
              </Button>
            </div>
          </form>
         
        </div>
      </div>
    </div>
  )
}

export default ChangePassword