import React, { useState,  useEffect } from "react";
import Modal from "react-modal";
import {
  addNewSchedule,
} from "../../services/VisitorService";
import { SET_NEW_SCHEDULE } from "../../redux/features/addNewScheduleSlice";
import { useSelector, useDispatch } from "react-redux";
import { useModalContext } from "../../components/utilities/ModalContext";
import { CloseIcon } from "../../icons/icon";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField, Grid, Autocomplete } from "@mui/material";
import {
  useForm,
  Controller,
} from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import { isAfter, isToday, isBefore } from "date-fns";
import { useNavigate } from "react-router-dom";
import { fetchWorkers } from "../../services/staffService";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const initialState = {
  staffId: "",
  visitorName: "",
  visitorEmail: "",
  visitorPhone: "",
  visitorGender: "",
  reasonForVisiting: "",
  timeOfVisiting: null,
};
const AddSchedule = () => {
  const dispatch = useDispatch();
  const [phonesNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState<string>("");
  const { isModalOpen, closeModal } = useModalContext();
  const [validationError, setValidationError] = useState(initialState);
  const [showStaff, setShowStaff] = useState([]);

  const token = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  const tokenFromStorage = localStorage.getItem("token") || null;
  const role = useSelector((state: any) => state.auth?.user?.role);

  const secretaryUser = role === "secretary";
  const adminUser = role === "admin";
  const navigate = useNavigate();

  const handleClose = () => {
    closeModal();
    reset();
    setSelectedStaffId("");
    setValue("timeOfVisiting", null);
  };

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const addSchedule = handleSubmit(async (formData) => {
    setLoading(true);
    try {
      const phoneNumber = parsePhoneNumberFromString(
        `${formData.visitorPhone}`,
        "NG" // Assuming Nigeria, you can change the country code accordingly
      );
      const formattedPhoneNumber = `${formData.visitorPhone} ${phonesNumber}`;
      const userData = {
        staffId: selectedStaffId,
        visitorName: formData.visitorName,
        visitorEmail:
          formData.visitorEmail !== "" ? formData.visitorEmail : undefined,
        visitorPhone: phoneNumber?.formatInternational() || "",
        visitorGender: formData.visitorGender,
        reasonForVisiting: formData.reasonForVisiting,
        timeOfVisiting: formData.timeOfVisiting?.toISOString(),
      };

      if (!selectedStaffId) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          staffId: "Select a Staff",
        }));
        setLoading(false);
        return;
      }

      let tokenToUse;
      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const data = await addNewSchedule(userData, tokenToUse);
      await dispatch(SET_NEW_SCHEDULE(data));

      if (data.status === true) {
        reset();
        setSelectedStaffId("");
        setValue("timeOfVisiting", null);
        // navigate('/visitors')
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
    }
  });
  const fetchEveryStaff = async () => {
    try {
      let tokenToUse;
      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const data = await fetchWorkers(tokenToUse, '');
      setShowStaff(data.data)
    } catch (err) {}
  };

  useEffect(() => {
    fetchEveryStaff();
  }, []);

  const genderData = ["male", "female"];

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      contentLabel="Add New Schedule"
      className="w-[42.7%] rounded-[12px] py-[30px] pb-[34px] bg-[white] add-schedule-modal"
      // style={{
      //   content: {
      //     overflowY: "auto",
      //   },
      // }}
      style={{
        overlay: {
          backgroundColor: "rgba(42, 42,42, 0.9)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <div className="w-[80%] mx-auto">
        <div className="flex justify-end mb-[34px]">
          <div onClick={handleClose} className="cursor-pointer">
            <CloseIcon />
          </div>
        </div>
        {/* Modal Content */}
        <h2 className="text-[#44566C] text-center font-bold text-[30px]">
          Add a Schedule
        </h2>

        <form onSubmit={addSchedule} className="new-staff-form h-[470px]">
          <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
            <label className="block text-[#8697A8] mb-2">
              Who are you visiting
            </label>
            <Controller
              name="staffId"
              control={control}
              defaultValue=""
              rules={{ required: "Select a Staff" }}
              render={({ field, fieldState }) => (
                <Autocomplete
                  options={showStaff || []}
                  getOptionLabel={(option: {
                    _id: string;
                    firstName: string;
                    lastName: string;
                  }) => `${option?.firstName} ${option?.lastName}`}
                  value={
                    showStaff?.find(
                      (staff: any) => staff._id === selectedStaffId
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setSelectedStaffId(newValue?._id || "");
                    field.onChange(newValue?._id || ""); // manually trigger the field change
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a Staff"
                      variant="outlined"
                      error={!!fieldState.error}
                    />
                  )}
                />
              )}
            />
            
          </div>
          <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
            <label className="block text-[#8697A8] mb-2">
              Visitor's Full Name
            </label>
            <Controller
              name="visitorName"
              control={control}
              defaultValue=""
              rules={{
                required: "Visitors name is required",
                minLength: {
                  value: 3,
                  message: "Name must be at least 3 characters",
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  placeholder="Your Name"
                  className="w-full  px-[19px] py-3 rounded"
                  variant="outlined"
                  fullWidth
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  {...field}
                />
              )}
            />
          </div>
          <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
            <label className="block text-[#8697A8] mb-2">
              Visitor's Email (Optional)
            </label>
            <Controller
              name="visitorEmail"
              control={control}
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  placeholder="Email"
                  className="w-full px-[19px] py-3 rounded"
                  variant="outlined"
                  fullWidth
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  {...field}
                />
              )}
            />
          </div>
          <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
            <Grid item xs={12}>
              <label className="block mb-2 text-[#8697A8]">Phone Number</label>
              <Controller
                name="visitorPhone"
                control={control}
                defaultValue=""
                rules={{
                  required: "Phone number is required",
                }}
                render={({ field, fieldState }) => (
                  <div>
                    <PhoneInput
                      id="phone-input"
                      placeholder="Phone Number"
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      defaultCountry="NG"
                      international
                      withCountryCallingCode
                      className={`custom-phone-input ${
                        fieldState.error ? "error-border-phone-input" : ""
                      }`}
                    />
                    {fieldState.error && (
                      <FormHelperText error>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </Grid>
          </div>
          <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
            <label className="block text-[#8697A8] mb-2">Gender</label>
            <Controller
              name="visitorGender"
              control={control}
              defaultValue=""
              rules={{ required: "Select a gender" }}
              render={({ field, fieldState }) => (
                <div>
                  <Select
                    {...field}
                    className="w-full bg-[#F8FAFB] px-[19px] py-3 rounded capitalize"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    style={{ height: "42px" }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    error={!!fieldState.error}
                  >
                    <MenuItem disabled value="">
                      Select a gender
                    </MenuItem>
                    {genderData?.map((gender: any, _id: any) => (
                      <MenuItem key={_id} value={gender} className="capitalize">
                        {gender}
                      </MenuItem>
                    ))}
                  </Select>
                  {fieldState.error && (
                    <FormHelperText error>
                      {fieldState.error.message}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
          </div>
          <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
            <label className="block mb-2 text-[#8697A8]">
              Reason for Visitation
            </label>
            <Controller
              name="reasonForVisiting"
              control={control}
              defaultValue=""
              rules={{
                required: "Reason for visitation is required",
                minLength: {
                  value: 3,
                  message: "Reason must be at least 3 characters",
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  className="w-full px-[19px] py-3 rounded"
                  variant="outlined"
                  fullWidth
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  {...field}
                />
              )}
            />
          </div>
          <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
            <label className="block text-[#8697A8]">Time of Visitation</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={[
                  "DateTimePicker",
                  "DateTimePicker",
                  "DateTimePicker",
                ]}
              >
                <DemoItem>
                  <Controller
                    name="timeOfVisiting"
                    control={control}
                    rules={{
                      required: "Time of visitation is required",
                      validate: {
                        validTime: (value) => {
                          const selectedDate = new Date(value);
                          const currentDate = new Date();

                          if (
                            !isAfter(selectedDate, currentDate) &&
                            !isToday(selectedDate)
                          ) {
                            return "Please select a current or future date.";
                          }

                          if (
                            isToday(selectedDate) &&
                            isBefore(selectedDate, currentDate)
                          ) {
                            return "Please select a future time for today.";
                          }

                          return true;
                        },
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <div>
                        <DateTimePicker
                          orientation="landscape"
                          timeSteps={{ hours: 1, minutes: 1 }}
                          value={field.value}
                          views={["year", "month", "day", "hours", "minutes"]}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          className="w-full bg-[#F8FAFB] px-[19px] h-[42px] py-3 rounded"
                          slotProps={{ textField: { size: "small" } }}
                        />
                        {fieldState.error && (
                          <p className="text-[#D32F2F] text-xs mt-[3px]">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className="w-full flex justify-center items-center mt-[38px]">
            <button
              type="submit"
              className="h-[42px] flex justify-center items-center bg-[#0081ff] rounded-[5px] text-center text-white text-[15px] font-medium cursor-pointer w-full mr-[10px]"
            >
              Create Schedule
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default AddSchedule;
