import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Scheduling {
  _id: string;
  staffId: string;
  visitorName: string;
  visitorEmail: string;
  reasonForVisiting: string;
  timeOfVisiting: string;
}
interface AddSchedule {
  newSchedule: Scheduling | null;
}

const initialState: AddSchedule = {
  newSchedule: null,
};

const addScheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    SET_NEW_SCHEDULE: (state, action: PayloadAction<Scheduling>) => {
      state.newSchedule = action.payload;
    },
  },
});


export const { SET_NEW_SCHEDULE } = addScheduleSlice.actions;

export default addScheduleSlice.reducer;
