import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DepartmentState {
    name: string;
  }

  const initialState: DepartmentState = {
    name: "",
  };
  
  const addDepartmentSlice = createSlice({
    name: "department",
    initialState,
    reducers: {
      SET_NEW_DEPARTMENT_NAME: (state, action: PayloadAction<string>) => {
        state.name = action.payload;
      },
      CLEAR_NEW_DEPARTMENT_NAME: (state) => {
        state.name = "";
      },
    },
  });

  export const { SET_NEW_DEPARTMENT_NAME, CLEAR_NEW_DEPARTMENT_NAME } = addDepartmentSlice.actions;

export default addDepartmentSlice.reducer;