import React, { useEffect, useState, useRef } from "react";
import { resendVerificationCode, verifyCode } from "../../services/authService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_TOKEN } from "../../redux/features/authSlice";
import { Button } from "@mui/material";
import CircleLoader from "react-spinners/CircleLoader";
import "react-toastify/dist/ReactToastify.css";


const Verify = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setformData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [code, setCode] = useState(["", "", "", ""]);
  const [codeError, setCodeError] = useState("");
  // const [errorMessage, setErrorMessage] = useState(initialState);
  const navigate = useNavigate();
 
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const correctVerificationCode = "1234";

  const handleInputChange = (
    index: number,
    value: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (/^\d*$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (event.target.value === "") {
        if (index > 0) {
          inputRefs[index - 1].current?.focus();
        }
      } else if (value.length === 1 && index < inputRefs.length - 1) {
        inputRefs[index + 1].current?.focus();
      }
    } else {
      setCodeError("Please enter digits only");
    }
  };

  const handleStepChange = () => {
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && code[index] === "") {
      event.preventDefault();
      const newCode = [...code];
      newCode[index - 1] = "";
      setCode(newCode);
      inputRefs[index - 1].current?.focus();
    }
  };

  const handlePaste = (
    index: number,
    event: React.ClipboardEvent<HTMLInputElement>
  ) => {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData("text");
    if (/^\d{4}$/.test(pastedData)) {
      // Only accept pasting if it's exactly 4 digits
      const newCode = [...code];
      for (let i = 0; i < 4; i++) {
        if (index + i < inputRefs.length) {
          newCode[index + i] = pastedData.charAt(i);
        }
      }
      setCode(newCode);
    } else {
      event.preventDefault();
      setCodeError("Please paste exactly 4 digits");
    }
  };

  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.auth?.token);


  // const tokens = useSelector((state: any) => {
  //   if (state.auth?.isLoggedIn) {
  //     return state.auth?.token;
  //   } else {
  //     return null;
  //   }
  // });
  const tokens = useSelector((state: any) => state.auth?.isLoggedIn?.token);
  const tokenFromStorage = localStorage.getItem('token') || null;


  const verify = async ( verificationCode: string, token: string,) => {
    const userData = {
      code: verificationCode,
    };

    setLoading(true);
    try {
      const verificationResponse = await verifyCode(
        userData,
        tokenFromStorage ? tokenFromStorage : tokens
      );
    
      if (verificationResponse) {
        await dispatch(SET_TOKEN(token));
        setIsModalOpen(true);
        navigate("/");
        return true;
      }  else {
        return false;
      }
    } catch (error: any) {
    
      return false; // Return false for error cases
    } finally {
      setLoading(false);
    }
  };

  
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
     const enteredCode = code.join("");
    if (enteredCode.length === 4) {
       const verificationCode = code.join("");
       const verificationResult = await verify(
         verificationCode,
         tokenFromStorage ? tokenFromStorage : tokens
       );

       if (verificationResult !== false) {
        await dispatch(SET_TOKEN(token));
         navigate("/");
       }
       setCodeError("");
     } else {
       setCodeError("Please enter a 4-digit code");
     }
    
  };

  // useEffect(() => {
  //   verify( tokens, verificationCode, )
  // }, [ tokens]);


  const handleResendCode = async () => {
    const userData = {
      email: email
    }
    const resendResult = await resendVerificationCode(userData);
    if (resendResult) {
      toast.success("Code resent successfully");
    } else {
      // toast.error("Code resend failed");
    }
  };


  const companyNames = useSelector(
    (state: any) => state.auth?.newOrganization?.email
  );
  const email = companyNames;

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="w-[40.6%] sign-in-form px-[50px] py-[36px] mr-auto ml-auto  bg-white shadow-lg shadow-slate-100 rounded-[11.3px] ">
        <div>
          <h1 className=" text-3xl font-bold text-[#44566C] text-center">
            Get <span className="text-[#0081FF]">Started</span>{" "}
          </h1>
          <p className="text-[#8697A8]  text-center mr-auto ml-auto mt-6">
            We’ve sent a verification code to your email <br />{" "}
            <strong>{email}</strong>
            <span className="block">OTP expires after <b>thirty minutes</b></span>
          </p>
          <form
            onSubmit={handleSubmit}
            className="w-[90%] mr-auto ml-auto sign-in-form-inner"
          >
            <div className="passcode-input mt-14">
              <input
                ref={inputRefs[0]}
                value={code[0]}
                onChange={(e) => handleInputChange(0, e.target.value, e)}
                onKeyDown={(e) => handleKeyDown(0, e)}
                onPaste={(e) => handlePaste(0, e)}
                type="text"
                name="code"
                maxLength={1}
              />
              <input
                ref={inputRefs[1]}
                value={code[1]}
                onChange={(e) => handleInputChange(1, e.target.value, e)}
                onKeyDown={(e) => handleKeyDown(1, e)}
                onPaste={(e) => handlePaste(1, e)}
                type="text"
                name="code"
                maxLength={1}
              />
              <input
                ref={inputRefs[2]}
                value={code[2]}
                onChange={(e) => handleInputChange(2, e.target.value, e)}
                onKeyDown={(e) => handleKeyDown(2, e)}
                onPaste={(e) => handlePaste(2, e)}
                type="text"
                name="code"
                maxLength={1}
              />
              <input
                ref={inputRefs[3]}
                value={code[3]}
                onChange={(e) => handleInputChange(3, e.target.value, e)}
                onKeyDown={(e) => handleKeyDown(3, e)}
                onPaste={(e) => handlePaste(3, e)}
                type="text"
                name="code"
                maxLength={1}
              />
            </div>
            {codeError && (
              <p className="text-[#FF0101] text-xs mt-[3px]">{codeError}</p>
            )}
            <p className="text-[#8697A8]  text-center mr-auto ml-auto mt-6">
              Haven’t gotten the code in your mail?
            </p>
            <p className="text-center text-[#8697A8] underline mx-auto">
         
              <p className="cursor-pointer hover:opacity-80">
              <Button onClick={handleResendCode}>Resend Code</Button>
                </p>
            </p>

            <div className="w-full mt-[25px]">
              <Button className="sign-in-btn" type="submit" disabled={loading}>
                {loading ? (
                  <CircleLoader color="#ffffff" loading={loading} size={20} />
                ) : (
                  "Login"
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Verify;
