import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllSchedule,
  getVisitorLink,
} from "../../services/VisitorService";
import { SET_ALL_SCHEDULE } from "../../redux/features/allSchedules1DaySlice";
import { LOGOUT, setTokenWithExpiry } from "../../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import AllTable from "./StatusTable/AllTable";
import PendingTable from "./StatusTable/PendingTable";
import VisistedTable from "./StatusTable/VisistedTable";
import CancelledTable from "./StatusTable/CancelledTable";
import AbsentTable from "./StatusTable/AbsentTable";
import SActiveTable from "./StatusTable/ActiveTable";
import { toast } from "react-toastify";

enum Tabs {
  All = "",
  Active = "Active",
  Waiting = "Waiting",
  Completed = "Completed",
  Cancelled = "Cancelled",
  Absent = "Absent",
}

const SecDashboard = () => {
  const getActiveTabFromLocalStorage = () => {
    return localStorage.getItem("activeTab") || Tabs.All;
  };
  const [activeTab, setActiveTab] = useState(() => getActiveTabFromLocalStorage());
  const [links, setLink] = useState("");

  const currentDate = new Date();

  const navigate = useNavigate();
  const handleAllTabClick = () => {
    localStorage.setItem("activeTab", Tabs.All);
    setActiveTab(Tabs.All);
  };
  
  const handleActiveTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Active);
    setActiveTab(Tabs.Active);
  };

  const handleWaitingTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Waiting);
    setActiveTab(Tabs.Waiting);
  };

  const handleCompletedTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Completed);
    setActiveTab(Tabs.Completed);
  };

  const handleCanceledTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Cancelled);
    setActiveTab(Tabs.Cancelled);
  };

  const handleAbsentTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Absent);
    setActiveTab(Tabs.Absent);
  };

  const token = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  const dispatch = useDispatch();
  const fetchAllDay1Schedule = async () => {
    try {
      const data = await fetchAllSchedule(token);

      await dispatch(SET_ALL_SCHEDULE(data));
    } catch (err) {}
  };
  useEffect(() => {
    fetchAllDay1Schedule();
  }, []);

  const expiry = 47 * 60 * 60 * 1000 + 50 * 60 * 1000;

  useEffect(() => {
    dispatch<any>(setTokenWithExpiry(token, expiry, handleLogoutAndNavigate));
  }, [dispatch, token, expiry]);

  const handleLogoutAndNavigate = () => {
    dispatch(LOGOUT());
    navigate("/login"); // Navigate to the login page when session expires
  };

  const role = useSelector((state: any) => state.auth?.user?.role);

  const secretaryUser = role === "secretary";

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    if (links) {
      const textArea = document.createElement("textarea");
      textArea.value = links;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);

      setIsCopied(true);
      toast.success("Link Copied to Clipboard!");

      // Reset the copied state after a delay
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  const fetchLink = async () => {
    try {
      const urlLink = await getVisitorLink(token);
      setLink(urlLink?.data?.url);
    } catch (err) {}
  };

  useEffect(() => {
    fetchLink();
  }, []);

  const handleCopyClick = () => {
    if (links) {
      copyToClipboard();
    }
  };

  return (
    <div className="ml-[51px] mb-[57px] mt-[42px] mr-[34px] adminDash pt-[80px]">
      <div className="flex justify-between items-center font-bold create-dept-level px-6  visitors-title-text py-[5px] pt-[19px] mb-[33px]">
        <h2 className="text-[#44566c] text-[32px]"> Today's {activeTab} Visitors</h2>
        <p className="text-[#6B7A99] text-base">
          {currentDate.toLocaleDateString()}
        </p>
      </div>
      <div className="flex justify-between staff-status-and-link items-center">
        <div className="tabs w-fit cursor-pointer  px-[2px] rounded flex items-center justify-center bg-white shadow-md font-normal text-[15px] visitors-status-tab">
          <div
            className={`tab flex items-center justify-center border-r ${
              activeTab === Tabs.All
                ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
            }`}
            onClick={handleAllTabClick}
          >
            All
          </div>

          <div
            className={`tab flex items-center justify-center border-r ${
              activeTab === Tabs.Active
                ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
            }`}
            onClick={handleActiveTabClick}
          >
            Active
          </div>
          <div
            className={`tab flex items-center justify-center border-r ${
              activeTab === Tabs.Waiting
                ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
            }`}
            onClick={handleWaitingTabClick}
          >
            Waiting
          </div>
          <div
            className={`tab flex items-center justify-center border-r ${
              activeTab === Tabs.Completed
                ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
            }`}
            onClick={handleCompletedTabClick}
          >
            Visited
          </div>
          <div
            className={`tab flex items-center justify-center border-r ${
              activeTab === Tabs.Cancelled
                ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
            }`}
            onClick={handleCanceledTabClick}
          >
            Cancelled
          </div>
          <div
            className={`tab flex items-center justify-center ${
              activeTab === Tabs.Absent
                ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
            }`}
            onClick={handleAbsentTabClick}
          >
            Absent
          </div>
        </div>
        <div className="ml-auto">
          {secretaryUser ? (
            links ? (
              <div>
                <a href={links} target="_blank" rel="noopener noreferrer">
                  {/* Render a link */}
                </a>
                <button
                  className="bg-[#0081ff] w-40 rounded text-white text-center h-10 text-sm relative"
                  onClick={handleCopyClick}
                >
                  {isCopied ? "Copied" : "Copy Visitors Link"}
                  <span className="tooltip absolute bottom-full left-1/2 transform -translate-x-1/2  text-white px-2 py-1 rounded opacity-0 transition-opacity duration-300">
                    {isCopied ? "Link Copied" : "Copy to Clipboard"}
                  </span>
                </button>
              </div>
            ) : (
              <p>No link Available</p>
            )
          ) : null}
        </div>
      </div>
      {activeTab === Tabs.All && <AllTable />}
      {activeTab === Tabs.Active && <SActiveTable />}
      {activeTab === Tabs.Waiting && <PendingTable />}
      {activeTab === Tabs.Completed && <VisistedTable />}
      {activeTab === Tabs.Cancelled && <CancelledTable />}
      {activeTab === Tabs.Absent && <AbsentTable />}
    </div>
  );
};

export default SecDashboard;
