import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import { useForm, Controller } from "react-hook-form";
import { CloseIcon, DotsIcon, PersonIcon } from "../../icons/icon";
import { useModalContext } from "../../components/utilities/ModalContext";
import {
  deleteLevels,
  editLevel,
  fetchLevels,
} from "../../services/authService";
import { SET_LEVELS } from "../../redux/features/levelSlice";
import { Button, TextField } from "@mui/material";
import useOnClickOutside from "../../hooks/useOnClickOutside";

type Props = {};
const initialState = {
  id: "",
};

const initialState2 = {
  _id: "",
  name: "",
};

const UpdateLevel = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedItemPopUpId, setSelectedItemPopUpId] = useState("");
  const [selectedLevelUser, setSelectedLevelUser] = useState({});
  const [formData, setFormData] = useState(initialState);
  const [dataForm, setDataForm] = useState(initialState2);
  const [showLevel, setShowLevel] = useState([]);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const {
    modalDelLevelOpen,
    modalDeleteLevelClose,
    modalDeleteLevelOpen,
    modalEditLevelOpen,
    modalEditLevelsClose,
    modalEditLevelsOpen,
  } = useModalContext();

  const dispatch = useDispatch();

  const token = useSelector((state: any) => {
    if (state?.auth?.isLoggedIn) {
      return state?.auth?.token;
    } else {
      return null;
    }
  });

  const adminSignUp = useSelector(
    (state: any) => state.auth?.newOrganization?.admin?.role
  );

  const role = useSelector((state: any) => state.auth?.user?.role);

  const adminUser = role === "admin";
  const tokenFromStorage = localStorage.getItem("token") || null;

  const levels = useSelector((state: any) => state.levels?.levels?.data);
  const levelIds = levels;

  const sortedLevelIds =
    levelIds && levelIds.length > 0
      ? [...levelIds].sort((a, b) => a.levelName.localeCompare(b.levelName))
      : [];

  const popUpLevelRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(popUpLevelRef, () => {
    setSelectedItemPopUpId("");
  });
  const handleDeleteLevelSubmit = async (e: any) => {
    e.preventDefault();
    let tokenToUse;

    if (adminUser) {
      tokenToUse = token;
    } else {
      tokenToUse = tokenFromStorage;
    }

    if (selectedLevel) {
      try {
        setLoading(true);
        const userData = { id: selectedLevel };
        await deleteLevels(tokenToUse, userData);
        setFormData(initialState);
        setSelectedLevel("");
        window.location.reload();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchAllLevels = async () => {
    try {
      let tokenToUse;
      if (adminUser) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const allLevels = await fetchLevels(tokenToUse);
      setShowLevel(allLevels?.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllLevels();
  }, []);

  const editLevelDetails = handleSubmit(async (dataForm) => {
    const userData = { id: selectedLevel, name: dataForm.name };
    setLoading(true);
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminSignUp !== true) {
        // User has signed up
        tokenToUse = tokenFromStorage;
      } else {
        // User has logged in
        tokenToUse = token;
      }
      const data = await editLevel(userData, tokenToUse);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setDataForm(initialState2);
      window.location.reload();
      // setSelectedDepartment("");
      // fetchAllStaff();
      // modalStaffClose();
    } catch (error) {
      setLoading(false);
      // setSelectedDepartment("");
      setDataForm(initialState2);
    }
  });

  const renderLevelDetails = (level: any) => {
    if (selectedItemPopUpId === level?._id) {
      return (
        <div>
          <div className="staff-dots-modal shadow-xl" ref={popUpLevelRef}>
            <ul className="h-[120px] w-[160px] z-50 pt-2">
              <li
                onClick={() => {
                  setSelectedLevel(level?._id);
                  modalEditLevelsOpen();
                  setSelectedItemPopUpId("");
                  setSelectedLevelUser(level);
                }}
                className="text-[#44566c] text-[14px] hover:bg-[#F8FAFB] cursor-pointer"
              >
                <button className="w-full text-left pl-5 pr-2 py-[10px]">
                  Edit level
                </button>
              </li>

              <li
                className="text-[#fff] font-medium text-[14px] h-[40px] mt-3 rounded-[6px] bg-[#FF0101] hover:opacity-80 cursor-pointer mx-1"
                onClick={() => {
                  setSelectedLevel(level?._id);
                  modalDeleteLevelOpen(selectedLevel);
                  setSelectedItemPopUpId("");
                  setSelectedLevelUser(level);
                }}
              >
                <button className="w-full text-left pl-5 pr-2 py-[10px]">
                  Delete this level
                </button>
              </li>
            </ul>
          </div>
        </div>
      );
    }
    return null;
  };
  const renderDeleteLevelModal = () => {
    return (
      <Modal
        isOpen={modalDelLevelOpen}
        onRequestClose={() => {
          modalDeleteLevelClose();
          // setFormData(initialState);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        contentLabel="Add New Staff Modal"
        className="w-[40%] bg-[white]  rounded-[12px] py-[30px] max-w-[880px]  create-dept-modal "
      >
        <div className="w-[85%] mx-auto ">
          <div className="w-20 mx-auto">
            <PersonIcon />
          </div>
          <h1 className="text-center text-[#44566C] text-[20px] font-semibold capitalize">
            Are you sure you want to Delete <br />
            {(selectedLevelUser as any)?.levelName}?
          </h1>
          <div className="flex gap-4 justify-center items-center mx-auto mt-6 flex-wrap">
            <button
              className="bg-[#F1F1F1] w-[200px] text-[#44566C] h-12 rounded-md hover:border-[2px] hover:border-[#C4C4C4] cursor-pointer"
              onClick={() => {
                modalDeleteLevelClose();
                setFormData(initialState);
              }}
            >
              Cancel
            </button>
            <form onSubmit={handleDeleteLevelSubmit}>
              <button
                className="bg-[#FF0101] w-[232px] text-[#FCFCFD] h-12 rounded-md hover:opacity-80 cursor-pointer"
                type="submit"
              >
                Delete
              </button>
            </form>
          </div>
        </div>
      </Modal>
    );
  };

  const renderEditModal = () => {
    return (
      <Modal
        isOpen={modalEditLevelOpen}
        onRequestClose={() => {
          modalEditLevelsClose();
          // setFormData(initialState);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        contentLabel="Add New Staff Modal"
        className="w-[40%] bg-[white]  rounded-[12px] py-[30px] max-w-[880px]  create-dept-modal "
      >
        <div className="mx-auto z-50 w-[79.5%] justify-center justify-items-center">
          <div className="flex justify-end mb-[30px]">
            <div
              onClick={() => {
                modalEditLevelsClose();
                reset();
                // setDepartments([]);
              }}
              className="cursor-pointer"
            >
              <CloseIcon />
            </div>
          </div>
          <h1 className="text-center text-[#44566c] text-3xl  font-bold">
            Edit <span className="text-[#0081ff]">Level</span>
          </h1>
          <form
          // onSubmit={editDepartmentDetails}
          >
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Level name is required",
                  // minLength: {
                  //   value: 3,
                  //   message: "Name must be at least 3 characters",
                  // },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Level Name"
                    defaultValue={(selectedLevelUser as any)?.levelName || ""}
                    className="w-full  px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
          </form>

          <div className="w-full mt-[25px]">
            <Button
              className="create-dept-level-submit-btn"
              type="submit"
              disabled={loading}
              onClick={editLevelDetails}
            >
              Update
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      <div className="w-full bg-white rounded-[20px] py-[22px] pr-[24px] pl-[14px] text-[#44566C] mt-[40px]">
        <p className="pl-5 text-[#44566C] font-semibold text-[15px]">Levels</p>
        {showLevel && showLevel?.length > 0 ? (
          showLevel?.map((level: any) => (
            <div key={level?._id} className="flex mt-7 justify-between">
              <div className="flex">
                <p className="rounded-[7px] bg-[#F3F6FF] p-[10px] leading-none text-[#0081FF]  uppercase w-fit ml-4 font-bold">
                  {" "}
                  {`${level.levelName ? level.levelName?.charAt(0) : ""}`}
                </p>
                <p
                  className="ml-[12px] pt-2 text-[#44566C] text-[14px] font-normal"
                  style={{ fontFamily: "Inter" }}
                >
                  {level?.levelName?.charAt(0)?.toUpperCase() +
                    level?.levelName?.slice(1)}
                </p>
              </div>
              <div
                className="flex justify-center items-center h-[30px] cursor-pointer mr-6"
                onClick={() => {
                  if (selectedLevel === level?._id) {
                    setSelectedLevel(level?._id);
                    setSelectedItemPopUpId("");
                  } else {
                    setSelectedLevel(level._id);
                    setSelectedItemPopUpId(level._id);
                  }
                }}
              >
                <button className="text-[18px]">
                  <DotsIcon />
                </button>
                {renderLevelDetails(level)}
              </div>
            </div>
          ))
        ) : (
          <tr>
            <td colSpan={4}>No data found</td>
          </tr>
        )}
        {renderDeleteLevelModal()}
        {renderEditModal()}
      </div>
    </div>
  );
};

export default UpdateLevel;
