import React, { useEffect, useState, useRef } from "react";
import {
  ArrowDown,
  CloseIcon,
  DotsIcon,
  ExcelIcon,
  PersonIcon,
} from "../../icons/icon";
import Add from "../../icons/mingcute_add-fill.svg";
import Search from "../../icons/search.svg";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Eye from "../../icons/Eye.svg";
import Eye2 from "../../icons/eye-regular.svg";
import PhoneInput from "react-phone-number-input";
import { useForm, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import CreateSec from "../profile/CreateSec";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReceptionist,
  editReceptionist,
  getSecretaries,
} from "../../services/secretaryService";
import { SET_SECRETARY } from "../../redux/features/getSecretarySlice";
import { useModalContext } from "../../components/utilities/ModalContext";
import { changePassword } from "../../services/ProfileService";
import CircleLoader from "react-spinners/CircleLoader";
import useOnClickOutside from "../../hooks/useOnClickOutside";

type Props = {};

const initialState = {
  _id: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
};

const receptionistInitialState = {
  id: "",
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};


const Receptionist = (props: Props) => {
  const [editloading, setEditLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [data, setData] = useState([]);
  const currentDate = new Date();
  const [dropdownVisibility2, setDropdownVisibility2] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedItemPopUpId, setSelectedItemPopUpId] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [viewOldPassword, setViewOldPassword] = useState("");
  const [viewPassword, setViewPassword] = useState("");
  const [confirmViewPassword, setConfirmViewPassword] = useState("");
  const [dataForm, setDataForm] = useState(initialState);
  const [currentPage, setCurrentPage] = useState(0); // Start from 0 for zero-based indexing
  const itemsPerPage = 10;
  const [receptionistFormData, setReceptionistFormData] = useState(
    receptionistInitialState
  );
  const [phoneNumber, setPhoneNumber] = useState(
    (selectedUser as any)?.phoneNumber || ""
  );
  const [receptionist, setReceptionist] = useState([])
  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const popUpRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(popUpRef, () => {
    setSelectedItemPopUpId("");
  });

  const {
    modalEditReceptionistClose,
    modalEditReceptionistOpen,
    editReceptionistModalOpen,
    modalRemoveClose,
    modalRemoveOpen,
    removeModalOpen,
    modalPasswordClose,
    modalPasswordOpen,
    passwordModalOpen,
  } = useModalContext();
  const toggleDropDownVisibility2 = () => {
    setDropdownVisibility2(!dropdownVisibility2);
  };

  const role = useSelector((state: any) => state.auth?.user?.role);
  const adminUser = role === "admin";

  
  const token = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  const tokenFromStorage = localStorage.getItem('token') || null;

  const secLists = useSelector(
    (state: any) => state.getSecretary?.secretaries?.data
  );

  const fetchSec = async () => {
    try {
      let tokenToUse;
      if (adminUser) {
        tokenToUse = token;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const sec = await getSecretaries(tokenToUse);
      setReceptionist(sec?.data)
    } catch (err) {}
  };
  useEffect(() => {
    fetchSec();
  }, []);
  useEffect(() => {
    setPhoneNumber((selectedUser as any)?.phoneNumber || "");
  }, [selectedUser]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisibility2(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const initialValues = {
      _id: (selectedUser as any)?._id,
      lastName: (selectedUser as any)?.lastName || "",
      firstName: (selectedUser as any)?.firstName || "",
      email: (selectedUser as any)?.email || "",
      phoneNumber: (selectedUser as any)?.phoneNumber || "",
    };
    reset(initialValues);
    setSelectedItemId((selectedUser as any)?._id);
  }, [selectedUser]);

  const pageCount = Math.ceil(receptionist?.length / itemsPerPage);

  const handlePageChange = (selectedPage: any) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * itemsPerPage;

  const filteredRecept = receptionist?.filter((recep: any) =>
    recep?.firstName?.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  //Download excel file


  const columnsToExport = ["firstName", "lastName", "email", "phoneNumber", "status",];

  const filteredDataForExport = receptionist?.map((item: any) => {
    const filteredItem: Record<string, any> = {}; 
    columnsToExport.forEach((column) => {
      filteredItem[column] = item[column];
    });
    return filteredItem;
  });

  function convertToCSV(data: any) {
    const header = Object.keys(data[0]).join(",");
    const rows = data?.map((staffData: any) =>
      Object.values(staffData).join(",")
    );
    return [header, ...rows].join("\n");
  }

  const handleDownload = () => {
    const csvData = convertToCSV(filteredDataForExport);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "receptionist.csv";
    a.click();
    URL.revokeObjectURL(url);
    setDropdownVisibility2(false);
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  //Closing Modals
  const handleClose = () => {
    modalEditReceptionistClose();
    modalPasswordClose();
    reset();
  };

  const receptionistIds = useSelector((state: any) => {
    if (
      state.getSecretary?.secList?.data &&
      state.getSecretary.secList.data.length > 0
    ) {
      // Select the first ID from the array
      return state.getSecretary.secList.data[0]._id;
    } else {
      return null; // or [] if you prefer an empty array for no data
    }
  });

  //Edit Receptionist

  const editReceptionistDetails = handleSubmit(async (dataForm) => {
    const formattedPhoneNumber = `${phoneNumber}`;
    const userData = {
      _id: selectedItemId,
      firstName: dataForm.firstName,
      lastName: dataForm.lastName,
      email: dataForm.email,
      phoneNumber: formattedPhoneNumber,
    };
    setEditLoading(true);
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const data = await editReceptionist(userData,tokenToUse);
      setEditLoading(false);
      setTimeout(() => {
        setEditLoading(false);
      }, 1000);
      setDataForm(initialState);
      setSelectedItemId("");
      fetchSec();
      modalEditReceptionistClose();
    } catch (error) {
      setEditLoading(false);
      setSelectedItemId("");
      setDataForm(initialState);
    }
  });

  //Password Function

  const handleReceptionistResetSubmit = handleSubmit(
    async (receptionistFormData) => {
      setPasswordLoading(true);
      const userData = {
        oldPassword: receptionistFormData.oldPassword,
        newPassword: receptionistFormData.newPassword,
        confirmPassword: receptionistFormData.confirmPassword,
        id: selectedItemId,
      };
      try {
        const data = await changePassword(userData);
        setPasswordLoading(false);
        setReceptionistFormData(receptionistInitialState);
        window.location.reload();
      } catch (error) {
        setPasswordLoading(false);
      }
    }
  );

  // Delete Receptionist

  const handleDeleteSubmit = async () => {
    const userData = {
      id: selectedItemId,
    };
    setDeleteLoading(true);
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const data = await deleteReceptionist(tokenToUse, userData);
      setDeleteLoading(false);
      fetchSec();
      window.location.reload();
    } catch (error) {
      setDeleteLoading(false);
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleShowPassword = () => {
    setViewPassword((previousValue): any => !previousValue);
  };

  const handleOldShowPassword = () => {
    setViewOldPassword((previousValue): any => !previousValue);
  };

  const handleConfirmShowPassword = () => {
    setConfirmViewPassword((previousValue): any => !previousValue);
  };

  const renderEditModal = () => {
    return (
      <Modal
        isOpen={editReceptionistModalOpen}
        onRequestClose={handleClose}
        contentLabel="Add New Staff Modal"
        className="w-[40.3%] rounded-[12px] py-[30px] pb-[34px] bg-[white] add-staff-modal add-schedule-modal"
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className="w-[77.5%] mx-auto">
          {/* Modal Content */}
          <div className="flex justify-end mb-[34px]">
            <button onClick={handleClose} className="cursor-pointer">
              <CloseIcon />
            </button>
          </div>
          <h2 className="text-[#44566C] text-center font-bold text-[30px]">
            Edit Staff
          </h2>
          <form
            onSubmit={editReceptionistDetails}
            className="new-staff-form h-[470px]"
          >
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">First Name</label>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: "First name is required",
                  minLength: {
                    value: 3,
                    message: "Name must be at least 3 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="First Name"
                    defaultValue={(selectedUser as any).firstName || ""}
                    className="w-full  px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Last Name</label>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: "Last name is required",
                  minLength: {
                    value: 3,
                    message: "Last name must be at least 3 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Last Name"
                    defaultValue={(selectedUser as any)?.lastName || ""}
                    className="w-full px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px]  font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Email</label>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Enter a valid email. E.g, example@gmail.com",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Email"
                    defaultValue={(selectedUser as any)?.email || ""}
                    className="w-full px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <Grid item xs={12}>
                <label className="block mb-2 text-[#8697A8]">
                  Phone Number
                </label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: "Phone number is required",
                  }}
                  render={({ field, fieldState }) => (
                    <div>
                      <PhoneInput
                        id="phone-input"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={(value) => setPhoneNumber(value)}
                        defaultCountry="NG"
                        international
                        withCountryCallingCode
                        className={`custom-phone-input ${
                          fieldState.error ? "error-border-phone-input" : ""
                        }`}
                      />
                      {fieldState.error && (
                        <FormHelperText error>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                />
              </Grid>
            </div>
            <button
              type="submit"
              className="bg-[#0081ff] h-[44px] rounded-[5px] mt-[38px] text-center mx-auto cursor-pointer flex justify-center items-center w-[167px] text-[#FFF] text-[15px] font-medium hover:opacity-80"
            >
              {editloading ? (
                <CircleLoader color="#ffffff" loading={editloading} size={20} />
              ) : (
                "Update"
              )}
            </button>
          </form>
        </div>
      </Modal>
    );
  };

  const renderPasswordModal = () => {
    return (
      <Modal
        isOpen={passwordModalOpen}
        onRequestClose={handleClose}
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        contentLabel="Add New Staff Modal"
        className="w-[40.3%] rounded-[12px] py-[30px] pb-[34px] bg-[white] add-staff-modal add-schedule-modal"
      >
        <div className="w-[85%] mx-auto ">
          <div className="flex justify-end mb-[34px]">
            <button onClick={handleClose} className="cursor-pointer">
              <CloseIcon />
            </button>
          </div>
          <h1 className="text-center text-[#44566C] text-[20px] font-semibold">
            Change Password
          </h1>

          <div className="gap-3 mx-auto mt-6">
            <form onSubmit={handleReceptionistResetSubmit}>
              <div>
                <label className="block mb-2 text-[#8697A8]">
                  Old Password
                </label>
                <Controller
                  name="oldPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                    maxLength: {
                      value: 13,
                      message: " Password must not be more than 13 characters",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="w-full">
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          {...field}
                          id="outlined-adornment-password"
                          type={viewOldPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleOldShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {viewOldPassword ? (
                                  <img src={Eye2} alt="password" width={15} />
                                ) : (
                                  <img src={Eye} alt="password" width={15} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          placeholder="Password"
                        />
                      </FormControl>
                      {fieldState.error && (
                        <p className="text-[#FF0101] text-xs mt-[3px]">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="mt-[22px]">
                <label className="block mb-2 text-[#8697A8]">
                  New Password
                </label>
                <Controller
                  name="newPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                    maxLength: {
                      value: 13,
                      message: "Password must not be more than 13 characters",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="w-full">
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          {...field}
                          id="outlined-adornment-password"
                          type={viewPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {viewPassword ? (
                                  <img src={Eye2} alt="password" width={15} />
                                ) : (
                                  <img src={Eye} alt="password" width={15} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          placeholder="Password"
                        />
                      </FormControl>
                      {fieldState.error && (
                        <p className="text-[#FF0101] text-xs mt-[3px]">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="mt-[22px]">
                <label className="block mb-2 text-[#8697A8]">
                  Confirm New Password
                </label>
                <Controller
                  name="confirmPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                    maxLength: {
                      value: 13,
                      message: "Password must not be more than 13 characters",
                    },
                    // validate: validatePasswordMatch,
                  }}
                  render={({ field, fieldState }) => (
                    <div className="w-full">
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          {...field}
                          id="outlined-adornment-password"
                          type={confirmViewPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleConfirmShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {confirmViewPassword ? (
                                  <img src={Eye2} alt="password" width={15} />
                                ) : (
                                  <img src={Eye} alt="password" width={15} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          placeholder="Password"
                        />
                      </FormControl>
                      {fieldState.error && (
                        <p className="text-[#FF0101] text-xs mt-[3px]">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className=" w-[150px] mx-auto mt-4">
                <button
                  className="bg-[#0081ff] h-[44px] rounded-[5px] mt-[38px] text-center mx-auto cursor-pointer flex justify-center items-center w-[167px] text-[#FFF] text-[15px] font-medium hover:opacity-80"
                  type="submit"
                >
                  {passwordLoading ? (
                    <CircleLoader
                      color="#ffffff"
                      loading={passwordLoading}
                      size={20}
                    />
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  };

  const renderRemoveModal = () => {
    return (
      <Modal
        isOpen={removeModalOpen}
        onRequestClose={() => {
          modalRemoveClose();
          // setFormData(initialState);
        }}
        contentLabel="Remove Receptionist Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        className="w-[35%] bg-[white]  rounded-[12px] py-[30px] max-w-[880px]  create-dept-modal "
      >
        <div className="w-[85%] mx-auto ">
          <div className="w-20 mx-auto">
            <PersonIcon />
          </div>
          <h1 className="text-center text-[#44566C] text-[20px] font-semibold capitalize">
            Are you sure you want to remove this user <br />{" "}
            {`${(selectedUser as any)?.firstName} ${
              (selectedUser as any)?.lastName
            }`}
            ?
          </h1>
          <div className="flex justify-between gap-3 mx-auto mt-6">
            <button
              className="bg-[#F1F1F1] w-[232px] text-[#44566C] h-12 rounded-md hover:border-[2px] hover:border-[#C4C4C4]"
              onClick={() => {
                modalRemoveClose();
                // setFormData(initialState);
              }}
            >
              Cancel
            </button>
            <form onSubmit={handleSubmit(handleDeleteSubmit)}>
              <button
                className="bg-[#FF0101] w-[200px] text-[#FCFCFD] h-12 rounded-md hover:opacity-80 cursor-pointer"
                type="submit"
              >
                Remove
              </button>
            </form>
          </div>
        </div>
      </Modal>
    );
  };

  const renderDetails = (item: any) => {
    if (selectedItemPopUpId === item._id) {
      return (
        <div className="staff-dots-modal shadow-xl" ref={popUpRef}>
          <ul className="h-[150px] w-[160px] z-50 pt-2">
            <li
              onClick={() => {
                setSelectedItemId(item?._id);
                modalEditReceptionistOpen();
                setSelectedItemPopUpId("");
                setSelectedUser(item);
              }}
              className="text-[#44566c] text-[14px] hover:bg-[#F8FAFB] cursor-pointer"
            >
              <button className="w-full text-left pl-5 pr-2 py-[10px]">
                View and Edit
              </button>
            </li>
            <li
              className="text-[#44566c] text-[14px] hover:bg-[#F8FAFB]"
              onClick={() => {
                setSelectedItemId(item?._id);
                modalPasswordOpen();
                setSelectedItemPopUpId("");
                setSelectedUser(item);
              }}
            >
              <button className="w-full text-left pl-5 pr-2 py-[10px]">
                Change Password
              </button>
            </li>
            <li
              onClick={() => {
                setSelectedItemId(item?._id);
                modalRemoveOpen();
                setSelectedItemPopUpId("");
                setSelectedUser(item);
              }}
              className="text-[#fff] font-medium text-[14px] h-[40px] mt-3 rounded-[6px] bg-[#FF0101] hover:opacity-80 cursor-pointer mx-1"
            >
              <button className="w-full h-full text-left pl-4">
                Remove User
              </button>
            </li>
          </ul>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="ml-[55px] mb-[57px] mt-[42px] mr-[40px] adminDash staff-page pt-[80px]">
      <div className="flex justify-between  items-center font-bold create-dept-level staff-title-text px-6  py-[5px] pt-[19px] mb-[33px]">
        <h2 className="text-[#44566c] text-[32px]">Receptionist</h2>
        <p className="text-[#6B7A99] text-base">
          {currentDate.toLocaleDateString()}
        </p>
      </div>
      <div
        className="bg-white flex flex-wrap justify-between gap-[18px] border
      border-[#dedede] border-solid px-[21px] py-6 mt-[33px] visitors-search-bar
      mb-[33px]"
      >
        <div className="flex items-center px-4 py-2 border border-[#EAEAEA] border-solid flex-1 rounded-[3px] visitors-search-bar-inner">
          <img
            src={Search}
            alt="Search"
            className="w-[20px] h-[20px] mr-[9px]"
          />
          <input
            type="search"
            placeholder="Search"
            className="flex-1"
            name="searchValue"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </div>
        <div>
          <CreateSec />
        </div>
        <div className="export-btn-container" ref={dropdownRef}>
          <div
            className="flex w-[96px] text-sm rounded-[4px] py-2 items-center justify-center boder-solid border-2 border-[#EAEAEA] text-[#666] font-medium export-btn cursor-pointer"
            onClick={() => {
              toggleDropDownVisibility2();
            }}
          >
            <button className="cursor-pointer">Export</button>
            <span className="cursor-pointer">
              <ArrowDown />
            </span>
          </div>
          {dropdownVisibility2 && (
            <div>
              <ul className="dropdown w-[96px] shadow-md text-center ">
                <li
                  className="text-[#44566c] h-[35px] text-[12px] cursor-pointer flex items-center justify-center excel-container"
                  onClick={handleDownload}
                >
                  <ExcelIcon />
                  <p className="ml-[10px] text-[#44566C] font-normal text-[15px]">
                    Excel
                  </p>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="visitors-table bg-white shadow-sm  pb-[30px] px-3 w-full">
        <div
          style={{
            maxWidth: "100%",
            overflowX: "auto",
          }}
          className="table-inner-container"
        >
          <table className="w-full table-content">
            <thead className="w-full text-[14px] h-[57px] bg-[#FAFAFA] ">
              <tr>
                <th className="w-[200px] rounded-l-[10px] text-[#44566C] text-sm font-semibold text-left pl-[16px]">
                  Name
                </th>
                <th className="w-[200px] text-[#44566C] text-sm font-semibold text-left">
                  Email
                </th>
                <th className="w-[200px] text-[#44566C] text-sm font-semibold text-left">
                  Phone Number
                </th>
                <th className="w-[200px] text-[#44566C] text-sm font-semibold text-left">
                  Status
                </th>
                <th className="w-[30px] text-[#44566C] text-sm font-semibold text-left rounded-r-[10px]"></th>
              </tr>
            </thead>
            <tbody>
              {filteredRecept?.length > 0 ? (
                filteredRecept?.map((item: any) => {
                  return (
                    <tr key={item._id}>
                      <td className="text-[#44566c] text-[14px] capitalize font-medium pt-[19px] flex items-center">
                        <p className="rounded-[7px] bg-[#F3F6FF] p-[10px] leading-none text-[#0081FF]  uppercase w-fit ml-4 font-bold">
                          {" "}
                          {`${item.firstName ? item.firstName?.charAt(0) : ""}${
                            item.lastName ? item.lastName?.charAt(0) : ""
                          }`}
                        </p>
                        <p className="ml-[12px]">
                          {`${item.firstName} ${item.lastName}`}
                        </p>
                      </td>
                      <td className="text-[#44566c] text-[14px] pt-[18px]">
                        {item.email}
                      </td>
                      <td className="text-[#44566c] text-[14px] pt-[18px]">
                        {item.phoneNumber}
                      </td>
                      <td className="text-[#44566c] text-[14px] pt-[18px] capitalize">
                        {item.status}
                      </td>
                      <td className="text-[#44566c] text-[14px] pt-[18px]">
                        <div
                          className="flex justify-center items-center h-[30px] cursor-pointer"
                          onClick={() => {
                            if (selectedItemId === item._id) {
                              setSelectedItemId("");
                              setSelectedItemPopUpId("");
                            } else {
                              setSelectedItemId(item._id);
                              setSelectedItemPopUpId(item._id);
                            }
                          }}
                        >
                          <button className="text-[18px]">
                            <DotsIcon />
                          </button>
                        </div>
                        {renderDetails(item)}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>No data found</td>
                </tr>
              )}
              {renderEditModal()}
              {renderPasswordModal()}
              {renderRemoveModal()}
            </tbody>
          </table>
        </div>
        <div className="flex pagination-comp">
          <ReactPaginate
            className="flex bg-white mx-auto h-[40px] text-sm text-[#44566c]
            rounded-[10px] pagination mt-[33px]"
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            previousLinkClassName={"previous-page"}
            nextLinkClassName={"next-page"}
            disabledClassName={"disabled-page"}
            activeClassName={"active-page"}
            breakLabel={"..."}
          />
        </div>
      </div>
    </div>
  );
};

export default Receptionist;
