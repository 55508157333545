import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Mail from "../../icons/Dark.svg";
import {
  forgotPassword,
  emailValidation,
  verifyEmail,
} from "../../services/authService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import CircleLoader from "react-spinners/CircleLoader";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const handleForgotSubmit = handleSubmit(async (formData) => {
    const data = {
      email: formData.email,
    };
    try {
      setLoading(false);
      const response = await verifyEmail(data);
      if (response.status === true) {
        toast.success("Email sent successfully!");
        const userData = {
          email: formData.email,
        };
        await forgotPassword(userData);
        navigate("/resetpassword", { state: { email: formData.email } });
        reset();
      } else {
        // toast.error(response?.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  });

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="w-[40.6%] sign-in-form px-[50px] py-[36px] mr-auto ml-auto  bg-white shadow-lg shadow-slate-100 rounded-[11.3px] ">
        <h1 className="text-[30px] font-bold text-[#44566C] text-center">
          Forgot<span className="text-[#0081FF]"> Password</span>{" "}
        </h1>
        <form
          className="w-[80%] mr-auto ml-auto sign-in-form-inner"
          onSubmit={handleForgotSubmit}
        >
          <div className="mt-[55px] email-container">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Enter a valid email. E.g, example@gmail.com",
                },
              }}
              render={({ field, fieldState }) => (
                <div className="w-full">
                  <FormControl fullWidth>
                    <OutlinedInput
                      {...field}
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={Mail} alt="Mail" />
                        </InputAdornment>
                      }
                      placeholder="Email"
                    />
                  </FormControl>
                  {fieldState.error && (
                    <p className="text-[#FF0101] text-xs mt-[3px]">
                      {fieldState.error.message}
                    </p>
                  )}
                </div>
              )}
            />
          </div>
          {/* BUTTON */}

          <div className="w-full mt-[50px]">
            <Button className="sign-in-btn" type="submit" disabled={loading}>
              {loading ? (
                <CircleLoader color="#ffffff" loading={loading} size={20} />
              ) : (
                "Next"
              )}
            </Button>
          </div>
        </form>

        <p className="text-center text-base mt-[55px] font-normal text-[#0081FF]">
          <Link to="/login" className="hover:opacity-80">
            {" "}
            Have an account?
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
