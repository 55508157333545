import React, { useEffect, useState } from "react";
import { useModalContext } from "../../components/utilities/ModalContext";
import Modal from "react-modal";
import { CloseIcon } from "../../icons/icon";
import { toast } from "react-toastify";
import {
  createUser,
  fetchStaff,
  verifyLink,
} from "../../services/staffService";
import { useSelector, useDispatch } from "react-redux";
import { SET_NEW_STAFF } from "../../redux/features/addStaffSlice";
import FadeLoader from "react-spinners/FadeLoader";
import NoPage from "../Visit/NoPage";
import { useLocation, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { TextField, Button } from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import { LOGOUT, setTokenWithExpiry } from "../../redux/features/authSlice";
import { fetchDeptBYId, fetchLevelBYId, identifyOrganization } from "../../services/authService";
import Avartar from "../../icons/User-avatar.svg.png";
import {
  fetchNotifications,
  readNotification,
} from "../../services/notificationService";
import { SET_NOTIFICATION } from "../../redux/features/notificationSlice";
import CircleLoader from "react-spinners/CircleLoader";
import PhoneInput from "react-phone-number-input";
Modal.setAppElement("#root");

const initialState = {
  createId: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  gender: "",
  oracleNumber: "",
  levelId: "",
  departmentId: "",
};

interface Organization {
  status: boolean;
  message: string;
  data: {
    _id: string;
    organizationName: string;
    email: string;
    logo: string; // You can update this type if you have a specific type for logos
    verified: boolean;
    departments: string[];
    levels: string[];
    staff: string[];
    visitors: string[];
    createdAt: string; // You can use a Date type if you parse this date string
    updatedAt: string; // You can use a Date type if you parse this date string
    __v: number;
    admin: string;
    secretary: string;
  };
}

const CreateUser = () => {
  const { isModalOpening, modalIsClosing, modalIsOpening } = useModalContext();
  const [formData, setFormData] = useState(initialState);
  const { firstName, lastName, email, phoneNumber, gender, oracleNumber } = formData;
  const [loading, setLoading] = useState(false);
  const [phonesNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const createIdFromQuery = queryParams.get("org");
  const [orgById, setOrgById] = useState('');
  const [createId, setCreateId] = useState(createIdFromQuery);
  const [isVerified, setIsVerified] = useState(false);
  const [showDept, setShowDept] = useState([]);
  const [showLevel, setShowLevel] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [identify, setIdentify] = useState<Organization | null>(null);
  const dispatch = useDispatch();

  const levels = useSelector((state: any) => state.levels?.levels?.data);
  const levelIds = levels;

  const departments = useSelector(
    (state: any) => state.departments?.departments?.data
  );
  const deptIds = departments;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const tokenFromStorage = localStorage.getItem('token') || null;
  const tokens = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  
  const adminSignUp = useSelector(
    (state: any) => state.auth?.newOrganization?.admin?.role
  );
  const role = useSelector((state: any) => state.auth?.user?.role);
  const secretaryUser = role === "secretary";
  const adminUser = role === "admin";

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const expiry = 47 * 60 * 60 * 1000 + 50 * 60 * 1000;

  useEffect(() => {
    const tokenToUse = tokens || tokenFromStorage;
    dispatch<any>(setTokenWithExpiry(tokenToUse, expiry, handleLogoutAndNavigate));
  }, [dispatch, tokens, expiry]);

  const handleLogoutAndNavigate = () => {
    dispatch(LOGOUT());
    navigate("/login");
  };

  const orgId = useSelector(
    (state: any) => state.auth?.user?.organization?._id
  );

  const verifyGetLink = async () => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const org = searchParams.get("org");
      if (org !== null && org.trim() !== "") {
        // Verify the link
        const linkVerificationResult = await verifyLink(org);
        if (linkVerificationResult.status === true) {
          setIsVerified(true);
        } else {
          navigate("/nopage");
        }
      } else {
        // Handle missing org parameter (Show page not found or error message)
        navigate("/nopage");
      }
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        // Handle invalid org parameter (Show page not found or error message)
        navigate("/nopage");
      } else {
        setIsVerified(false);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    verifyGetLink();
  }, []);

  const identifyOrg = async () => {
    try {
      // Fetch organization
      const data = await identifyOrganization(createIdFromQuery);
      setIdentify(data);

      const organizationId = data.data?._id;
       setOrgById(organizationId)
    } catch (error) {
    }
  };




  useEffect(() => {
    identifyOrg();
  }, []);

  const handleClose = () => {
    modalIsClosing();
    reset();
    setSelectedLevel("");
    setSelectedDepartment("");
  };

  const addUser = handleSubmit(async (formData) => {
    setLoading(true);
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      setErrorMessage("");

      const formattedPhoneNumber = `${formData.phoneNumber} ${phonesNumber}`;
      const userData = {
        createId: createId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phoneNumber: formData.phoneNumber.toString(),
        gender: formData.gender,
        oracleNumber: formData.oracleNumber,
        levelId: selectedLevel,
        departmentId: selectedDepartment,
      };
      const data = await createUser(
        userData,
       tokenToUse
      );
      await dispatch(SET_NEW_STAFF(data));
      if (data.status === true) {
        toast.success("Staff added successfully");
        reset();
        setSelectedLevel("");
        setSelectedDepartment("");
        fetchNotification();
        window.location.reload();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  });

  

    //Fetch Dept
const fetchAllDept = async () => {
  try {
    const allDept = await fetchDeptBYId( orgById);
    setShowDept(allDept?.data)
  } catch (err) {}
};

useEffect(() => {
  if (orgById) {
    // Call fetchEveryStaff only if orgById is defined
    fetchAllDept();
  }
}, [orgById]);

//Fetch Levels
const fetchAllLevels = async () => {
  try {
    const allLevels = await fetchLevelBYId(orgById );
    setShowLevel(allLevels?.data)
  } catch (err) {}
};

useEffect(() => {
  if (orgById) {
    // Call fetchEveryStaff only if orgById is defined
    fetchAllLevels();
  }
}, [orgById]);

  const companyName = useSelector(
    (state: any) => state.auth?.user?.organization?.organizationName
  );


  const name = companyName;

  const companyLogo = useSelector(
    (state: any) => state.auth?.user?.organization?.logo
  );

  const Logo = companyLogo;

  const fetchNotification = async () => {
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const allNotifications = await fetchNotifications(tokenToUse, false);
      await dispatch(SET_NOTIFICATION(allNotifications));
      await readNotification(allNotifications?.data?.viewed, tokenToUse);
    } catch (err) {}
  };
  useEffect(() => {
    fetchNotification();
  }, []);

  
  const genderData = [
    "male",
    "female"
  ]

  return (
    <div>
      {isLoading ? (
        <div className="w-[80px] mx-auto mt-[21%]">
          <FadeLoader />
        </div>
      ) : isVerified ? (
        <div>
          <div>
            <div className="flex justify-center items-center h-screen w-screen">
              <div>
                <div>
                  {identify?.data?.logo ? (
                    <img
                      src={identify?.data?.logo}
                      alt="Company Logo"
                      className="h-[200px] w-[200px] rounded-full mx-auto shadow "
                    />
                  ) : (
                    <img
                      src={Avartar}
                      alt="Company Logo"
                      className="h-[200px] w-[200px] rounded-full mx-auto shadow "
                    />
                  )}
                </div>
                <h1 className="text-center text-4xl mt-5 font-bold text-[#44566C] mx-auto">
                  Welcome To{" "}
                  <span className="text-[#0081ff]">
                    {" "}
                    {identify?.data?.organizationName}{" "}
                  </span>
                </h1>
                <h2 className="text-center mt-5 text-lg font-semibold text-[#44566C] mx-auto">
                  Create Staff
                </h2>
                <div className="flex justify-center items-center mt-5">
                  <button
                    className="bg-[#0081ff] w-[170px] h-[40px] text-center rounded text-white shadow-md flex justify-center items-center "
                    onClick={modalIsOpening}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
            <Modal
              isOpen={isModalOpening}
              onRequestClose={handleClose}
              contentLabel="Add New Staff Modal"
              className="w-[48.3%] rounded-[12px] py-[30px] pb-[34px] bg-[white] add-staff-modal add-schedule-modal"
              // style={{
              //   content: {
              //     overflowY: "auto",
              //   },
              // }}
              style={{
                overlay: {
                  backgroundColor: "rgba(42, 42,42, 0.9)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            >
              <div className="w-[77.5%] mx-auto">
                {/* Modal Content */}
                <div className="flex justify-end mb-[34px]">
                  <div className="cursor-pointer" onClick={handleClose}>
                    <CloseIcon />
                  </div>
                </div>
                <h2 className="text-[#44566C] text-center font-bold text-[30px]">
                  Create Staff
                </h2>
                <form onSubmit={addUser} className="new-staff-form h-[470px]">
                  <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
                    <label className="block mb-2 text-[#8697A8]">
                      First Name
                    </label>
                    <Controller
                      name="firstName"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "First name is required",
                        minLength: {
                          value: 3,
                          message: "Name must be at least 3 characters",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          placeholder="First Name"
                          className="w-full  px-[19px] py-3 rounded"
                          variant="outlined"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
                    <label className="block mb-2 text-[#8697A8]">
                      Last Name
                    </label>
                    <Controller
                      name="lastName"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Last name is required",
                        minLength: {
                          value: 3,
                          message: "Last name must be at least 3 characters",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          placeholder="Last Name"
                          className="w-full px-[19px] py-3 rounded"
                          variant="outlined"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mt-[38px]  font-normal text-[15px] mr-[10px]">
                    <label className="block mb-2 text-[#8697A8]">Email</label>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message:
                            "Enter a valid email. E.g, example@gmail.com",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          placeholder="Email"
                          className="w-full px-[19px] py-3 rounded"
                          variant="outlined"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
                    <label className="block mb-2 text-[#8697A8]">
                      Phone Number
                    </label>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Phone number is required",
                      }}
                      render={({ field, fieldState }) => (
                        <div>
                          <PhoneInput
                            id="phone-input"
                            placeholder="Phone Number"
                            value={field.value}
                            onChange={(value) => field.onChange(value)}
                            defaultCountry="NG"
                            international
                            withCountryCallingCode
                            className={`custom-phone-input ${
                              fieldState.error ? "error-border-phone-input" : ""
                            }`}
                          />
                          {fieldState.error && (
                            <FormHelperText error>
                              {fieldState.error.message}
                            </FormHelperText>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
            <label className="block text-[#8697A8] mb-2">
             Gender
            </label>
            <Controller
              name="gender"
              control={control}
              defaultValue=""
              rules={{ required: "Select a gender" }}
              render={({ field, fieldState }) => (
                <div>
                  <Select
                    {...field}
                    className="w-full bg-[#F8FAFB] px-[19px] py-3 rounded capitalize"
                   value={field.value}
                     onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                    style={{ height: "42px" }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    error={!!fieldState.error}
                  >
                    <MenuItem disabled value="">
                     Select a gender
                    </MenuItem>
                    {genderData?.map((gender: any, _id: any) => (
                      <MenuItem key={_id} value={gender} className="capitalize">
                      {gender}
                    </MenuItem>
                    ))}
                  </Select>
                  {fieldState.error && (
                    <FormHelperText error>
                      {fieldState.error.message}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
          </div>
          <div className="mt-[38px]  font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Oracle Number</label>
              <Controller
                name="oracleNumber"
                control={control}
                defaultValue=""
                rules={{
                  required: "oracleNumber is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Oracle Number"
                    className="w-full px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
                  <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
                    <label className="block mb-2 text-[#8697A8]">Level</label>
                    <Controller
                      name="level"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Select a level" }}
                      render={({ field, fieldState }) => (
                        <div>
                          <Select
                            {...field}
                            className="w-full bg-[#F8FAFB]  px-[19px] py-3 capitalize rounded"
                            value={selectedLevel}
                            onChange={(e: any) => {
                              setSelectedLevel(e.target.value);
                              field.onChange(e);
                            }}
                            style={{ height: "42px" }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            error={!!fieldState.error}
                          >
                            <MenuItem disabled value="">
                              Select a Level
                            </MenuItem>
                            {showLevel?.map((levelIdss: any, _id: any) => (
                              <MenuItem
                                key={_id}
                                value={levelIdss?._id}
                                className="capitalize"
                              >
                                {levelIdss?.levelName}
                              </MenuItem>
                            ))}
                          </Select>
                          {fieldState.error && (
                            <FormHelperText error>
                              {fieldState.error.message}
                            </FormHelperText>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
                    <label className="block mb-2 text-[#8697A8]">
                      Department
                    </label>
                    <Controller
                      name="department"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Select a department" }}
                      render={({ field, fieldState }) => (
                        <div>
                          <Select
                            {...field}
                            className="w-full bg-[#F8FAFB] px-[19px] py-3 capitalize rounded"
                            value={selectedDepartment}
                            onChange={(e: any) => {
                              setSelectedDepartment(e.target.value);
                              field.onChange(e);
                            }}
                            style={{ height: "42px" }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            error={!!fieldState.error}
                          >
                            <MenuItem disabled value="">
                              Select a Department
                            </MenuItem>
                            {showDept?.map((department: any, _id: any) => (
                              <MenuItem
                                key={_id}
                                value={department?._id}
                                className="capitalize"
                              >
                                {department?.departmentName}
                              </MenuItem>
                            ))}
                          </Select>
                          {fieldState.error && (
                            <FormHelperText error>
                              {fieldState.error.message}
                            </FormHelperText>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-[#0081ff] h-[44px] rounded-[5px] mt-[38px] text-center mx-auto cursor-pointer flex justify-center items-center w-[167px] text-[#FFF] text-[15px] font-medium hover:opacity-80"
                  >
                    {loading ? (
                      <CircleLoader
                        color="#ffffff"
                        loading={loading}
                        size={20}
                      />
                    ) : (
                      "Create Staff"
                    )}
                  </button>
                </form>
              </div>
            </Modal>
          </div>
        </div>
      ) : (
        <NoPage />
      )}
    </div>
  );
};

export default CreateUser;
