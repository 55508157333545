import React from "react";

const Footer = () => {
  return (
    <footer className="bg-white h-[62px] px-[16px] flex justify-end items-center ml-[51px] footer-div">
      <p className=" text-[#6F6C90] font-normal text-lg">
        Copyright &copy; 2022 | All Rights Reserved{" "}
      </p>
    </footer>
  );
};

export default Footer;
