// tokenExpirationMiddleware.js

import { LOGOUT, SET_TOKEN, selectToken } from '../redux/features/authSlice';
import { toast } from 'react-toastify';

const tokenExpirationMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type === SET_TOKEN) {
    const { auth } = store.getState();
    const currentTimestamp = Date.now();

    const expirationTimestamp = currentTimestamp + 10 * 60 * 1000;
    if (auth.tokenExpirationTimestamp && currentTimestamp > auth.tokenExpirationTimestamp) {
      store.dispatch(LOGOUT());
      toast.error('Session Expired');
      if (action.navigateCallback && typeof action.navigateCallback === 'function') {
        action.navigateCallback('/'); // You can pass the desired URL
      }
    }

    store.dispatch(selectToken(expirationTimestamp))
   
  }

  return next(action);
};

export default tokenExpirationMiddleware;
