import { createSlice, PayloadAction } from "@reduxjs/toolkit";
 interface SECRETARY {
    email: string;
    password: string
}

interface AddSecretary {
    newSecretary: SECRETARY | null;
  }

  const initialState: AddSecretary = {
    newSecretary: null,
  };

  const createSecrtarySlice = createSlice({
    name: 'secretary',
    initialState,
    reducers: {
        SET_NEW_SECRETARY: (state, action: PayloadAction<SECRETARY>) => {
            state.newSecretary = action.payload;
          },
    }
  })

  export const {SET_NEW_SECRETARY} = createSecrtarySlice.actions

  export default createSecrtarySlice.reducer