import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch, AnyAction } from "redux";
import { toast } from "react-toastify";

const Organization = {
  organizationName: "",
  logo: "",
  email: "",
  departments: [],
  levels: [],
  staff: [],
  visitors: [],
};
interface OrganizationInterface {
  organizationName?: string;
  logo?: string;
  email?: string;
  departments?: any[];
  levels?: any[];
  staff?: any[];
  visitors?: any[];
}

interface User {
  organization?: OrganizationInterface;
  organizationName?: string;
  token?: string;
  email?: string;
  logo?: string;
  verified?: boolean;
  departments?: any[];
  levels?: any[];
  staff?: any[];
  visitors?: any[];
}

interface AuthState {
  token: string | null;
  name: string;
  isLoggedIn: boolean;
  user: User;
  expiry: number | null;
  expirationTimer: NodeJS.Timeout | null;
  // newOrganization: null;
  newOrganization: OrganizationInterface | null;
}

const User = {
  organization: Organization,
  organizationName: "",
  token: "",
  email: "",
  logo: "",
  verified: false,
  departments: [],
  levels: [],
  staff: [],
  visitors: [],
};
const initialState: AuthState = {
  token: "",
  name: "",
  isLoggedIn: true,
  user: User,
  expiry: null,
  expirationTimer: null,
  newOrganization: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_USER(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    SET_NEWORG(state, action: PayloadAction<any>) {
      state.newOrganization = action.payload;
    },
    SET_TOKEN(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    SET_LOGIN(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
    },
    SET_EXPIRY(state, action: PayloadAction<number | null>) {
      state.expiry = action.payload;
    },

    SET_EXPIRATION_TIMER(state, action: PayloadAction<NodeJS.Timeout | null>) {
      state.expirationTimer = action.payload;
    },
    UPDATE_NAME(state, action: PayloadAction<string>) {

      if (state.user && state.user.organization) {
        state.user.organization = {
          ...(state.user.organization as any),
          organizationName: action.payload,
        };
      }
      else if (state.newOrganization) {
        state.newOrganization = {
          ...(state.newOrganization as any),
          organizationName: action.payload,
        };
      }

    },
    
    UPDATE_LOGO(state, action: PayloadAction<string>) {
      if (state.user && state.user.organization) {
        state.user.organization = {
          ...(state.user.organization || {}),
          logo: action.payload,
        };
      } else if (state.newOrganization) {
        state.newOrganization = {
          ...(state.newOrganization || {}),
          logo: action.payload,
        };
      }
    },
    



    LOGOUT(state) {
      state.token = null;
      state.isLoggedIn = false;
      state.user = User;
    },
  },
});

export const {
  SET_LOGIN,
  SET_USER,
  SET_TOKEN,
  LOGOUT,
  SET_EXPIRY,
  SET_EXPIRATION_TIMER,
  SET_NEWORG,
  UPDATE_NAME,
  UPDATE_LOGO,
} = authSlice.actions;

export const selectIsLoggedIn = (state: any) => state.auth.isLoggedIn;
export const selectIsError = (state: any) => state.auth.isError;
export const selectName = (state: any) => state.auth.name;
export const selectUser = (state: any) => state.auth.user;
export const selectToken = (state: any) => state.auth.token;

export const setTokenWithExpiry =
  (token: string, expiry: number, onSessionExpired: () => void) =>
  (dispatch: Dispatch<AnyAction>) => {

    dispatch(SET_TOKEN(token));
    dispatch(SET_EXPIRY(expiry));
    const expirationTimer = setTimeout(() => {
      toast.error("Your session has expired. You have been logged out.");
      onSessionExpired(); // Call the provided callback function when the session expires
    }, expiry);

    // Return the timer ID for cleanup
    return expirationTimer;
  };

export default authSlice.reducer;