import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Notification {
    _id: string;
    title: string;
    message: string;
    viewed: boolean;
    organization: string;
    createdAt: string;
    updatedAt: string;
  }

  interface NotificationState {
    notifications: Notification[];
  }

  const initialState: NotificationState = {
    notifications: [],
  };

  const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
      SET_NOTIFICATION: (state, action: PayloadAction<Notification[]>) => {
        state.notifications = action.payload;
      },
      MARK_NOTIFICATION_AS_VIEWED: (state, action: PayloadAction<string>) => {
        const notification = state.notifications.find(
          (n) => n._id === action.payload
        );
        if (notification) {
          notification.viewed = true;
        }
      },
    },
  });

  export const {SET_NOTIFICATION, MARK_NOTIFICATION_AS_VIEWED} = notificationSlice.actions
  export default notificationSlice.reducer