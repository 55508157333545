import React, { useEffect, useRef, useState } from "react";
import { changePassword } from "../../services/ProfileService";
import { useDispatch, useSelector } from "react-redux";
import { useModalContext } from "../../components/utilities/ModalContext";
import CreateSec from "./CreateSec";
import { TextField, Button, Select, MenuItem, FormHelperText } from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { LOGOUT, SET_LOGIN, setTokenWithExpiry } from "../../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import Upload from "./Upload";
import Eye from "../../icons/Eye.svg";
import Eye2 from "../../icons/eye-regular.svg";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import CircleLoader from "react-spinners/CircleLoader";
import { editUser, loginUser } from "../../services/authService";


enum Tabs {
  Company = "Company Details",
  Admin = "Admin",
}

const adminInitialState = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const receptionistInitialState = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const userInitialState = {
  id: "",
  firstName: '',
  lastName: '',
  phoneNumber: ''
};

const MyProfile = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Company);
  const [adminFormData, setAdminFormData] = useState(adminInitialState);
  const [receptionistFormData, setReceptionistFormData] = useState(
    receptionistInitialState
  );
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const [clearMsg, setClearMsg] = useState("");
  const { openModal } = useModalContext();
  const [adminViewPassword, setAdminViewPassword] = useState(false);
  const [adminConfirmViewPassword, setAdminConfirmViewPassword] =
    useState(false);
  const [adminViewOldPassword, setAdminViewOldPassword] = useState(false);

  const [receptionistViewPassword, setReceptionistViewPassword] =
    useState(false);
  const [receptionistConfirmViewPassword, setReceptionistConfirmViewPassword] =
    useState(false);
  const [receptionistViewOldPassword, setReceptionistViewOldPassword] =
    useState(false);
    const [formData, setFormData] = useState(userInitialState);
    const {  id, firstName, lastName, phoneNumber } = formData;

  // PASSWORD VISIBILITY
  const handleAdminShowPassword = () => {
    setAdminViewPassword((previousValue) => !previousValue);
  };
  const handleAdminConfirmShowPassword = () => {
    setAdminConfirmViewPassword((previousValue) => !previousValue);
  };

  const handleAdminOldShowPassword = () => {
    setAdminViewOldPassword((previousValue) => !previousValue);
  };

  const handleReceptionistShowPassword = () => {
    setReceptionistViewPassword((previousValue) => !previousValue);
  };
  const handleReceptionistConfirmShowPassword = () => {
    setReceptionistConfirmViewPassword((previousValue) => !previousValue);
  };

  const handleReceptionistOldShowPassword = () => {
    setReceptionistViewOldPassword((previousValue) => !previousValue);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleTabClick = (tab: Tabs) => {
    setActiveTab(tab);
  };

  const adminSignUp = useSelector(
    (state: any) => state.auth?.newOrganization?.admin?.role
  );

  
  const role = useSelector((state: any) => state.auth?.user?.role);
    
  const secretaryUser = role === "secretary";
  const adminUser = role === "admin";

  const {
    handleSubmit: handleAdminSubmit,
    control: adminControl,
    reset: adminReset,
    formState: adminFormState,
  } = useForm();

  const handleResetSubmit = handleAdminSubmit(async (adminFormData) => {
    setLoading(true);
    const userData = {
      oldPassword: adminFormData.oldPassword,
      newPassword: adminFormData.newPassword,
      confirmPassword: adminFormData.confirmPassword,
      id: userId,
    };
    try {
      const data = await changePassword(userData);
      if (data.status === true) {
        setLoading(false);
        adminReset();
        setAdminFormData(adminInitialState);
        navigate('/login')
      }
    } catch (error) {
      setLoading(false);
    }
  });

  const userId = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.user?._id;
    } else {
      return null;
    }
  });

  // Receptionist flow
  const ReceptionistUserserId = useSelector((state: any) => {
    if (state.getSecretary?.secList) {
      return state.getSecretary?.secList?.data?._id;
    } else {
      return null;
    }
  });

  const {
    handleSubmit: handleReceptionistSubmit,
    control: receptionistControl,
    reset: receptionistReset,
    formState: receptionistFormState,
  } = useForm();

  const handleReceptionistResetSubmit = handleReceptionistSubmit(
    async (receptionistFormData) => {
      setLoading(true);
      const userData = {
        oldPassword: receptionistFormData.oldPassword,
        newPassword: receptionistFormData.newPassword,
        confirmPassword: receptionistFormData.confirmPassword,
        id: ReceptionistUserserId,
      };
      try {
        const data = await changePassword(userData);
        setLoading(false);
        receptionistReset();
        setReceptionistFormData(receptionistInitialState);
      } catch (error) {
        setLoading(false);
      }
    }
  );

  const token = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  const tokenFromStorage = localStorage.getItem("token") || null;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const expiry = 47 * 60 * 60 * 1000 + 50 * 60 * 1000;

  useEffect(() => {
    let tokenToUse;

    // Check if the user has signed up (using adminSignUp)
    if (adminSignUp === true) {
      // User has signed up
      tokenToUse = tokenFromStorage;
    } else {
      // User has logged in
      tokenToUse = token;
    }
    dispatch<any>(
      setTokenWithExpiry(
       tokenToUse,
        expiry,
        handleLogoutAndNavigate
      )
    );
  }, [dispatch, token, expiry]);

  const handleLogoutAndNavigate = () => {
    dispatch(LOGOUT());
    navigate("/login");
  };
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const details = useSelector((state: any) => state.auth?.user);

  const editUserDetails = handleSubmit(async (formData) => {
    setSelectedItemId(userId);
  
    if (selectedItemId) {
      setLoading(true);
      try {
        let tokenToUse;

        // Check if the user has signed up (using adminSignUp)
        if (adminUser) {
          tokenToUse = token;
        } else {
          // Assume the user has logged in
          tokenToUse = tokenFromStorage;
        }
  
        const userData = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phoneNumber: formData.phoneNumber,
          id: selectedItemId,
        };
  
        const data = await editUser(userData, tokenToUse);
        
        // Update local state with edited user details
        setFormData({
          ...formData,
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          phoneNumber: userData.phoneNumber,
        });
  
        await dispatch(SET_LOGIN(data));
        setLoading(false);
      
      } catch (error) {
        setLoading(false);
        setFormData(userInitialState);
      }
    }
  });
  
  return (
    <div className="adminDash profilePage ml-[46px] mt-[42px] mr-[49px] pt-[80px]">
      <div className="flex justify-between ml-[24px] create-dept-level profile-receptionist-header pr-8">
        <h2 className="text-[#44566c] text-[32px] font-bold tracking-wide">
          My Profile
        </h2>
      </div>
      <div className="w-[23%] mx-auto flex bg-white border shadow-md rounded cursor-pointer">
        <div
          className={`tab flex items-center justify-center  border-r text-[14px]  ${
            activeTab === Tabs.Company
              ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
              : "px-[30px] h-[35px]  w-[135px] hover:bg-[#F8FAFB] text-[#515151] "
          }`}
          onClick={() => handleTabClick(Tabs.Company)}
        >
          Company&nbsp;Details
        </div>

        <div
          className={`tab flex items-center justify-center  border-r ${
            activeTab === Tabs.Admin
              ? "active bg-[#0081ff] pl-0 h-[35px] w-[135px] text-white rounded text-center hover:opacity-80"
              : "px-[30px] h-[35px]  w-[100px] hover:bg-[#F8FAFB] "
          }`}
          onClick={() => handleTabClick(Tabs.Admin)}
        >
          Admin
        </div>
      </div>
      {activeTab === Tabs.Company && <Upload />}

      {activeTab === Tabs.Admin && (
        <div>
          <div className="profile-form-container shadow-md border-0 mt-[37px] w-full bg-white rounded-[20px] py-[22px] px-[24px] text-[#44566C] mb-[169px]">
            <div className="pt-[16px]">
              <h2 className="text-[#44566C] text-[15px] font-semibold leading-6">
              Admin Details
              </h2>
              <form className="px-[44px] pt-[46px] pb-[29px] profile-form">
                <div className="flex justify-between">
                <div className="w-[45%] update-old-password-container">
                  <label className="block text-[#8697A8] font-normal text-[15px]">
                  First Name
                  </label>
                  <div>
                  <Controller
                name="firstName"
                control={control}
                defaultValue={details?.firstName || ""}
                rules={{
                  required: "First name is required",
                  minLength: {
                    value: 3,
                    message: "Name must be at least 3 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="First Name"
                    className="no-outline"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
                  </div>
                </div>

                <div className="w-[45%] update-old-password-container">
                  <label className="block text-[#8697A8] font-normal text-[15px]">
                  Last Name
                  </label>
                  <div>
                  <Controller
                name="lastName"
                control={control}
                defaultValue={details?.lastName || ""}
                rules={{
                  required: "Last Nmae is required",
                  minLength: {
                    value: 3,
                    message: "Name must be at least 3 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Last Name"
                    className="no-outline"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
                  </div>
                </div>
                </div>
                <div className="flex justify-between mt-[42px] update-password-container">
      
                  <div className="w-[45%]">
                  <label className="block mb-2 text-[#8697A8]">Phone Number</label>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue={details?.phoneNumber || ''}
                
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Phone Number"
                    className="no-outline"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
                  </div>
                </div>

                <div className="mt-8 flex justify-center items-center">
                  <Button
                    className="profile-page-btn mx-auto"
                    onClick={editUserDetails}
                  >
                    {loading ? (
                      <CircleLoader
                        color="#ffffff"
                        loading={loading}
                        size={20}
                      />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>

          {/* UPDATE PASSWORD */}
          <div className="profile-form-container shadow-md border-0 mt-[10px] w-full bg-white rounded-[20px] py-[22px] px-[24px] text-[#44566C] ">
            <div className="pt-[16px]">
              <h2 className="text-[#44566C] text-[15px] font-semibold leading-6">
                Change Password
              </h2>
              <form className="px-[44px] pt-[46px] pb-[29px] profile-form">
                <div className="w-[45%] update-old-password-container">
                  <label className="block text-[#8697A8] font-normal text-[15px]">
                    Old Password
                  </label>
                  <div>
                    <Controller
                      name="oldPassword"
                      control={adminControl}
                      defaultValue=""
                      rules={{
                        required: "Old Password is required",
                        minLength: {
                          value: 6,
                          message: "Password must be at least 6 characters",
                        },
                        maxLength: {
                          value: 13,
                          message:
                            "Password must not be more than 13 characters",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div className="w-full mt-[12px]">
                          <FormControl variant="outlined" fullWidth>
                            <OutlinedInput
                              {...field}
                              id="outlined-adornment-password"
                              className="no-outline"
                              type={adminViewOldPassword ? "text" : "password"}
                              sx={{
                                border: "none",
                                "& fieldset": { border: "none" },
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleAdminOldShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {adminViewOldPassword ? (
                                      <img
                                        src={Eye2}
                                        alt="password"
                                        width={15}
                                      />
                                    ) : (
                                      <img src={Eye} alt="password" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              placeholder="Old Password"
                            />
                          </FormControl>
                          {fieldState.error && (
                            <p className="text-[#FF0101] text-xs mt-[3px]">
                              {fieldState.error.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="flex justify-between mt-[42px] update-password-container">
                  <div className="w-[45%]">
                    <label className="block text-[#8697A8] font-normal text-[15px]">
                      New Password
                    </label>
                    <div className="mt-[12px]">
                      <Controller
                        name="newPassword"
                        control={adminControl}
                        defaultValue=""
                        rules={{
                          required: "New Password is required",
                          minLength: {
                            value: 6,
                            message: "Password must be at least 6 characters",
                          },
                          maxLength: {
                            value: 13,
                            message:
                              "Password must not be more than 13 characters",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <div className="w-full">
                            <FormControl variant="outlined" fullWidth>
                              <OutlinedInput
                                {...field}
                                id="outlined-adornment-password"
                                className="no-outline"
                                type={adminViewPassword ? "text" : "password"}
                                sx={{
                                  border: "none",
                                  "& fieldset": { border: "none" },
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleAdminShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {adminViewPassword ? (
                                        <img
                                          src={Eye2}
                                          alt="password"
                                          width={15}
                                        />
                                      ) : (
                                        <img src={Eye} alt="password" />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                placeholder="New Password"
                              />
                            </FormControl>
                            {fieldState.error && (
                              <p className="text-[#FF0101] text-xs mt-[3px]">
                                {fieldState.error.message}
                              </p>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="w-[45%]">
                    <label className="block text-[#8697A8] font-normal text-[15px]">
                      Confirm New Password
                    </label>
                    <div className="mt-[12px]">
                      <Controller
                        name="confirmPassword"
                        control={adminControl}
                        defaultValue=""
                        rules={{
                          required: "Confirm New Password is required",
                          minLength: {
                            value: 6,
                            message: "Password must be at least 6 characters",
                          },
                          maxLength: {
                            value: 13,
                            message:
                              "Password must not be more than 13 characters",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <div className="w-full">
                            <FormControl variant="outlined" fullWidth>
                              <OutlinedInput
                                {...field}
                                id="outlined-adornment-password"
                                className="no-outline"
                                type={
                                  adminConfirmViewPassword ? "text" : "password"
                                }
                                sx={{
                                  border: "none",
                                  "& fieldset": { border: "none" },
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleAdminConfirmShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {adminConfirmViewPassword ? (
                                        <img
                                          src={Eye2}
                                          alt="password"
                                          width={15}
                                        />
                                      ) : (
                                        <img src={Eye} alt="password" />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                placeholder="New Password"
                              />
                            </FormControl>
                            {fieldState.error && (
                              <p className="text-[#FF0101] text-xs mt-[3px]">
                                {fieldState.error.message}
                              </p>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-8 flex justify-center items-center">
                  <Button
                    className="profile-page-btn mx-auto"
                    onClick={handleResetSubmit}
                  >
                    {loading ? (
                      <CircleLoader
                        color="#ffffff"
                        loading={loading}
                        size={20}
                      />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfile;
