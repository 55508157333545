import axios from "axios";
import { toast } from "react-toastify";

export const Backend_URL = process.env.REACT_APP_AUTH_URL;

export const emailValidation = (email: any) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const registerUser = async (userData: any) => {
  try {
    const response = await axios.post(`${Backend_URL}/auth/signup`, userData);
    if (response.status >= 200 && response.status < 300) {
      toast.success("Successful...");
    }

    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    } else {
      toast.error(message); 
      throw new Error(message); 
    }
  }
};

export const verifyEmail = async (userData: any) => {
  try {
    const response = await axios.post(
      `${Backend_URL}/auth/verifyEmail`,
      userData
    );
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error(message); 
        throw new Error(message); 
      }
  }
};

export const loginUser = async (userData: any, token: any) => {
  try {
    const response = await axios.post(`${Backend_URL}/auth/login`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (response.statusText === "OK") {
      toast.success("Login Successful");
    }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    } else {
      toast.error(message); 
        throw new Error(message); 
    }
  }
};

export const editUser = async (userData: any, token: string) =>{
  try {
    const response = await axios.put(
      `${Backend_URL}/user/${userData.id}`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      }
    );
  
    if (response.status >= 200 && response.status < 300) {
      toast.success("User edited successfully");
      return response.data; // Return the data on success
    } else {
    
    }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
      if (error?.response?.data?.error) {
        // toast.error(error?.response?.data?.error);
      } else {
        // toast.error("Failed to Update");
      }
      throw error;
  }
};

export const forgotPassword = async (userData: any) => {
  try {
    const response = await axios.post(
      `${Backend_URL}/user/forgot-password`,
      userData
    );
    if (response.statusText === "OK") {
      toast.success("An email sent...");
    }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
      toast.error(message); 
      throw new Error(message); 
  }
};

export const resetPassword = async (userData: any) => {
  try {
    const response = await axios.post(
      `${Backend_URL}/user/reset-password/${userData.code}`,
      userData
    );
    if (response.statusText === "OK") {
      toast.success("Reset successful...");
    }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
     if (error.response && error.response.status === 401) {
      toast.error(message); 
      throw new Error(message); 
     } else {
      toast.error(message); 
      throw new Error(message); 
     }
    
  }
};

export const verifyCode = async (userData: any, token: any) => {
  try {
    const response = await axios.put(`${Backend_URL}/auth/verify`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.statusText === "OK") {
      toast.success("Verification successful...");
    }

    return true; 
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
        toast.error("Incorrect OTP code, please enter the correct code");
    if (error.response && error.response.status === 400) {
      return false;
    }
    return false;
  }
};

export const verificationCode1 = async (userData: any) => {
  try {
    const response = await axios.put(`${Backend_URL}/auth/verify`, userData);

    if (response.statusText === "OK") {
      toast.success("Verified...");
    }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error("Incorrect code, please enter the correct code");
  }
};

export const createDept = async (userData: any, token: string) => {
  try {
    const response = await axios.post(`${Backend_URL}/department`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    if (error?.response?.data?.error) {
      toast.error(error?.response?.data?.error);
    } else {
      toast.error(message); 
        throw new Error(message); 
    }
    throw error;
  }
};

export const fetchDept = async (token: string) => {
  try {
    const response = await axios.get(`${Backend_URL}/department`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    // if (response.statusText === "OK") {
    //   toast.success("Successful...");
    // }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    // toast.error("Failed. Try again");
    throw error;
  }
};

export const fetchDeptBYId = async (id: string) => {
  try {
    const response = await fetch(`${Backend_URL}/department/${id}`);

    if (response.ok) {
      const data = await response.json();
      // toast.success("Successful...");
      return data;
    } else {
      // Handle error response
      const errorData = await response.json();
      const message = errorData.message ;
      // toast.error(message);
      throw new Error(message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    // toast.error("Failed. Try again");
    throw new Error(message);
  }
};


export const deleteDepartments = async (token: any, userData: any) => {
  try {
    const response = await axios.delete(
      `${Backend_URL}/department/${userData?.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      toast.success("Department deleted successfully");
      return response.data; // Return the data on success
    } else {
      // If the response status is not in the 2xx range, throw an error
      // throw new Error("Failed to delete department");
    }
  } catch (error: any) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    toast.error(message); // Display the error message using toast
    throw new Error(message); // Rethrow the error to be caught by the caller
  }
};

export const editDepartment = async (userData: any, token: string) =>{
  try {
    const response = await axios.put(
      `${Backend_URL}/department/${userData.id}`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      }
    );
  
    if (response.status >= 200 && response.status < 300) {
      toast.success("Department edited successfully");
      return response.data; // Return the data on success
    } else {
    
    }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error);
      } else {
        toast.error(message); 
        throw new Error(message); 
      }
      throw error;
  }
};

export const levelCreation = async (userData: any, token: string) => {
  try {
    const response = await axios.post(`${Backend_URL}/level`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    if (error?.response?.data?.error) {
      toast.error(error?.response?.data?.error);
    } else {
      toast.error(message); 
      throw new Error(message); 
    }
    throw error;
  }
};

export const fetchLevels = async (token: string) => {
  try {
    const response = await axios.get(`${Backend_URL}/level`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    // if (response.statusText === "OK") {
    //   toast.success("Successful...");
    // }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    // toast.error("Failed. Try again");
    throw error;
  }
};

export const fetchLevelBYId = async (orgById: string) => {
  try {
    const response = await axios.get(`${Backend_URL}/level/${orgById}`);

    // if (response.statusText === "OK") {
    //   toast.success("Successful...");
    // }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    // toast.error("Failed. Try again");
    throw error;
  }
};

export const deleteLevels = async (token: any, userData: any) => {
  try {
    const response = await axios.delete(
      `${Backend_URL}/level/${userData?.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      toast.success("Level deleted successfully");
      return response.data; // Return the data on success
    } else {
      // If the response status is not in the 2xx range, throw an error
      // throw new Error("Failed to delete department");
    }
  } catch (error: any) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    toast.error(message); // Display the error message using toast
    throw new Error(message); // Rethrow the error to be caught by the caller
  }
};

export const editLevel = async (userData: any, token: string) =>{
  try {
    const response = await axios.put(
      `${Backend_URL}/level/${userData.id}`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      }
    );
  
    if (response.status >= 200 && response.status < 300) {
      toast.success("Department edited successfully");
      return response.data; // Return the data on success
    } else {
    
    }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error);
      } else {
        toast.error(message); 
        throw new Error(message); 
      }
      throw error;
  }
};

export const identifyOrganization = async (id: any) => {
  try {
    const response = await axios.get(
      `${Backend_URL}/organization/createId/${id}`);

    // if (response.statusText === "OK") {
    //   toast.success("Successful...");
    // }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    // toast.error('Failed. Try again');
    throw error;
  }
};

export const resendVerificationCode = async (userData: any) => {
  try {
    const response = await axios.post(`${Backend_URL}/auth/code`, userData, {
    });
    if (response.statusText === "OK") {
      toast.success("Code Sent Successfully");
    }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error);
    } else {
      toast.error(message); 
      throw new Error(message); 
    }
  }
};
