import axios from "axios";
import { toast } from "react-toastify";

export const emailValidation = (email: any) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  
export const Backend_URL = process.env.REACT_APP_AUTH_URL;

export const secretaryCreation = async (userData: any, token: any) => { 
  try {
    const response = await axios.post(`${Backend_URL}/user/secretary`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": 'application/json'
      }
    });

    if (response.status >= 200 && response.status < 300) {
      toast.success("Receptionist created successfully");
    }

    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    if (error?.response?.data?.error) {
      toast.error(error?.response?.data?.error);
    } else {
      toast.error(message); 
        throw new Error(message); 
    }
    // toast.error('Receptionist already exists'); 
  }
};



  export const getSecretaries = async (token: any) => { 
    try {
      const response = await axios.get(`${Backend_URL}/user/secretaries`,
       {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      }
       );
      //  if (response.statusText === "OK") {
      //   toast.success("successful...");
      // }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      // toast.error('Failed. Try again');
    }
  };

  export const editReceptionist = async (userData: any, token: string,) =>{
    try {
      const response = await axios.put(
        `${Backend_URL}/user/${userData._id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        }
      );
    
      if (response.statusText === "OK") {
        toast.success("Receptionist Edited Successfully");
      }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        toast.error(message); 
        throw new Error(message); 
      
    }
  };

  export const deleteReceptionist = async (token: any, userData: any) => {
    try {
      const response = await axios.delete(`${Backend_URL}/user/${userData.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: userData, 
      });
      if (response.status >= 200 && response.status < 300) {
        toast.success("Receptionist deleted successfully");
      }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        toast.error(message); 
        throw new Error(message); 
    }
  };