import React, { useEffect, useState } from "react";
import "./App.css";
import SignIn from "./views/auth/SignIn";
import SignUp from "./views/auth/SignUp";
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  Router,
} from "react-router-dom";
import AdminDashboard from "./views/dashboard/AdminDashboard";
import ForgotPassword from "./views/auth/ForgotPassword";
import ResetPassword from "./views/auth/ResetPassword";
import Verify from "./views/auth/Verify";
import Verification from "./views/auth/Verification";
import Layout from "./components/Layout";
import Visitors from "./views/Visit/Visitors";
import Staff from "./views/staff/Staff";
import MyProfile from "./views/profile/MyProfile";
import Broadcast from "./views/Broadcast";
import SecDashboard from "./views/dashboard/SecDashboard";
import { useSelector, useDispatch } from "react-redux";
import { SET_TOKEN } from "./redux/features/authSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateUser from "./views/staff/CreateUser";
import Visit from "./views/Visit/Visit";
import NoPage from "./views/Visit/NoPage";
import Receptionist from "./views/receptionist/Receptionist";
import ChangePassword from "./views/receptionist/ChangePassword";
import AuthGuard from "./constant/PrivateRoute";
import PublicRoute from "./constant/PublicRoutes";
import ReceptionistAllStaff from "./views/staff/ReceptionistAllStaff";

function App() {
  const dispatch = useDispatch();
  const adminUser = useSelector((state: any) => state.auth?.user?.role);

  const adminSignUp = useSelector(
    (state: any) => state.auth?.newOrganization?.admin?.role
  );

  const secretaryUser = useSelector((state: any) => state.auth?.user?.role);

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      dispatch(SET_TOKEN(storedToken));
    }
  }, []);

  const tokens = useSelector((state: any) => state.auth?.token);

  const token = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state?.auth?.token;
    } else {
      return null;
    }
  });

  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer className="toast-message-container" />
        <Routes>
          <Route
            path="/"
            element={
              <AuthGuard
                element={
                  <Layout>
                    <AdminDashboard />
                  </Layout>
                }
              />
            }
          />

          <Route
            path="/secdashboard"
            element={
              <AuthGuard
                element={
                  <Layout>
                    <SecDashboard />
                  </Layout>
                }
                isSecretary={secretaryUser}
              />
            }
          />

          <Route path="/login" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/verification" element={<Verification />} />

          <Route
            path="/visitors"
            element={
              <AuthGuard
                element={
                  <Layout>
                    <Visitors />
                  </Layout>
                }
              />
            }
          />

          <Route
            path="/staff"
            element={
              <AuthGuard
                element={
                  <Layout>
                    <Staff />
                  </Layout>
                }
              />
            }
          />

          {/* <Route
            path="/receptionist/staff"
            element={
              <AuthGuard
                element={
                  <Layout>
                    <ReceptionistAllStaff />
                  </Layout>
                }
               
              />
            }
          /> */}

          <Route
            path="/profile"
            element={
              <AuthGuard
                element={
                  <Layout>
                    <MyProfile />
                  </Layout>
                }
              />
            }
          />

          <Route
            path="/receptionist"
            element={
              <AuthGuard
                element={
                  <Layout>
                    <Receptionist />
                  </Layout>
                }
              />
            }
          />
          <Route
            path="/broadcast"
            element={
              <AuthGuard
                element={
                  <Layout>
                    <Broadcast />
                  </Layout>
                }
              />
            }
          />

          <Route
            path="/receptionist/changepassword"
            element={
              <AuthGuard
                element={
                  <Layout>
                    <ChangePassword />
                  </Layout>
                }
              />
            }
          />
          <Route path="/staff/signup" element={<CreateUser />} />
          <Route path="/visit" element={<Visit />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
