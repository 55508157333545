import axios from "axios";
import { toast } from "react-toastify";
import { TableData } from "../types/TableData";

export const Backend_URL = process.env.REACT_APP_AUTH_URL;

export const emailValidation = (email: any) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  export const addNewStaff = async (userData: any, token: any) => {
    try {
      const response = await axios.post(
        `${Backend_URL}/staff`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        }
      );
  
      if (response.statusText === "OK") {
        toast.success("Staff added successfully");
      }
  
      return response.data;
    } catch (error: any) {
      const errorMessage =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
  
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message.join(', ')); 
      } else {
        toast.error(errorMessage);
        throw new Error(errorMessage);
      }
    }
  };
  

  export const fetchAllStaff = async ( id:string) => {
    try {
      const response = await axios.get(
        `${Backend_URL}/staff/org/${id}`
      );
     
      return response.data;
    }  catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      
      throw error;
    }
  };

  export const fetchWorkers = async (token: any, search: string) => {
    try {
      const response = await axios.get(
        `${Backend_URL}/staff`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          },
          params: {
            search: search
          }
        }
      );
      return response.data;
    }  catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      throw error;
    }
  };
  

  export const fetchStaff = async (token: string): Promise<TableData[]> => {
    try {
      const response = await axios.get<TableData[]>(
        `${Backend_URL}/staff`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        }
      );
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
       
        throw new Error(message); 
    
    }
  };

  export const createLink = async (token: any) => {
    try {
      const response = await axios.post(
        `${Backend_URL}/staff/link`,{},
        {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": 'application/json'
            }
          }
      )
      if (response.status >= 200 && response.status < 300) {
        toast.success('Link created successfully');
      }
      return response.data;
    } catch (error: any) {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    toast.error(message); // Display the error message using toast
    throw new Error(message); // Rethrow the error to be caught by the caller
    }
  };

  export const getLink = async (token: any) => { 
    try {
      const response = await axios.get(`${Backend_URL}/staff/link`,
       {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      }
       ); 
      //  if (response.statusText === "OK") {
      //   toast.success("successful...");
      // }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        // toast.error(message); 
        // throw new Error(message); 
    }
  };

  export const deleteLink = async (token: any) => { 
    try {
      const response = await axios.delete(`${Backend_URL}/staff/link`,
       {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      }
       );
       if (response.status >= 200 && response.status < 300) {
        toast.success(" Link deleted successfully");
      }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
     if (error?.response?.data?.error) {
       toast.error(error?.response?.data?.error);
     } else {
      toast.error(message); 
      throw new Error(message); 
     }
    }
  };

  export const createUser = async (userData: any, token: any) => {
    try {
      const response = await axios.post(
        `${Backend_URL}/staff/${userData.id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        toast.success("Staff added successfully");
      }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error);
      } else {
        toast.error(message); 
        throw new Error(message); 
      }
    }
  };

  export const verifyLink = async (org: any) => {
    try {
      const response = await axios.get(`${Backend_URL}/staff/link/verify`, {
        params: {
          org: org,
        },
      });
  
      return response.data;
    } catch (error: any) {
      const status = error.response?.data?.status;
  
      if (status === false) {
        // Handle false status (404 or other error)
        // navigate("/nopage");
      } else {
        // Handle other errors
       
      }
    }
  };

  export const staffStatus = async (userData: any, token: string,) =>{
    try {
      const response = await axios.put(
        `${Backend_URL}/staff/${userData.id}/status?status=${userData.status}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        }
      );
    
      if (response.statusText === "OK") {
        toast.success("Success");
      }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        toast.error(message); 
        throw new Error(message); 
        throw error;
    }
  };
  
  export const editStaff = async (userData: any, token: string,) =>{
    try {
      const response = await axios.put(
        `${Backend_URL}/staff/${userData._id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        }
      );
    
      if (response.statusText === "OK") {
        toast.success("Staff Edited Successfully");
      }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        } else {
          toast.error(message); 
        throw new Error(message); 
        }
        throw error;
    }
  };
  
   