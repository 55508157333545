import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Add from "../../icons/mingcute_add-fill.svg";
import Search from "../../icons/search.svg";
import { useModalContext } from "../../components/utilities/ModalContext";
import ReactPaginate from "react-paginate";
import Filter from "../../icons/Filter.svg";
import { ArrowDown, DotsIcon, ExcelIcon, PersonIcon } from "../../icons/icon";
import Modal from "react-modal";
import {
  editStaff,
  staffStatus,
  fetchWorkers,
} from "../../services/staffService";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import { CloseIcon } from "../../icons/icon";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Grid } from "@mui/material";
import CircleLoader from "react-spinners/CircleLoader";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { fetchDept, fetchLevels } from "../../services/authService";

const initialState = {
  status: "",
  id: "",
};

const ActiveTable = () => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const {
    editStaffModalOpen,
    modalStaffClose,
    modalStaffOpen,
    modallOpen,
    suspendModalOpen,
    modalSuspendOpen,
    modalSuspendClose,
    archiveModalOpen,
    modalArchiveClose,
    modalArchiveOpen,
  } = useModalContext();

  const [currentPage, setCurrentPage] = useState(0); // Start from 0 for zero-based indexing
  const itemsPerPage = 10;
  const [formData, setFormData] = useState(initialState);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedItemPopUpId, setSelectedItemPopUpId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [showLevel, setShowLevel] = useState([]);
  const [showDept, setShowDept] = useState([]);
  const [dropdownVisibility2, setDropdownVisibility2] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(
    (selectedUser as any)?.phoneNumber || ""
  );

  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const popUpRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(popUpRef, () => {
    setSelectedItemPopUpId("");
  });

  const fetchAllStaff = async () => {
    try {
      let tokenToUse;
      if (adminUser || secretaryUser) {
        tokenToUse = tokens;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const data = await fetchWorkers(tokenToUse, '');
      setStaffData(data?.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllStaff();
  }, []);

  const fetchAllLevels = async () => {
    try {
      let tokenToUse;
      if (adminUser || secretaryUser) {
        tokenToUse = tokens;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const allLevels = await fetchLevels(tokenToUse);
      setShowLevel(allLevels?.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllLevels();
  }, []);

  const fetchAllDept = async () => {
    let tokenToUse;
    if (adminUser || secretaryUser) {
      tokenToUse = tokens;
    } else {
      tokenToUse = tokenFromStorage;
    }
    try {
      const allDept = await fetchDept(tokenToUse);
    setShowDept(allDept?.data)
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllDept();
  }, []);

  const levels = useSelector((state: any) => state.levels?.levels?.data);
  const levelIds = levels;

  const departments = useSelector(
    (state: any) => state.departments?.departments?.data
  );
  const deptIds = departments;

  useEffect(() => {
    setPhoneNumber((selectedUser as any)?.phoneNumber || "");
  }, [selectedUser]);

  // Search Filter
  const handleSearchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    try {
      let tokenToUse;
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const allStaff = await fetchWorkers(tokenToUse, value); // Pass the search value to the backend
      setStaffData(allStaff?.data);
      setSearchValue(value);
      setCurrentPage(0);
    } catch (err) {
    
    }
  };

  const staffIdToNameMap: Record<string, string> = {};
  const staffIdToNameMaps: Record<string, string> = {};

  showLevel.forEach((level: any) => {
    staffIdToNameMap[level._id] = `${level.levelName}`;
  });

  showDept.forEach((currDept: any) => {
    staffIdToNameMaps[currDept._id] = `${currDept?.departmentName}`;
  });

  const filteredStaff = staffData?.filter((staff: any) => {
    const filterNameAndEmail =
      staff?.firstName?.toLowerCase().includes(searchValue.toLowerCase()) ||
      staff?.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
      (staff?.level &&
        staffIdToNameMap[staff.level]
          ?.toLowerCase()
          .includes(searchValue.toLowerCase())) ||
      (staff.currDept &&
        staffIdToNameMaps[staff.currDept]
          ?.toLowerCase()
          .includes(searchValue.toLowerCase()));
    return filterNameAndEmail;
  });

  const activeStaff = filteredStaff?.filter(
    (staff: any) => staff.status === "active"
  );

  //Pagination
  const pageCount = Math.ceil(activeStaff?.length / itemsPerPage);

  const handlePageChange = (selectedPage: any) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = filteredStaff?.slice(offset, offset + itemsPerPage);

  const role = useSelector((state: any) => state.auth?.user?.role);
  const secretaryUser = role === "secretary";
  const adminUser = role === "admin";

  const toggleDropDownVisibility2 = () => {
    setDropdownVisibility2(!dropdownVisibility2);
  };

  const tokens = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  const tokenFromStorage = localStorage.getItem("token") || null;

  const updateStatus = async (id: string, token: string) => {
    const userData = {
      status: selectedStatus,
      id: selectedItemId,
    };
    setLoading(true);
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const data = await staffStatus(userData, tokenToUse);
      setLoading(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setFormData(initialState);
      setSelectedStatus("");
      window.location.reload();
    } catch (error) {
      setLoading(false);
      setSelectedStatus("");
      setFormData(initialState);
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisibility2(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const editStaffDetails = handleSubmit(async (dataForm) => {
    const formattedPhoneNumber = `${phoneNumber}`;
    const userData = {
      _id: selectedItemId,
      firstName: dataForm.firstName,
      lastName: dataForm.lastName,
      email: dataForm.email,
      phoneNumber: formattedPhoneNumber,
      levelId: selectedLevel,
      departmentId: selectedDepartment,
    };
    setLoading(true);
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const data = await editStaff(userData, tokenToUse);
      setFormData(initialState);
      setSelectedItemId("");
      setLoading(false);
      fetchAllStaff();
      modalStaffClose();
      // window.location.reload();
    } catch (error) {
      setLoading(false);
      setSelectedItemId("");
      setFormData(initialState);
    }
  });


  useEffect(() => {
    const initialValues = {
      _id: (selectedUser as any)?._id,
      lastName: (selectedUser as any)?.lastName || "",
      firstName: (selectedUser as any)?.firstName || "",
      email: (selectedUser as any)?.email || "",
      phoneNumber: (selectedUser as any)?.phoneNumber || "",
      level: (selectedUser as any)?.level || "",
      department: (selectedUser as any)?.department || "",
    };
    reset(initialValues);
    setSelectedDepartment((selectedUser as any)?.department);
    setSelectedLevel((selectedUser as any)?.level);
    setSelectedItemId((selectedUser as any)?._id);
  }, [selectedUser]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let tokenToUse;

    // Check if the user has signed up (using adminSignUp)
    if (adminUser) {
      tokenToUse = tokens;
    } else {
      // Assume the user has logged in
      tokenToUse = tokenFromStorage;
    }
    updateStatus(selectedItemId, tokenToUse);
    setSelectedStatus("");
    setSelectedItemId("");
  };

  const sortedTableDataWithNames = currentPageData
    ? currentPageData.map((staff: any) => {
        const dept = deptIds.find((dept: any) => dept._id === staff.department);
        const departmentName = dept ? `${dept.departmentName} ` : "";

        const level = levelIds.find((level: any) => level._id === staff.level);
        const levelName = level ? `${level.levelName} ` : "";

        return {
          ...staff,
          departmentName,
          levelName,
        };
      })
    : [];

  const columnsToExport = [
    "firstName",
    "lastName",
    "email",
    "phoneNumber",
    "levelName",
    "departmentName",
    "gender",
    "oracleNumber",
  ];

  const filteredDataForExport = sortedTableDataWithNames.map((item: any) => {
    const filteredItem: Record<string, any> = {};
    columnsToExport.forEach((column) => {
      filteredItem[column] = item[column];
    });
    return filteredItem;
  });

  function convertToCSV(data: any) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((staffData: any) =>
      Object.values(staffData).join(",")
    );
    return [header, ...rows].join("\n");
  }

  const handleDownload = () => {
    const csvData = convertToCSV(filteredDataForExport);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "activeStaff.csv";
    a.click();
    URL.revokeObjectURL(url);
    setDropdownVisibility2(false);
  };

  const handleClose = () => {
    modalStaffClose();
    reset();
    setSelectedLevel("");
    setSelectedDepartment("");
  };

  const renderEditModal = () => {
    return (
      <Modal
        isOpen={editStaffModalOpen}
        onRequestClose={handleClose}
        contentLabel="Add New Staff Modal"
        className="w-[40.3%] rounded-[12px] py-[30px] pb-[34px] bg-[white] add-staff-modal add-schedule-modal"
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className="w-[77.5%] mx-auto">
          <div className="flex justify-end mb-[34px]">
            <button onClick={handleClose} className="cursor-pointer">
              <CloseIcon />
            </button>
          </div>
          <h2 className="text-[#44566C] text-center font-bold text-[30px]">
            Edit Staff
          </h2>
          <form
            onSubmit={editStaffDetails}
            className="new-staff-form h-[470px]"
          >
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">First Name</label>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: "First name is required",
                  minLength: {
                    value: 3,
                    message: "Name must be at least 3 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="First Name"
                    defaultValue={(selectedUser as any)?.firstName || ""}
                    className="w-full  px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Last Name</label>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: "Last name is required",
                  minLength: {
                    value: 3,
                    message: "Last name must be at least 3 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Last Name"
                    defaultValue={(selectedUser as any)?.lastName || ""}
                    className="w-full px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px]  font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Email</label>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Enter a valid email. E.g, example@gmail.com",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Email"
                    defaultValue={(selectedUser as any)?.email || ""}
                    className="w-full px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <Grid item xs={12}>
                <label className="block mb-2 text-[#8697A8]">
                  Phone Number{" "}
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{
                      required: "Phone number is required",
                    }}
                    render={({ field, fieldState }) => (
                      <div>
                        <PhoneInput
                          id="phone-input"
                          placeholder="Phone Number"
                          value={phoneNumber}
                          onChange={(value) => setPhoneNumber(value)}
                          defaultCountry="NG"
                          international
                          withCountryCallingCode
                          className={`custom-phone-input ${
                            fieldState.error ? "error-border-phone-input" : ""
                          }`}
                        />
                        {fieldState.error && (
                          <FormHelperText error>
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                  />
                </label>
              </Grid>
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">oracle Number</label>
              <Controller
                name="oracleNumber"
                control={control}
                defaultValue={(selectedUser as any)?.oracleNumber || ""}
                rules={{
                  required: "oracleNumber is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Oracle Number"
                    className="w-full px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    disabled
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Level</label>
              <Controller
                name="level"
                control={control}
                rules={{
                  required: "Select a level",
                }}
                render={({ field, fieldState }) => (
                  <div>
                    <Select
                      {...field}
                      className="w-full bg-[#F8FAFB] px-[19px] py-3 capitalize rounded"
                      onChange={(e) => {
                        setSelectedLevel(e.target.value);
                        field.onChange(e);
                        // setDepartmentError("");
                      }}
                      value={String(selectedLevel)}
                      style={{ height: "42px" }}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      error={!!fieldState.error}
                    >
                      {showLevel?.map((levelIdss: any, _id: any) => (
                        <MenuItem
                          key={_id}
                          value={levelIdss?._id}
                          className="capitalize"
                        >
                          {levelIdss?.levelName}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.error && (
                      <FormHelperText error>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Department</label>
              <Controller
                name="department"
                control={control}
                rules={{
                  required: "Select a department",
                }}
                render={({ field, fieldState }) => (
                  <div>
                    <Select
                      {...field}
                      className="w-full bg-[#F8FAFB] px-[19px] py-3 capitalize rounded"
                      value={String(selectedDepartment)}
                      onChange={(e) => {
                        setSelectedDepartment(e.target.value);
                        field.onChange(e);
                        setDepartmentError("");
                      }}
                      style={{ height: "42px" }}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      error={!!(fieldState.error || departmentError)}
                    >
                      {showDept?.map((department: any, _id: any) => (
                        <MenuItem
                          key={_id}
                          value={department?._id}
                          className="capitalize"
                        >
                          {department?.departmentName}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.error && (
                      <FormHelperText error>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>
            <button
              type="submit"
              className="bg-[#0081ff] h-[44px] rounded-[5px] mt-[38px] text-center mx-auto cursor-pointer flex justify-center items-center w-[167px] text-[#FFF] text-[15px] font-medium hover:opacity-80"
            >
              {loading ? (
                <CircleLoader color="#ffffff" loading={loading} size={20} />
              ) : (
                "Update"
              )}
            </button>
          </form>
        </div>
      </Modal>
    );
  };

  const renderSuspendModal = () => {
    return (
      <Modal
        isOpen={suspendModalOpen}
        onRequestClose={() => {
          modalSuspendClose();
          // setFormData(initialState);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        contentLabel="Add New Staff Modal"
        className="w-[40%] bg-[white]  rounded-[12px] py-[30px] max-w-[880px]  create-dept-modal "
      >
        <div className="w-[85%] mx-auto ">
          <div className="w-20 mx-auto">
            <PersonIcon />
          </div>
          <h1 className="text-center text-[#44566C] text-[20px] font-semibold capitalize">
            Are you sure you want to Suspend <br />
            {`${(selectedUser as any)?.firstName} ${
              (selectedUser as any)?.lastName
            }`}
            ?
          </h1>
          <div className="flex gap-4 justify-center items-center mx-auto mt-6 flex-wrap">
            <button
              className="bg-[#F1F1F1] w-[200px] text-[#44566C] h-12 rounded-md hover:border-[2px] hover:border-[#C4C4C4] cursor-pointer"
              onClick={() => {
                modalSuspendClose();
                // setFormData(initialState);
              }}
            >
              Cancel
            </button>
            <form onSubmit={handleFormSubmit}>
              <button
                className="bg-[#FF0101] w-[232px] text-[#FCFCFD] h-12 rounded-md hover:opacity-80 cursor-pointer"
                type="submit"
              >
                Suspend
              </button>
            </form>
          </div>
        </div>
      </Modal>
    );
  };

  const renderArchiveModal = () => {
    return (
      <Modal
        isOpen={archiveModalOpen}
        onRequestClose={() => {
          modalArchiveClose();
          // setFormData(initialState);
        }}
        contentLabel="Add New Staff Modal"
        className="w-[40%] bg-[white] rounded-[12px] py-[30px] max-w-[880px]  create-dept-modal"
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className="w-[85%] mx-auto ">
          <div className="w-20 mx-auto">
            <PersonIcon />
          </div>
          <h1 className="text-center text-[#44566C] text-[20px] font-semibold capitalize">
            Are you sure you want to Archive <br />{" "}
            {`${(selectedUser as any)?.firstName} ${
              (selectedUser as any)?.lastName
            }`}
            ?
          </h1>
          <div className="flex gap-4 justify-center items-center mx-auto mt-6 flex-wrap">
            <button
              className="bg-[#F1F1F1] w-[200px] text-[#44566C] h-12 rounded-md hover:border-[2px] hover:border-[#C4C4C4]"
              onClick={() => {
                modalArchiveClose();
                // setFormData(initialState);
              }}
            >
              Cancel
            </button>
            <form onSubmit={handleFormSubmit}>
              <button
                className="bg-[#FF0101] w-[200px] text-[#FCFCFD] h-12 rounded-md hover:opacity-80 cursor-pointer"
                type="submit"
              >
                Archive
              </button>
            </form>
          </div>
        </div>
      </Modal>
    );
  };

  const renderDetails = (item: any) => {
    if (selectedItemPopUpId === item._id) {
      return (
        <div>
          <div className="staff-dots-modal shadow-xl" ref={popUpRef}>
            <ul className="h-[150px] w-[160px] z-50 pt-2">
              <li
                onClick={() => {
                  setSelectedItemId(item?._id);
                  modalStaffOpen();
                  setSelectedItemPopUpId("");
                  setSelectedUser(item);
                }}
                className="text-[#44566c] text-[14px] hover:bg-[#F8FAFB] cursor-pointer"
              >
                <button className="w-full text-left pl-5 pr-2 py-[10px]">
                  View and Edit
                </button>
              </li>
              <li
                className="text-[#44566c] text-[14px] hover:bg-[#F8FAFB]"
                onClick={() => {
                  setSelectedItemId(item?._id);
                  setSelectedStatus("suspended");
                  modalSuspendOpen();
                  setSelectedItemPopUpId("");
                  setSelectedUser(item);
                }}
              >
                <button className="w-full text-left pl-5 pr-2 py-[10px]">
                  Suspend
                </button>
              </li>
              <li
                onClick={() => {
                  setSelectedItemId(item?._id);
                  setSelectedStatus("archived");
                  modalArchiveOpen();
                  setSelectedItemPopUpId("");
                  setSelectedUser(item);
                }}
                className="text-[#fff] font-medium text-[14px] h-[40px] mt-3 rounded-[6px] bg-[#FF0101] hover:opacity-80 cursor-pointer mx-1"
              >
                <button className="w-full h-full text-left pl-4">
                  Archive
                </button>
              </li>
            </ul>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <div
        className="bg-white flex flex-wrap justify-between gap-[18px] border
      border-[#dedede] border-solid px-[21px] py-6 mt-[33px] visitors-search-bar
      mb-[33px]"
      >
        <div className="flex items-center px-4 py-2 border border-[#EAEAEA] border-solid flex-1 rounded-[3px] visitors-search-bar-inner">
          <img
            src={Search}
            alt="Search"
            className="w-[20px] h-[20px] mr-[9px]"
          />
          <input
            type="search"
            placeholder="Search"
            className="flex-1"
            name="searchValue"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </div>
        {!secretaryUser && (
          <button
            className="bg-[#0081FF] w-[183px] text-sm rounded-[4px] py-2 text-white flex items-center justify-center add-schedule-btn"
            onClick={modallOpen}
          >
            <img src={Add} alt="add-btn" />
            <p className="ml-2 text-sm font-normal">Add New Staff</p>
          </button>
        )}
        {!secretaryUser && (
          <div className="export-btn-container" ref={dropdownRef}>
            <div
              className="flex w-[96px] text-sm rounded-[4px] py-2 items-center justify-center boder-solid border-2 border-[#EAEAEA] text-[#666] font-medium export-btn cursor-pointer"
              onClick={() => {
                toggleDropDownVisibility2();
              }}
            >
              <button className="cursor-pointer">Export</button>
              <span className="cursor-pointer">
                <ArrowDown />
              </span>
            </div>
            {dropdownVisibility2 && (
              <div>
                <ul className="dropdown w-[96px] shadow-md text-center ">
                  <li
                    className="text-[#44566c] h-[35px] text-[12px] cursor-pointer flex items-center justify-center excel-container"
                    onClick={handleDownload}
                  >
                    <ExcelIcon />
                    <p className="ml-[10px] text-[#44566C] font-normal text-[15px]">
                      Excel
                    </p>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
        {secretaryUser && (
          <button className="bg-[#0081FF] w-[183px] text-sm rounded-[4px] py-2 text-white flex items-center justify-center add-schedule-btn">
            <img src={Filter} alt="filter" />
            <p className="ml-2 text-sm font-normal">Filter</p>
          </button>
        )}
      </div>
      <div className="visitors-table bg-white shadow-sm  pb-[27px] px-2 w-full">
        <div
          style={{
            maxWidth: "100%",
            overflowX: "auto",
          }}
          className="table-inner-container"
        >
          <table className="w-full table-content">
            <thead className="w-full text-[14px] h-[57px] bg-[#FAFAFA]">
              <tr>
                <th className="w-[170px] rounded-l-[10px] text-[#44566C] text-sm font-semibold text-left pl-[16px]">
                  Name
                </th>
                <th className="w-[170px] text-[#44566C] text-sm font-semibold text-left">
                  Email
                </th>
                <th className="w-[170px] text-[#44566C] text-sm font-semibold text-left">
                  Phone Number
                </th>
                {!secretaryUser && (
                  <th className="w-[170px] text-[#44566C] text-sm font-semibold text-left">
                    Oracle Number
                  </th>
                )}
                <th className="w-[170px] text-[#44566C] text-sm font-semibold text-left">
                  Level
                </th>
                <th className="w-[170px] text-[#44566C] text-sm font-semibold text-left">
                  Department
                </th>
                <th className="w-[30px] rounded-r-[10px]"></th>
              </tr>
            </thead>

            <tbody>
              {currentPageData?.length > 0 ? (
                currentPageData?.map((item: any) => {
                  if (item.status === "active") {
                    return (
                      <tr key={item._id}>
                        <td className="text-[#44566c] text-[14px] capitalize font-medium pt-[19px] flex items-center">
                          <p className="rounded-[7px] bg-[#F3F6FF] p-[10px] leading-none text-[#0081FF]  uppercase w-fit ml-4 font-bold">
                            {" "}
                            {`${
                              item.firstName ? item.firstName?.charAt(0) : ""
                            }${item.lastName ? item.lastName?.charAt(0) : ""}`}
                          </p>
                          <p className="ml-[12px]">
                            {`${item.firstName} ${item.lastName}`}
                          </p>
                        </td>
                        <td className="text-[#44566c] text-[14px] pt-[18px]">
                          {item.email}
                        </td>
                        <td className="text-[#44566c] text-[14px] pt-[18px]">
                          {item.phoneNumber}
                        </td>
                        <td className="text-[#44566c] text-[14px] pt-[18px]">
                          {item.oracleNumber}
                        </td>
                        <td className="text-[#44566c] text-[14px] pt-[18px] capitalize">
                          {levelIds.find(
                            (currLevel: any) => currLevel._id === item.level
                          )
                            ? levelIds.find(
                                (currLevel: any) => currLevel._id === item.level
                              )?.levelName
                            : "N/A"}
                        </td>
                        <td className="text-[#44566c] text-[14px] pt-[18px] capitalize">
                          {deptIds.find(
                            (currDept: any) => currDept._id === item.department
                          )
                            ? deptIds.find(
                                (currDept: any) =>
                                  currDept._id === item.department
                              )?.departmentName
                            : "N/A"}
                        </td>
                        {!secretaryUser && (
                          <td className="text-[#44566c] text-[14px] pt-[18px]">
                            <div
                              className="flex justify-center items-center h-[30px] cursor-pointer"
                              onClick={() => {
                                if (selectedItemId === item._id) {
                                  setSelectedItemId("");
                                  setSelectedItemPopUpId("");
                                } else {
                                  setSelectedItemId(item._id);
                                  setSelectedItemPopUpId(item._id);
                                }
                              }}
                            >
                              <button className="text-[18px]">
                                <DotsIcon />
                              </button>
                            </div>
                            {renderDetails(item)}
                          </td>
                        )}
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <tr>
                  <td colSpan={4}>No data found</td>
                </tr>
              )}
              {renderEditModal()}
              {renderSuspendModal()}
              {renderArchiveModal()}
            </tbody>
          </table>
        </div>
        <div className="flex pagination-comp">
          <ReactPaginate
            className="flex bg-white mx-auto h-[40px] text-sm text-[#44566c]
            rounded-[10px] pagination mt-[33px]"
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            previousLinkClassName={"previous-page"}
            nextLinkClassName={"next-page"}
            disabledClassName={"disabled-page"}
            activeClassName={"active-page"}
            breakLabel={"..."}
          />
        </div>
      </div>
    </div>
  );
};

export default ActiveTable;
