import imageFileResizer from 'react-image-file-resizer';

const imageResizeAndCompress = (file: File): Promise<File> => {
  return new Promise((resolve) => {

if (file.type === 'image/svg+xml') {
      resolve(file);
    } else {
      imageFileResizer.imageFileResizer(
        file,
        300, // new width in pixels
        200, // new height in pixels
        'jpeg', // or the format you prefer
        90, // compression quality (0 to 100)
        0, // rotation angle
        (resizedFile: any) => {
          resolve(resizedFile);
        },
        'file'
      );
    }
  });
};





//     imageFileResizer.imageFileResizer(
//       file,
//       300, // new width in pixels
//       200, // new height in pixels
//       'jpeg', // or the format you prefer
//       90, // compression quality (0 to 100)
//       0, // rotation angle
//       (resizedFile: any) => {
//         resolve(resizedFile);
//       },
//       'file'
//     );
//   });
// };

export { imageResizeAndCompress };
