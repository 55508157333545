import React, { createContext, useContext, useState, ReactNode } from "react";
import { SET_SHOW_NAVBAR } from "../../redux/features/showNavbarSlice";
import { useSelector, useDispatch } from "react-redux";

interface ModalContextType {
  isModalOpen: boolean;
  izModalOpen: boolean;
  izModalOpens: boolean;
  modalIsOpen: boolean;
  modalIzOpen: boolean;
  openDmodal: boolean;
  modalIsOpened: boolean;
  isModalOpening: boolean;
  modalDelOpen: boolean;
  modalLevOpen: boolean;
  suspendModalOpen: boolean;
  activeModalOpen: boolean;
  archiveModalOpen: boolean;
  notifyModalOpen: boolean;
  visitorsModalOpen: boolean;
  editStaffModalOpen: boolean;
  notificationModalOpen: boolean;
  editReceptionistModalOpen: boolean;
  passwordModalOpen: boolean;
  removeModalOpen: boolean;
  filterChartOpen: boolean;
  modalDelDeptOpen: boolean;
  modalDelLevelOpen: boolean;
  modalEditDeptOpen: boolean;
  modalEditLevelOpen: boolean;
  modalStaffDetails: boolean;

  openModal: () => void;
  openModall: () => void;
  openModalll: () => void;
  modalOpen: () => void;
  modallOpen: () => void;
  modalDOpen: () => void;
  modalWillOpen: () => void;
  modalIsOpening: () => void;
  modalDeleteOpen: (dept: string) => void;
  modalLevelOpen: () => void;
  modalSuspendOpen: () => void;
  modalActiveOpen: () => void;
  modalArchiveOpen: () => void;
  modalNotifyOpen: () => void;
  modalVisitorsOpen: () => void;
  modalStaffOpen: () => void;
  modalNotificationOpen: () => void;
  modalEditReceptionistOpen: () => void;
  modalPasswordOpen: () => void;
  modalRemoveOpen: () => void;
  modalFilterOpen: () => void;
  modalDeleteDeptOpen: (dept: string) => void;
  modalDeleteLevelOpen:(lev: string) => void;
  modalEditDepartmentOpen: () => void;
  modalEditLevelsOpen: () => void;
  modalOpenViewDetails: () => void;

  closeModal: () => void;
  closeModall: () => void;
  closeModalll: () => void;
  modalClose: () => void;
  modallClose: () => void;
  modalDClose: () => void;
  modalWillClose: () => void;
  modalIsClosing: () => void;
  modalDeleteClose: () => void;
  modalLevelClose: () => void;
  modalSuspendClose: () => void;
  modalActiveClose: () => void;
  modalArchiveClose: () => void;
  modalNotifyClose: () => void;
  modalVisitorsClose: () => void;
  modalStaffClose: () => void;
  modalNotificationClose: () => void;
  modalEditReceptionistClose: () => void;
  modalPasswordClose: () => void;
  modalRemoveClose: () => void;
  modalFilterClose: () => void;
  modalDeleteDeptClose: () => void;
  modalDeleteLevelClose: () => void;
  modalEditDepartmentClose: () => void;
  modalEditLevelsClose: () => void;
  modalCloseViewDetails: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);
export function useModalContext(): ModalContextType {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }
  return context;
}
interface ModalProviderProps {
  children: ReactNode;
}

export function ModalProvider({ children }: ModalProviderProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [izModalOpen, setIzModalOpen] = useState(false);
  const [izModalOpens, setIzModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIzOpen, setModalIzOpen] = useState(false);
  const [openDmodal, setOpenDmodal] = useState(false);
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const [isModalOpening, setIsModalOpening] = useState(false);
  const [modalDelOpen, setModalDeleteOpen] = useState(false);
  const [modalDeleteArgument, setModalDeleteArgument] = useState<string>("");
  const [modalLevOpen, setModalLevelOpen] = useState(false);
  const [suspendModalOpen, setModalSuspendOpen] = useState(false);
  const [activeModalOpen, setModalActiveOpen] = useState(false);
  const [archiveModalOpen, setModalArchiveOpen] = useState(false);
  const [notifyModalOpen, setModalNotifyOpen] = useState(false);
  const [visitorsModalOpen, setModalVisitorsOpen] = useState(false);
  const [editStaffModalOpen, setModalStaffOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [editReceptionistModalOpen, setModalEditReceptionistOpen] = useState(false);
  const [removeModalOpen, setModalRemoveOpen] = useState(false);
  const [passwordModalOpen, setModalPasswordOpen] = useState(false);
  const [filterChartOpen, setModalFilterOpen] = useState(false);
  const [modalDelDeptOpen, setModalDelDeptOpen] = useState(false);
  const [modalDelLevelOpen, setModalDelLevelOpen] = useState(false);
  const [modalEditLevelOpen,   setModalEditLevelOpen] = useState(false);
  const [modalEditDeptOpen, setModalEditDepartmentOpen] = useState(false);
  const [modalStaffDetails, setModalviewDetails] = useState(false);

  const dispatch = useDispatch();

  const openModal = () => {
    setIsModalOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const openModall = () => {
    setIzModalOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const openModalll = () => {
    setIzModal(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalOpen = () => {
    setModalIsOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modallOpen = () => {
    setModalIzOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalDOpen = () => {
    setOpenDmodal(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalWillOpen = () => {
    setModalIsOpened(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalIsOpening = () => {
    setIsModalOpening(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalDeleteOpen = (dept: string) => {
    setModalDeleteArgument(dept); // Set the argument
    setModalDeleteOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalLevelOpen = () => {
    setModalLevelOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalSuspendOpen = () => {
    setModalSuspendOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };
  const modalActiveOpen = () => {
    setModalActiveOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };
  const modalArchiveOpen = () => {
    setModalArchiveOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalNotifyOpen = () => {
    setModalNotifyOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };
  const modalVisitorsOpen = () => {
    setModalVisitorsOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalStaffOpen = () => {
    setModalStaffOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalNotificationOpen = () => {
    setNotificationModalOpen(true);
  };

  const modalEditReceptionistOpen = () => {
    setModalEditReceptionistOpen(true);
     dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalPasswordOpen = () => {
    setModalPasswordOpen(true);
     dispatch(SET_SHOW_NAVBAR(false));
  };
  
  const modalRemoveOpen = () => {
    setModalRemoveOpen(true);
     dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalFilterOpen = () => {
    setModalFilterOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };
  const modalDeleteDeptOpen = () => {
    setModalDelDeptOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalDeleteLevelOpen = () => {
    setModalDelLevelOpen(true);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalEditDepartmentOpen = () => {
    setModalEditDepartmentOpen(true);
     dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalEditLevelsOpen = () => {
    setModalEditLevelOpen (true);
     dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalOpenViewDetails = () => {
    setModalviewDetails (true);
     dispatch(SET_SHOW_NAVBAR(false));
  };

  //CLOSE MODALS
  const closeModalll = () => {
    setIzModal(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const closeModall = () => {
    setIzModalOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalClose = () => {
    setModalIsOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modallClose = () => {
    setModalIzOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalDClose = () => {
    setOpenDmodal(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalWillClose = () => {
    setModalIsOpened(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalIsClosing = () => {
    setIsModalOpening(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalDeleteClose = () => {
    setModalDeleteOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalLevelClose = () => {
    setModalLevelOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalSuspendClose = () => {
    setModalSuspendOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalActiveClose = () => {
    setModalActiveOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };
  const modalArchiveClose = () => {
    setModalArchiveOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalNotifyClose = () => {
    setModalNotifyOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalVisitorsClose = () => {
    setModalVisitorsOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalStaffClose = () => {
    setModalStaffOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalNotificationClose = () => {
    setNotificationModalOpen(false);
  };

  const modalEditReceptionistClose = () => {
    setModalEditReceptionistOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalPasswordClose = () => {
    setModalPasswordOpen(false);
   dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalRemoveClose = () => {
    setModalRemoveOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalFilterClose = () => {
    setModalFilterOpen(false);
    dispatch(SET_SHOW_NAVBAR(true));
  };

  const modalDeleteDeptClose = () => {
    setModalDelDeptOpen(false);
    dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalDeleteLevelClose = () => {
    setModalDelLevelOpen(false);
    dispatch(SET_SHOW_NAVBAR(false));
  };
  const modalEditDepartmentClose = () => {
    setModalEditDepartmentOpen(false);
     dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalEditLevelsClose = () => {
    setModalEditLevelOpen(false);
     dispatch(SET_SHOW_NAVBAR(false));
  };

  const modalCloseViewDetails = () => {
    setModalviewDetails (false);
     dispatch(SET_SHOW_NAVBAR(false));
  };
  const contextValue: ModalContextType = {
    isModalOpen,
    izModalOpen,
    izModalOpens,
    modalIsOpen,
    modalIzOpen,
    openDmodal,
    modalIsOpened,
    isModalOpening,
    modalDelOpen,
    modalLevOpen,
    suspendModalOpen,
    activeModalOpen,
    archiveModalOpen,
    notifyModalOpen,
    visitorsModalOpen,
    editStaffModalOpen,
    notificationModalOpen,
    editReceptionistModalOpen,
    removeModalOpen,
    passwordModalOpen,
    filterChartOpen,
    modalDelDeptOpen,
    modalDelLevelOpen,
    modalEditDeptOpen,
    modalEditLevelOpen,
    modalStaffDetails,

    closeModal,
    closeModall,
    closeModalll,
    modalClose,
    modallClose,
    modalWillClose,
    modalIsClosing,
    modalDeleteClose,
    modalLevelClose,
    modalDClose,
    modalSuspendClose,
    modalActiveClose,
    modalArchiveClose,
    modalNotifyClose,
    modalVisitorsClose,
    modalStaffClose,
    modalNotificationClose,
    modalEditReceptionistClose,
    modalPasswordClose,
    modalRemoveClose,
    modalFilterClose,
    modalDeleteDeptClose,
    modalDeleteLevelClose,
    modalEditDepartmentClose,
    modalEditLevelsClose,
    modalOpenViewDetails,

    openModal,
    openModall,
    openModalll,
    modalOpen,
    modallOpen,
    modalDOpen,
    modalWillOpen,
    modalIsOpening,
    modalDeleteOpen,
    modalLevelOpen,
    modalSuspendOpen,
    modalActiveOpen,
    modalArchiveOpen,
    modalNotifyOpen,
    modalVisitorsOpen,
    modalStaffOpen,
    modalNotificationOpen,
    modalEditReceptionistOpen,
    modalPasswordOpen,
    modalRemoveOpen,
    modalFilterOpen,
    modalDeleteLevelOpen,
    modalDeleteDeptOpen,
    modalEditDepartmentOpen,
    modalEditLevelsOpen,
    modalCloseViewDetails
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
}
