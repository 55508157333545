import React, { useEffect, useState } from "react";
import { useModalContext } from "../../components/utilities/ModalContext";
import Modal from "react-modal";
import Add from "../../icons/mingcute_add-fill.svg";
import {
  getSecretaries,
  secretaryCreation,
} from "../../services/secretaryService";
import { useSelector, useDispatch } from "react-redux";
import { SET_USER } from "../../redux/features/authSlice";
import { SET_NEW_SECRETARY } from "../../redux/features/SecretarySlice";
import { SET_SECRETARY } from "../../redux/features/getSecretarySlice";
import { CloseIcon } from "../../icons/icon";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  TextField,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import Eye from "../../icons/Eye.svg";
import Eye2 from "../../icons/eye-regular.svg";
import CircleLoader from "react-spinners/CircleLoader";
import PhoneInput from "react-phone-number-input";

Modal.setAppElement("#root");

const initialRecState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  password: "",
};

const CreateSec = () => {
  const { izModalOpen, openModall, closeModall } = useModalContext();
  const [formData, setFormData] = useState(initialRecState);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [viewPassword, setViewPassword] = useState("");
  const token = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  const tokenFromStorage = localStorage.getItem('token') || null;
  
  const role = useSelector((state: any) => state.auth?.user?.role);
  const adminUser = role === "admin";

  
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const handleCreateRecForm = handleSubmit(async (formData) => {
    setLoading(true);
    try {
      const phoneNumber = parsePhoneNumberFromString(
        `${formData.phoneNumber}`,
        'NG' // Assuming Nigeria, you can change the country code accordingly
      );
      const userData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: phoneNumber?.formatInternational() || '',
        email: formData.email,
        password: formData.password,
      };
      let tokenToUse;
        if (adminUser) {
          tokenToUse = token;
        } else {
          tokenToUse = tokenFromStorage;
        }
      const data = await secretaryCreation(userData, tokenToUse);
      if (data.status === true) {
        await dispatch(SET_USER(data?.data?.user));
        await dispatch(SET_NEW_SECRETARY(data));
        fetchSec();  
        window.location.reload();
        reset();
        closeModall();
      }
       setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  });

  const fetchSec = async () => {
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const sec = await getSecretaries(tokenToUse);
      setData(sec);
    } catch (err) {}
  };
  useEffect(() => {
    fetchSec();
  }, []);

  const handleClose = () => {
    closeModall();
    reset();
  };

    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.preventDefault();
    };

  const handleShowPassword = () => {
    setViewPassword((previousValue): any => !previousValue);
  };

  return (
    <div>
      <button
        className="bg-[#0081ff] px-4  h-[40px] rounded text-white shadow-md hover:opacity-80 flex items-center justify-center add-schedule-btn"
        onClick={openModall}
      >
        <img src={Add} alt="add-btn" />
        <p className="ml-2 text-sm font-normal">Add New Receptionist</p>
      </button>
      <Modal
        isOpen={izModalOpen}
        onRequestClose={handleClose}
        contentLabel="Add New Staff Modal"
        className="w-[42%] rounded-[12px] py-[45px] bg-[white]"
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className="mx-auto z-50 w-[79.5%] justify-center justify-items-center">
          <div className="flex justify-end mb-[30px]">
            <div onClick={handleClose} className="cursor-pointer">
              <CloseIcon />
            </div>
          </div>
          <h1 className="text-center text-[#44566c] text-3xl  font-bold">
            Add New Receptionist
          </h1>
          <form
            onSubmit={handleCreateRecForm}
            className="new-staff-form h-[470px]"
          >
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">First Name</label>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                rules={{
                  required: "First name is required",
                  minLength: {
                    value: 3,
                    message: "Name must be at least 3 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="First Name"
                    className="w-full  px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Last Name</label>
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                rules={{
                  required: "Last name is required",
                  minLength: {
                    value: 3,
                    message: "Last name must be at least 3 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Last Name"
                    className="w-full px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px]  font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Email</label>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Enter a valid email. E.g, example@gmail.com",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Email"
                    className="w-full px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px]  font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Phone Number</label>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                rules={{
                  required: "Phone number is required",
                }}
                render={({ field, fieldState }) => (
                  <div>
                    <PhoneInput
                      id="phone-input"
                      placeholder="Phone Number"
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      defaultCountry="NG"
                      international
                      withCountryCallingCode
                      className={`custom-phone-input ${
                        fieldState.error ? "error-border-phone-input" : ""
                      }`}
                    />
                    {fieldState.error && (
                      <FormHelperText error>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="mt-[38px]  font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Password</label>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                  maxLength: {
                    value: 13,
                    message: " Password must not be more than 13 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="w-full">
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        {...field}
                        id="outlined-adornment-password"
                        type={viewPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {viewPassword ? (
                                <img src={Eye2} alt="password" width={15} />
                              ) : (
                                <img src={Eye} alt="password" width={15} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="Password"
                      />
                    </FormControl>
                    {fieldState.error && (
                      <p className="text-[#FF0101] text-xs mt-[3px]">
                        {fieldState.error.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>

            <button className="bg-[#0081ff] h-[44px] rounded-[5px] mt-[38px] text-center mx-auto cursor-pointer flex justify-center items-center w-[167px] text-[#FFF] text-[15px] font-medium hover:opacity-80">
              {loading ? (
                <CircleLoader color="#ffffff" loading={loading} size={20} />
              ) : (
                "Create"
              )}
            </button>
          </form>
          {/* <div className="h-[70px] mt-5 flex items-center justify-center">
            {secs ? (
              <div className=" bg-white shadow-md rounded w-[60%] mx-auto h-8 text-center">
                {secs}
              </div>
            ) : null}
          </div> */}
        </div>
      </Modal>
    </div>
  );
};

export default CreateSec;
