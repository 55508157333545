import { combineReducers } from "redux";
import authReducer from "../redux/features/authSlice";
import departmentReducer from "../redux/features/deptSlice";
import addDepartmentReducer from "../redux/features/addDeptSlice";
import levelReducer from "../redux/features/levelSlice";
import addStaffReducer from "../redux/features/addStaffSlice";
import allStaffReducer from "../redux/features/allStaffSlice";
import addScheduleReducer from "../redux/features/addNewScheduleSlice";
import allScheduleSlice from "../redux/features/allSchedules1DaySlice";
import createSecretaryReducer from "../redux/features/SecretarySlice";
import getSecretaryReducer from "../redux/features/getSecretarySlice";
import linkReducer from "../redux/features/LinkSlice";
import notificationReducer from "../redux/features/notificationSlice";
import filterReducer from "../redux/features/dateFilterSlice";
import showNavbarReducer from "../redux/features/showNavbarSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  departments: departmentReducer,
  levels: levelReducer,
  addDepartments: addDepartmentReducer,
  addStaff: addStaffReducer,
  allStaff: allStaffReducer,
  addSchedule: addScheduleReducer,
  allSchedule: allScheduleSlice,
  createSecretary: createSecretaryReducer,
  getSecretary: getSecretaryReducer,
  getLink: linkReducer,
  getNotifications: notificationReducer,
  getDateFilter: filterReducer,
  navbarDisplay: showNavbarReducer,
});

export default rootReducer;
