import React from "react";

function NoPage() {
  return (
    <div>
      <h1 className="text-center mt-[15%] text-[#44566c] text-[40px] font-bold ">
        404 <br />
        Invalid URL
        <br /> (PAGE NOT FOUND)
      </h1>
      <div className="w-20 mx-auto mt-3">
      <button
        className="h-[42px]   flex justify-center items-center bg-[#0081ff] rounded-[5px] text-center text-white text-[15px] font-medium cursor-pointer w-20"
      >
        <a href="/login">
        Home
        </a>
      </button>
      </div>
    </div>
  );
}

export default NoPage;
