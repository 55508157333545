import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModalContext } from "../../components/utilities/ModalContext";
import Modal from "react-modal";
import {
  visitFilter,
  visitFilters,
  visitorStatus,
} from "../../services/VisitorService";
import Search from "../../icons/search.svg";
import Add from "../../icons/mingcute_add-fill.svg";
import ReactPaginate from "react-paginate";
import { CloseIcon, DotsIcon, ExcelIcon, ArrowDown } from "../../icons/icon";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import { isAfter, isToday, isBefore } from "date-fns";
import Filter from "../../icons/Filter.svg";
import { SET_VISITORS } from "../../redux/features/dateFilterSlice";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import AddSchedule from "./AddSchedule";
import { fetchNotifications, readNotification } from "../../services/notificationService";
import { MARK_NOTIFICATION_AS_VIEWED, SET_NOTIFICATION } from "../../redux/features/notificationSlice";
const initialState = {
  status: "",
  id: "",
};
interface AbsentVisitors {
  _id: string;
  staff: string;
  visitorName: string;
  visitorEmail: string;
  reasonForVisiting: string;
  timeOfVisiting: string;
  status: string;
  organization: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface AbsentData {
  status: boolean;
  message: string;
  data: {
    data: AbsentVisitors[];
    page: string; // Add page property
    limit: string; // Add limit property
    totalPages: number; // Add totalPages property
  };
}

const VAbsentTable = ({ verifyStatus }: any) => {
  const [loading, setLoading] = useState(false);
  const {
    visitorsModalOpen,
    modalVisitorsOpen,
    modalVisitorsClose,
    openModal,
    modalDOpen,
    modalDClose,
    openDmodal,
  } = useModalContext();
  const [formData, setFormData] = useState(initialState);
  const { status, id } = formData;
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedItemPopUpId, setSelectedItemPopUpId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredAbsentData, setFilteredAbsentData] =
    useState<AbsentData | null>(null);
  const [selectedUser, setSelectedUser] = useState({});
  const statusOptions = [
    "All",
    "pending",
    "active",
    "complete",
    "absent",
    "cancelled",
  ];
  const [openDetailsItemId, setOpenDetailsItemId] = useState("");

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const popUpRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(popUpRef, () => {
    setSelectedItemPopUpId("");
  });

  const { notifyModalOpen, modalNotifyClose, modalNotifyOpen } =
    useModalContext();
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const dispatch = useDispatch();

  const tokens = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  const tokenFromStorage = localStorage.getItem("token") || null;
  const adminSignUp = useSelector(
    (state: any) => state.auth?.newOrganization?.admin?.role
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisibility2(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const allStaffData = useSelector(
    (state: any) => state.allStaff?.staffList?.data
  );

  const handleSearchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    try {
      let tokenToUse;
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const allVisitors = await visitFilters(tokenToUse, value); // Pass the search value to the backend
      setFilteredAbsentData(allVisitors);
      setSearchValue(value);
    } catch (err) {
      console.error("Error searching visitors:", err);
    }
  };


  const updateStatus = async (id: string, token: string) => {
    const userData = {
      status: selectedStatus, // Use selectedStatus
      id: selectedItemId,
    };

    setLoading(true);
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const data = await visitorStatus(
        userData,
        tokenToUse,
      );
      setLoading(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setFormData(initialState);
      setSelectedStatus(""); // Reset selected status
      fetchByDate();
      modalDClose();
    } catch (error) {
      setLoading(false);
      setSelectedStatus(""); // Reset selected status
      setFormData(initialState);
      window.location.reload();
    }
  };

  const fetchByDate = async () => {
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const allVisitors = await visitFilter(tokenToUse);
      await dispatch(SET_VISITORS(allVisitors));
      setFilteredAbsentData(allVisitors);
    } catch (err) {}
  };

  useEffect(() => {
    let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminSignUp !== true) {
        // User has signed up
        tokenToUse = tokenFromStorage || ""; // Use empty string if null
      } else {
        // User has logged in
        tokenToUse = tokens;
      }
    handlePageChange(tokenToUse);
  }, []);

  const handleFilterClose = () => {
    modalNotifyClose();
    setValue("startDate", null);
    setValue("endDate", null);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
    e.preventDefault();
    updateStatus(selectedItemId, tokenToUse);
  };

  const staffIdToNameMap: Record<string, string> = {}; // Define the type of staffIdToNameMap

  allStaffData?.forEach((staff: any) => {
    staffIdToNameMap[staff._id] = `${staff.firstName} ${staff.lastName}`;
  });

  const totalPages = filteredAbsentData?.data?.totalPages || 0;
  const pageCount = Math.max(totalPages, 1);

  // Extract the page, limit, and data properties
  const {
    page,
    limit,
    data: responseData,
  } = filteredAbsentData?.data || { page: 0, limit: 0, data: [] };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const deepCopyOfData = JSON.parse(JSON.stringify(responseData));

  const filterByDateAndName = deepCopyOfData?.filter((visitor: any) => {
    const visitorNameMatch =
      visitor?.visitorName?.toLowerCase().includes(searchValue.toLowerCase()) ||
      visitor?.visitorEmail
        ?.toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      (visitor.staff &&
        staffIdToNameMap[visitor.staff]
          ?.toLowerCase()
          .includes(searchValue.toLowerCase()));
    return visitorNameMatch;
  });

  //Date Format
  function formatDateToBackend(inputDate: any) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const handleFilterSubmit = async (
    e: React.FormEvent | undefined,
    status: string,
    page: number,
    limit: number
  ) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    try {
      // Convert the startDate and endDate to the desired format
      const formattedStartDate = formatDateToBackend(startDate);
      const formattedEndDate = formatDateToBackend(endDate);
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const res = await visitFilter(
        tokenToUse,
        formattedStartDate,
        formattedEndDate,
        status,
        limit,
        page
      );
      setFilteredAbsentData(res);
      handleFilterClose();
      setStartDate("");
      setEndDate("");
    } catch (error) {}
  };

  const handlePageChange = async (selectedPage: { selected: number }) => {
    try {
      // Fetch data for the selected page
      const updatedStatus = "absent";
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const res = await visitFilters(
        tokenToUse,
        searchValue,
        status,
        selectedPage.selected + 1,
        itemsPerPage, // Pass the defined 'limit'
      );

      // Update your component's state or data source with the new data
      setFilteredAbsentData(res);

      // Update the current page
      setCurrentPage(selectedPage.selected);
    } catch (error) {}
  };

  const sortedTableDataWithNames = filterByDateAndName
    ? filterByDateAndName.map((visitor: any) => {
        const staff = allStaffData?.find(
          (staff: any) => staff._id === visitor.staff
        );
        const staffName = staff ? `${staff.firstName} ${staff.lastName}` : "";
        return {
          ...visitor,
          staffName,
        };
      })
    : [];

    const columnsToExport = ["visitorName", "visitorEmail", "reasonForVisiting", "timeOfVisiting", "status", "staffName", "visitorPhone", "visitorGender"];

  const filteredDataForExport = sortedTableDataWithNames.map((item: any) => {
    const filteredItem: Record<string, any> = {};
    columnsToExport?.forEach((column) => {
      filteredItem[column] = item[column];
    });
    return filteredItem;
  });

  function convertToCSV(data: any) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((visitorsData: any) =>
      Object.values(visitorsData).join(",")
    );
    return [header, ...rows].join("\n");
  }

  const handleDownload = () => {
    const csvData = convertToCSV(filteredDataForExport);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "absentVisitors.csv";
    a.click();
    URL.revokeObjectURL(url);
    setDropdownVisibility2(false);
  };

  const role = useSelector((state: any) => state.auth?.user?.role);
  const rolee = useSelector(
    (state: any) => state.auth?.newOrganization?.admin?.role
  );

  const adminUser = role ? role : rolee === "admin";
  const secretaryUser = role === "secretary";
  const [dropdownVisibility2, setDropdownVisibility2] = useState(false);

  const toggleDropDownVisibility2 = () => {
    setDropdownVisibility2(!dropdownVisibility2);
  };

  const fetchNotification = async () => {
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const allNotifications = await fetchNotifications(tokenToUse, false);
      await dispatch(SET_NOTIFICATION(allNotifications));
      await dispatch(
        MARK_NOTIFICATION_AS_VIEWED(allNotifications?.data?.viewed)
      );
      await readNotification(allNotifications?.data?.viewed, tokenToUse);
    } catch (err) {}
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  const viewDetails = () => {
    return (
      <Modal
        isOpen={visitorsModalOpen}
        onRequestClose={() => {
          modalVisitorsClose();
        }}
        contentLabel="Add New Staff Modal"
        className="w-[35%] bg-[white]  rounded-[12px] py-[30px] max-w-[800px]"
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className="w-[95%] mx-auto">
          <div className="flex justify-end mb-[10px]">
            <div
              onClick={() => {
                modalVisitorsClose();
                // setFormData(initialState);
              }}
              className="cursor-pointer"
            >
              <CloseIcon />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex">
              <p className="rounded-[7px] bg-[#F3F6FF] p-[10px] leading-none text-[#0081FF]  uppercase w-fit ml-4 font-bold">
                {" "}
                {`${
                  (selectedUser as any)?.visitorName
                    ? (selectedUser as any)?.visitorName
                        .split(" ")[0]
                        ?.charAt(0)
                    : ""
                }${
                  (selectedUser as any)?.visitorName &&
                  (selectedUser as any)?.visitorName.split(" ").length > 1
                    ? (selectedUser as any)?.visitorName
                        .split(" ")[1]
                        ?.charAt(0)
                    : ""
                }`}
              </p>
              <p
                className="ml-[12px] text-[#44566C] font-semibold"
                style={{ fontFamily: "Inter" }}
              >
                {(selectedUser as any).visitorName} <br />
                <span className="text-[14px] font-medium">
                  {(selectedUser as any).visitorEmail}
                </span>
              </p>
            </div>
          </div>
          <div className="w-[71%] mx-auto">
            <p className="mt-8 text-[#44566C] font-bold text-[14px]">
              Staff: &nbsp;
              {`${
                allStaffData?.find(
                  (staff: any) => staff._id === (selectedUser as any)?.staff
                )?.firstName
              } ${
                allStaffData?.find(
                  (staff: any) => staff._id === (selectedUser as any)?.staff
                )?.lastName
              }`}
            </p>
            <p className="mt-3 text-[#44566C] font-bold text-[14px]">
             Phone Number: &nbsp;
              {(selectedUser as any)?.visitorPhone}
            </p>
            <p className="mt-3 text-[#44566C] font-bold text-[14px]">
              Reason For Visit: &nbsp;
              {(selectedUser as any)?.reasonForVisiting}
            </p>

            <p className="mt-3 text-[#44566C] font-bold text-[14px]">
              Status: &nbsp;
              {(selectedUser as any)?.status}
            </p>

            
            {(selectedUser as any)?.timeOfVisiting && (
               <p className="mt-3 text-[#44566C] font-bold text-[14px]">
               Date: &nbsp;
               {new Date((selectedUser as any).timeOfVisiting).toLocaleDateString("en-US", {
                 timeZone: "Africa/Lagos",
               })}
             </p>
            )}
            

            {(selectedUser as any)?.timeOfVisiting && (
              <p className="mt-3 text-[#44566C] font-bold text-[14px]">
                Scheduled Time: &nbsp;
                {new Date(
                  (selectedUser as any).timeOfVisiting
                ).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </p>
            )}

          </div>
          <div className="w-full flex justify-center">
            <button
              className="w-[302px] mx-auto h-[56px] bg-[#0081FF] text-center text-white text-[16px] font-semibold mt-5 rounded cursor-pointer hover:opacity-80"
              onClick={() => {
                modalVisitorsClose();
                // setFormData(initialState);
              }}
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderDetails = (item: any) => {
    return (
      <div>
        {selectedItemPopUpId === item._id && (
          <div className="details-container" ref={popUpRef}>
            <ul>
              <li
                onClick={() => {
                  setSelectedItemId(item?._id);
                  // toggleDropDownVisibility(item?._id);
                  modalVisitorsOpen();
                  setSelectedItemPopUpId("");
                  setSelectedUser(item);
                }}
                className="h-14 w-[152px] shadow-md text-center bg-white rounded-[6px] text-[1E202C)] font-[16px] flex justify-center items-center cursor-pointer hover:bg-[#F8FAFB]"
              >
                View Details
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="bg-white flex flex-wrap justify-between gap-[18px] border border-[#dedede] border-solid px-[21px] py-6 mt-[33px] visitors-search-bar mb-[33px]">
        <div className="flex items-center px-4 py-2 border border-[#EAEAEA] border-solid flex-1 rounded-[3px] visitors-search-bar-inner">
          <img
            src={Search}
            alt="Search"
            className="w-[20px] h-[20px] mr-[9px]"
          />
          <input
            type="search"
            placeholder="Search"
            className="flex-1"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </div>
        <button
          className="bg-[#0081FF] w-[183px] text-sm rounded-[4px] py-2 text-white flex items-center justify-center add-schedule-btn"
          onClick={openModal}
        >
          <img src={Add} alt="add-btn" />
          <p className="ml-2 text-sm font-normal">Add Schedule</p>
        </button>
        <AddSchedule />

        <button
          className="bg-[#0081FF] w-[114px] text-sm rounded-[4px] py-2 text-white flex items-center justify-center add-schedule-btn hover:opacity-80"
          onClick={modalNotifyOpen}
        >
          <img src={Filter} alt="filter" />
          <p className="ml-2 text-sm font-normal">Filter</p>
        </button>
        {!secretaryUser && (
          <div className="export-btn-container" ref={dropdownRef}>
            <div
              className="flex w-[96px] text-sm rounded-[4px] py-2 items-center justify-center boder-solid border-2 border-[#EAEAEA] text-[#666] font-medium export-btn cursor-pointer"
              onClick={() => {
                toggleDropDownVisibility2();
              }}
            >
              <button className="cursor-pointer">Export</button>
              <span className="cursor-pointer">
                <ArrowDown />
              </span>
            </div>
            {dropdownVisibility2 && (
              <div>
                <ul className="dropdown w-[96px] shadow-md text-center ">
                  <li
                    className="text-[#44566c] h-[35px] text-[12px] cursor-pointer flex items-center justify-center excel-container"
                    onClick={handleDownload}
                  >
                    <ExcelIcon />
                    <p className="ml-[10px] text-[#44566C] font-normal text-[15px]">
                      Excel
                    </p>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        isOpen={notifyModalOpen}
        onRequestClose={handleFilterClose}
        contentLabel="Add New Staff Modal"
        className="w-[42%] rounded-[12px] py-[45px] bg-[white] filter-date-modal max-w-[800px]"
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className="w-[83%] mx-auto">
          <div className="flex justify-end mb-[10px]">
            <div onClick={handleFilterClose} className="cursor-pointer">
              <CloseIcon />
            </div>
          </div>
          <form
            onSubmit={(e) =>
              handleFilterSubmit(e, "absent", itemsPerPage, currentPage)
            }
          >
            <h2 className="text-[#44566C] text-[30px] font-bold mx-auto text-center">
              Filter
            </h2>
            <div className="mt-[27px] font-normal text-[15px]">
              <label className="block text-[#777] text-[16px]">
                Start Date
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DemoItem>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{
                        required: "Start Date is required",
                        validate: {
                          validTime: (value) => {
                            const selectedDate = new Date(value);
                            const currentDate = new Date();
                            if (
                              !isAfter(selectedDate, currentDate) &&
                              !isToday(selectedDate)
                            ) {
                              return "Please select a current or future date.";
                            }
                            return true;
                          },
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div>
                          <DatePicker
                            {...field}
                            value={startDate || ""}
                            onChange={(newValue) => {
                              setStartDate(newValue || "");
                            }}
                            className="picker rounded"
                            slotProps={{ textField: { size: "small" } }}
                          />
                          {fieldState.error && (
                            <p className="text-[#D32F2F] text-xs mt-[3px]">
                              {fieldState.error.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="mt-[23px] font-normal text-[15px]">
              <label className="block text-[#777] text-[16px]">End Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DemoItem>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{
                        required: "End Date is required",
                        validate: {
                          validTime: (value) => {
                            const selectedDate = new Date(value);
                            const currentDate = new Date();

                            if (
                              !isAfter(selectedDate, currentDate) &&
                              !isToday(selectedDate)
                            ) {
                              return "Please select a current or future date.";
                            }
                            return true;
                          },
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div>
                          <DatePicker
                            {...field}
                            value={endDate || ""}
                            onChange={(newValue) => {
                              setEndDate(newValue || ""); // Ensure newValue is a string
                            }}
                            className="picker rounded"
                            slotProps={{ textField: { size: "small" } }}
                          />
                          {fieldState.error && (
                            <p className="text-[#D32F2F] text-xs mt-[3px]">
                              {fieldState.error.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <button
              type="submit"
              className="w-full rounded-[5px] bg-[#0081FF] h-[53px] mt-[27px] text-[19px] font-medium text-[#fff]"
              style={{
                boxShadow:
                  "0px 10.23094px 20.46187px 0px rgba(0, 129, 255, 0.20)",
              }}
            >
              Filter
            </button>
          </form>
        </div>
      </Modal>
      <div className="visitors-table bg-white shadow-sm  pb-[27px] px-2 w-full">
        <div
          style={{
            maxWidth: "100%",
            overflowX: "auto",
          }}
          className="table-inner-container"
        >
          <table className="w-full table-content">
            <thead className="text-[14px] h-[57px] bg-[#FAFAFA]">
              <tr>
                <th className="w-[170px] rounded-l-[10px] text-[#44566C] text-sm font-semibold text-left pl-[16px]">
                  Name
                </th>
                <th className="w-[100px] text-[#44566C] text-sm font-semibold text-left">
                  Date
                </th>
                <th className="w-[100px] text-[#44566C] text-sm font-semibold text-left">
                  Time
                </th>
                <th className="w-[140px] text-[#44566C] text-sm font-semibold text-left">
                  Phone Number
                </th>
                <th className="w-[120px] text-[#44566C] text-sm font-semibold text-left">
                  Staff
                </th>
                <th className="w-[140px] text-[#44566C] text-sm font-semibold text-left">
                  Appointment
                </th>
                <th className="w-[120px] text-[#44566C] text-sm font-semibold text-left">
                  Action
                </th>
                <th className="w-[30px] rounded-r-[10px]  text-[#44566C] text-sm font-semibold text-left"></th>
              </tr>
            </thead>
            <tbody>
              {filterByDateAndName?.length &&
              filterByDateAndName?.length > 0 ? (
                filterByDateAndName?.map((item: any) => {
                  if (item.status === "absent") {
                    return (
                      <tr key={item._id}>
                        <td className="text-[#44566c] text-[14px] capitalize font-medium pt-[19px] flex items-center">
                          <p className="rounded-[7px] bg-[#F3F6FF] p-[10px] leading-none text-[#0081FF]  uppercase w-fit ml-4 font-bold">
                            {" "}
                            {`${
                              item?.visitorName
                                ? item?.visitorName.split(" ")[0]?.charAt(0)
                                : ""
                            }${
                              item?.visitorName &&
                              item?.visitorName.split(" ").length > 1
                                ? item?.visitorName.split(" ")[1]?.charAt(0)
                                : ""
                            }`}
                          </p>
                          <p
                            className="ml-[12px]"
                            style={{ fontFamily: "Inter" }}
                          >
                            {item.visitorName}
                          </p>
                        </td>
                        <td className="text-[#44566c] text-[14px] pt-[18px]">
                          {new Date(item?.timeOfVisiting).toLocaleDateString(
                            "en-US",
                            {
                              timeZone: "Africa/Lagos",
                            }
                          )}
                        </td>
                        <td className="text-[#44566c] text-[14px] pt-[18px]">
                          {new Date(item?.timeOfVisiting).toLocaleTimeString(
                            "en-US",
                            {
                              timeZone: "Africa/Lagos",
                            }
                          )}
                        </td>
                        <td className="text-[#44566c] text-[14px] pt-[18px]">
                        {item.visitorPhone}
                        </td>
                        <td className="text-[#44566c] text-[14px] pt-[18px]">
                          {`${
                            allStaffData?.find(
                              (staff: any) => staff._id === item.staff
                            )?.firstName
                          } ${
                            allStaffData?.find(
                              (staff: any) => staff._id === item.staff
                            )?.lastName
                          }`}
                        </td>
                        <td className="text-[#44566c] text-[14px] pt-[18px]  capitalize">
                          <p className="bg-[#F3F6FF] rounded-md px-[30px] py-[7px] text-[#FF0101] w-fit">
                            {item.status}
                          </p>
                        </td>
                        {secretaryUser && (
                          <td className="pt-[18px]">
                            <button
                              onClick={() => {
                                setSelectedItemId(item?._id);
                                modalDOpen();
                              }}
                              className="bg-[#0081ff] text-white text-sm rounded px-[30px] py-[7px] w-fit"
                            >
                              Update
                            </button>
                          </td>
                        )}
                        <Modal
                          isOpen={openDmodal}
                          onRequestClose={() => {
                            modalDClose();
                            setFormData(initialState);
                          }}
                          contentLabel="Add New Staff Modal"
                          className="w-[35%] bg-[white]  rounded-[12px] py-[30px]"
                          style={{
                            overlay: {
                              backgroundColor: "rgba(42, 42,42, 0.9)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            },
                          }}
                        >
                          <div className="mx-auto w-[79.5%] justify-center justify-items-center">
                            <div className="flex justify-end mb-[30px]">
                              <div
                                onClick={() => {
                                  modalDClose();
                                  setFormData(initialState);
                                }}
                                className="cursor-pointer"
                              >
                                <CloseIcon />
                              </div>
                            </div>
                            <h1 className="text-center text-[#44566c] text-3xl  font-bold">
                              Update{" "}
                              <span className="text-[#0081ff]">Status</span>
                            </h1>
                            <form onSubmit={handleFormSubmit}>
                              <div className="flex mt-[51px] ">
                                <Select
                                  value={selectedStatus}
                                  onChange={(e) =>
                                    setSelectedStatus(e.target.value)
                                  }
                                  className="text-md w-full bg-[#F8FAFB] mt-3 px-[19px] py-3 rounded capitalize"
                                  placeholder="Department"
                                  style={{ height: "47px" }}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  required
                                >
                                  <MenuItem disabled value="">
                                    Select a status
                                  </MenuItem>
                                  {statusOptions.map((option, _id: any) => (
                                    <MenuItem
                                      key={option}
                                      value={option}
                                      className="capitalize"
                                    >
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </div>

                              <button
                                className=" text-white h-[47px] w-[100%] bg-[#0081ff] text-center mx-auto border border-[#C4C4C4]-700 rounded mt-5 mb-5 flex justify-center items-center cursor-pointer"
                                type="submit"
                              >
                                Update
                              </button>
                            </form>
                          </div>
                        </Modal>
                        <td className="mr-4 pt-[18px]">
                          <div
                            className="flex justify-center items-center h-[30px] cursor-pointer"
                            onClick={() => {
                              if (selectedItemId === item._id) {
                                setSelectedItemId("");
                                setSelectedItemPopUpId("");
                              } else {
                                setSelectedItemId(item._id);
                                setSelectedItemPopUpId(item._id);
                              }
                            }}
                          >
                            <button className="text-[18px]">
                              <DotsIcon />
                            </button>
                          </div>
                          {renderDetails(item)}
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <tr>
                  <td colSpan={4}>No data found</td>
                </tr>
              )}
              {viewDetails()}
            </tbody>
          </table>
        </div>
        <div className="flex pagination-comp">
          <ReactPaginate
            className="flex bg-white mx-auto h-[40px] text-sm text-[#44566c]
            rounded-[10px] pagination mt-[33px]"
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            previousLinkClassName={"previous-page"}
            nextLinkClassName={"next-page"}
            disabledClassName={"disabled-page"}
            activeClassName={"active-page"}
            breakLabel={"..."}
          />
        </div>
      </div>
    </div>
  );
};

export default VAbsentTable;
