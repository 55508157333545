import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import tokenExpirationMiddleware from "./tokenExpiration";
import thunk from "redux-thunk";
import { LOGOUT, SET_EXPIRATION_TIMER } from "./features/authSlice";

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Load data from localStorage
const userDataFromStorage = localStorage.getItem('user');
const userData = userDataFromStorage ? JSON.parse(userDataFromStorage) : '';
const token = localStorage.getItem('token');
const name = localStorage.getItem('name') || "";
const expiry = localStorage.getItem('expiry');
const parsedExpiry = expiry ? parseInt(expiry, 10) : null;

const expirationHours = 47;
const expirationMinutes = 50;
const expirationTimeInMilliseconds = (expirationHours * 60 * 60 * 1000) + (expirationMinutes * 60 * 1000);

const isTokenExpired = parsedExpiry && new Date(parsedExpiry) < new Date();


const initialState = {
  auth: {
    token,
    name,
    isLoggedIn: token !== null , // Set isLoggedIn based on token and expiry
    user: userData,
    expiry: parsedExpiry,
    expirationTimer: null,
    newOrganization: null,
  },
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware(),
    thunk,
    tokenExpirationMiddleware
  ],
  preloadedState: initialState,
});

// Add a listener for changes to the auth state
let previousIsLoggedIn = initialState.auth.isLoggedIn;
if (isTokenExpired) {
  // Token has expired at the time of initialization
  previousIsLoggedIn = false;
}

// ... (previous code)

// Function to set the expiration timer
// ... (previous code)

// Function to set the expiration timer
const setExpirationTimer = () => {
  const state = store.getState();
  const { auth } = state;

  // Calculate the expiration time for the token
  const expirationTime = new Date().getTime() + expirationTimeInMilliseconds;

  // Set an expiration timer
  const expirationTimer = setTimeout(() => {
    // Token has expired, dispatch the logout action
    store.dispatch(LOGOUT()); // Replace 'LOGOUT' with your actual logout action
  }, expirationTimeInMilliseconds);

  // Update the expiration timer in the auth state
  store.dispatch(SET_EXPIRATION_TIMER(expirationTimer)); // Assuming you have a SET_EXPIRATION_TIMER action
};

// Schedule the expiration timer when the store is created or when the token is set
setExpirationTimer();

// Function to periodically check for token expiration
const checkTokenExpiration = () => {
  const state = store.getState();
  const { auth } = state;

  // Check if the token has expired
  if (auth.expiry && new Date(auth.expiry) < new Date()) {
    if (previousIsLoggedIn) {
      previousIsLoggedIn = false;
      if (auth.expirationTimer) {
        clearTimeout(auth.expirationTimer);
      }
      store.dispatch(LOGOUT()); // Replace 'LOGOUT' with your actual logout action
    }
  } else {
    previousIsLoggedIn = auth.isLoggedIn;
  }

  // Schedule the next check after a delay
  setTimeout(checkTokenExpiration, expirationTimeInMilliseconds);
};

// Start the initial check
checkTokenExpiration();

// ... (remaining code)



export const persistor = persistStore(store);
