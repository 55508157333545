import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import {
  addNewStaff,
  fetchWorkers,
  getLink,
} from "../../services/staffService";
import AllTable from "./AllTable";
import ActiveTable from "./ActiveTable";
import Suspended from "./SuspendedTable";
import ArchivedTable from "./ArchivedTable";
import { SET_NEW_STAFF } from "../../redux/features/addStaffSlice";
import { useModalContext } from "../../components/utilities/ModalContext";
import { CloseIcon } from "../../icons/icon";
import CreateLink from "./CreateLink";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Badge, Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CircleLoader from "react-spinners/CircleLoader";
import {
  LOGOUT,
  selectToken,
  setTokenWithExpiry,
} from "../../redux/features/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchNotifications,
  readNotification,
} from "../../services/notificationService";
import { SET_NOTIFICATION } from "../../redux/features/notificationSlice";
import Unapproved from "./Unapproved";
import { fetchDept, fetchLevels } from "../../services/authService";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import ReceptionistAllStaff from "./ReceptionistAllStaff";

Modal.setAppElement("#root");

enum Tabs {
  Active = "Active",
  All = "All",
  Suspended = "Suspended",
  Archived = "Archived",
  Unapproved = "Unapproved",
}

const Staff = () => {
  const [links, setLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [phonesNumber, setPhoneNumber] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const getActiveTabFromLocalStorage = () => {
    return localStorage.getItem("activeTab") || Tabs.Active;
  };
  const [activeTab, setActiveTab] = useState(() =>
    getActiveTabFromLocalStorage()
  );
  const [allStaffTable, setAllStaffTable] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const [showLevel, setShowLevel] = useState([]);
  const [showDept, setShowDept] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const [staffData, setStaffData] = useState([]);
  const { modalIzOpen, modallClose } = useModalContext();
  const dispatch = useDispatch();
  const [departmentError, setDepartmentError] = useState("");
  const tokens = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  const handleAllTabClick = () => {
    localStorage.setItem("activeTab", Tabs.All);
    setActiveTab(Tabs.All);
  };

  const handleActiveTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Active);
    setActiveTab(Tabs.Active);
  };

  const handleSuspendedTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Suspended);
    setActiveTab(Tabs.Suspended);
  };

  const handleUnapprovedTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Unapproved);
    setActiveTab(Tabs.Unapproved);
  };

  const handleArchivedTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Archived);
    setActiveTab(Tabs.Archived);
  };

  const currentDate = new Date();
  const navigate = useNavigate();

  const token = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });
  const expiry = 47 * 60 * 60 * 1000 + 50 * 60 * 1000;

  const tokenFromStorage = localStorage.getItem("token") || null;

  useEffect(() => {
    dispatch<any>(
      setTokenWithExpiry(
        tokenFromStorage ? tokenFromStorage : token,
        expiry,
        handleLogoutAndNavigate
      )
    );
  }, [dispatch, tokenFromStorage ? tokenFromStorage : token, expiry]);

  const handleLogoutAndNavigate = () => {
    dispatch(LOGOUT());
    navigate("/login");
  };

  const handleClose = () => {
    modallClose();
    reset();
    setSelectedLevel("");
    setSelectedDepartment("");
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const role = useSelector((state: any) => state.auth?.user?.role);

  const adminUser = role === "admin";
  const secretaryUser = role === "secretary";

  const adminSignUp = useSelector(
    (state: any) => state.auth?.newOrganization?.admin?.role
  );

  //ADD STAFF
  const addStaff = handleSubmit(async (formData) => {
    setLoading(true);
    try {
      let tokenToUse;

      if (adminUser) {
        tokenToUse = token;
      } else {
        tokenToUse = tokenFromStorage;
      }
      setErrorMessage("");
      const phoneNumber = parsePhoneNumberFromString(
        `${formData.phoneNumber}`,
        "NG"
      );
      const formattedPhoneNumber = `${formData.phoneNumber} ${phonesNumber}`;
      const userData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phoneNumber: phoneNumber?.formatInternational() || "",
        gender: formData.gender,
        oracleNumber: formData.oracleNumber,
        levelId: selectedLevel,
        departmentId: selectedDepartment,
      };
      const data = await addNewStaff(userData, tokenToUse);
      await dispatch(SET_NEW_STAFF(data));
      if (data.status === true) {
        reset();
        setSelectedLevel("");
        setSelectedDepartment("");
        fetchNotification();
        window.location.reload();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  });

  const genderData = ["male", "female"];

  const allStaffData = useSelector(
    (state: any) => state.allStaff?.staffList.data
  );

  useEffect(() => {
    setAllStaffTable(allStaffData);
  }, []);

  const fetchAllDept = async () => {
    let tokenToUse;

    if (adminUser || secretaryUser) {
      tokenToUse = token;
    } else {
      tokenToUse = tokenFromStorage;
    }
    try {
      const allDept = await fetchDept(tokenToUse);
      setShowDept(allDept?.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllDept();
  }, []);

  //Fetch Levels
  const fetchAllLevels = async () => {
    try {
      let tokenToUse;
      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const allLevels = await fetchLevels(tokenToUse);
      setShowLevel(allLevels?.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllLevels();
  }, []);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 5000); // Display the success message for 5 seconds
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000); // Display the success message for 5 seconds
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const adminUsers = role === "admin";

  const fetchLink = async () => {
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUsers) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const urlLink = await getLink(tokenToUse);
      setLink(urlLink?.data?.url);
    } catch (err) {}
  };
  useEffect(() => {
    fetchLink();
  }, []);

  const handleTokenExpiration = () => {
    dispatch({
      type: selectToken,
      navigateCallback: () => {
        navigate("/login");
      },
    });
  };
  useEffect(() => {
    handleTokenExpiration();
  }, []);

  const fetchNotification = async () => {
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUsers || secretaryUser) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const allNotifications = await fetchNotifications(tokenToUse, false);
      await dispatch(SET_NOTIFICATION(allNotifications));
      await readNotification(allNotifications?.data?.viewed, token);
    } catch (err) {}
  };
  useEffect(() => {
    fetchNotification();
  }, []);

  const fetchAllStaff = async () => {
    try {
      let tokenToUse;
      if (adminUser || secretaryUser) {
        tokenToUse = tokens;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const data = await fetchWorkers(tokenToUse, "");
      setStaffData(data?.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllStaff();
  }, []);

  const unapprovedStaff = staffData?.filter(
    (staff: any) => staff.status === "unapproved"
  );

  return (
    <div className="ml-[55px] mb-[57px] mt-[42px] mr-[40px] adminDash staff-page pt-[80px]">
      {!secretaryUser && (
        <div className="flex justify-between  items-center font-bold create-dept-level staff-title-text px-6  py-[5px] pt-[19px] mb-[33px]">
          <h2 className="text-[#44566c] text-[32px]">{activeTab} Staff</h2>
          <p className="text-[#6B7A99] text-base">
            {currentDate.toLocaleDateString()}
          </p>
        </div>
      )}
      {secretaryUser && (
        <div className="flex justify-between  items-center font-bold create-dept-level staff-title-text px-6  py-[5px] pt-[19px] mb-[33px]">
          <h2 className="text-[#44566c] text-[32px]">All Staff</h2>
          <p className="text-[#6B7A99] text-base">
            {currentDate.toLocaleDateString()}
          </p>
        </div>
      )}
      <Modal
        isOpen={modalIzOpen}
        onRequestClose={handleClose}
        contentLabel="Add New Staff Modal"
        className="w-[48.3%] rounded-[12px] py-[30px] pb-[34px] bg-[white] add-staff-modal add-schedule-modal"
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "40",
          },
        }}
      >
        <div className="w-[77.5%] mx-auto">
          {/* Modal Content */}
          <div className="flex justify-end mb-[34px]">
            <div onClick={handleClose} className="cursor-pointer">
              <CloseIcon />
            </div>
          </div>
          <h2 className="text-[#44566C] text-center font-bold text-[30px]">
            New Staff
          </h2>
          <form onSubmit={addStaff} className="new-staff-form h-[470px]">
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">First Name</label>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                rules={{
                  required: "First name is required",
                  minLength: {
                    value: 3,
                    message: "Name must be at least 3 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="First Name"
                    className="w-full  px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Last Name</label>
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                rules={{
                  required: "Last name is required",
                  minLength: {
                    value: 3,
                    message: "Last name must be at least 3 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Last Name"
                    className="w-full px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px]  font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Email</label>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Enter a valid email. E.g, example@gmail.com",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Email"
                    className="w-full px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <Grid item xs={12}>
                <label className="block mb-2 text-[#8697A8]">
                  Phone Number
                </label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Phone number is required",
                  }}
                  render={({ field, fieldState }) => (
                    <div>
                      <PhoneInput
                        id="phone-input"
                        placeholder="Phone Number"
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                        defaultCountry="NG"
                        international
                        withCountryCallingCode
                        className={`custom-phone-input ${
                          fieldState.error ? "error-border-phone-input" : ""
                        }`}
                      />
                      {fieldState.error && (
                        <FormHelperText error>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                />
              </Grid>
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block text-[#8697A8] mb-2">Gender</label>
              <Controller
                name="gender"
                control={control}
                defaultValue=""
                rules={{ required: "Select a gender" }}
                render={({ field, fieldState }) => (
                  <div>
                    <Select
                      {...field}
                      className="w-full bg-[#F8FAFB] px-[19px] py-3 rounded capitalize"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      style={{ height: "42px" }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      error={!!fieldState.error}
                    >
                      <MenuItem disabled value="">
                        Select a gender
                      </MenuItem>
                      {genderData?.map((gender: any, _id: any) => (
                        <MenuItem
                          key={_id}
                          value={gender}
                          className="capitalize"
                        >
                          {gender}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.error && (
                      <FormHelperText error>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="mt-[38px]  font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Oracle Number</label>
              <Controller
                name="oracleNumber"
                control={control}
                defaultValue=""
                rules={{
                  required: "oracleNumber is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Oracle Number"
                    className="w-full px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Level</label>
              <Controller
                name="level"
                control={control}
                defaultValue=""
                rules={{ required: "Select a level" }}
                render={({ field, fieldState }) => (
                  <div>
                    <Select
                      {...field}
                      className="w-full bg-[#F8FAFB]  px-[19px] py-3 capitalize rounded"
                      value={selectedLevel}
                      onChange={(e: any) => {
                        setSelectedLevel(e.target.value);
                        field.onChange(e);
                      }}
                      style={{ height: "42px" }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      error={!!fieldState.error}
                    >
                      <MenuItem disabled value="">
                        Select a Level
                      </MenuItem>
                      {showLevel?.map((levelIdss: any, _id: any) => (
                        <MenuItem
                          key={_id}
                          value={levelIdss?._id}
                          className="capitalize"
                        >
                          {levelIdss?.levelName}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.error && (
                      <FormHelperText error>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block mb-2 text-[#8697A8]">Department</label>
              <Controller
                name="department"
                control={control}
                defaultValue=""
                rules={{ required: "Select a department" }}
                render={({ field, fieldState }) => (
                  <div>
                    <Select
                      {...field}
                      className="w-full bg-[#F8FAFB] px-[19px] py-3 capitalize rounded"
                      value={selectedDepartment}
                      onChange={(e: any) => {
                        setSelectedDepartment(e.target.value);
                        field.onChange(e);
                        setDepartmentError("");
                      }}
                      style={{ height: "42px" }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      error={!!(fieldState.error || departmentError)}
                    >
                      <MenuItem disabled value="">
                        Select a Department
                      </MenuItem>
                      {showDept?.map((department: any, _id: any) => (
                        <MenuItem
                          key={_id}
                          value={department?._id}
                          className="capitalize"
                        >
                          {department?.departmentName}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.error && (
                      <FormHelperText error>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>
            <button
              type="submit"
              className="bg-[#0081ff] h-[44px] rounded-[5px] mt-[38px] text-center mx-auto cursor-pointer flex justify-center items-center w-[167px] text-[#FFF] text-[15px] font-medium hover:opacity-80"
            >
              {loading ? (
                <CircleLoader color="#ffffff" loading={loading} size={20} />
              ) : (
                "Create Staff"
              )}
            </button>
          </form>
        </div>
      </Modal>
      <div className="flex justify-between staff-status-and-link items-center">
        {!secretaryUser && (
          <div className="tabs w-fit cursor-pointer px-[2px] rounded flex items-center justify-center bg-white shadow-md font-normal text-[15px] visitors-status-tab staff-status-tab">
            <div
              className={`tab flex items-center justify-center border-r ${
                activeTab === Tabs.Active
                  ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                  : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
              }`}
              onClick={handleActiveTabClick}
            >
              Active
            </div>

            <div
              className={`tab flex items-center justify-center border-r ${
                activeTab === Tabs.Suspended
                  ? "active bg-[#0081ff] pl-0  w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                  : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
              }`}
              onClick={handleSuspendedTabClick}
            >
              Suspended
            </div>
            {/* <div className="w-[30px]  border-r text-transparent divider">i</div> */}
            <div
              className={`tab flex items-center justify-center ${
                activeTab === Tabs.Archived
                  ? "active bg-[#0081ff] pl-0  w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                  : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
              }`}
              onClick={handleArchivedTabClick}
            >
              Archived
            </div>

            <div
              className={`tab flex items-center justify-center ${
                activeTab === Tabs.Unapproved
                  ? "active bg-[#0081ff] pl-0  w-[135px] h-[35px] text-white rounded text-center hover:opacity-80 relative"
                  : "px-[30px] h-[35px] hover:bg-[#F8FAFB] relative"
              }`}
              onClick={handleUnapprovedTabClick}
            >
              <button>
                Unapproved
                <Badge
                  badgeContent={unapprovedStaff?.length}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "#fff",
                      backgroundColor: "#FF0101",
                      marginLeft: "10px",
                      marginTop: "-12px",
                    },
                  }}
                  overlap="circular"
                ></Badge>
              </button>
            </div>

            <div
              className={`tab flex items-center justify-center  border-r ${
                activeTab === Tabs.All
                  ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                  : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
              }`}
              onClick={handleAllTabClick}
            >
              All
            </div>
          </div>
        )}
        <div>{(adminUser || adminSignUp) && <CreateLink />}</div>
      </div>
      <>
      {!secretaryUser && (
      <div>
        {activeTab === Tabs.All && <AllTable />}
        {activeTab === Tabs.Active && <ActiveTable />}
        {activeTab === Tabs.Suspended && <Suspended />}
        {activeTab === Tabs.Archived && <ArchivedTable />}
        {activeTab === Tabs.Unapproved && (
          <Unapproved staffData={staffData} setStaffData={setStaffData} />
        )}
      
      </div>
      )}
      </>
      <>
      {secretaryUser && (
      <ReceptionistAllStaff />
      )}
      </>
    </div>
  );
};

export default Staff;
