import React, { useState, useEffect, useRef } from "react";
import UpdateDepartment from "./updateDepartment";
import UpdateLevel from "./UpdateLevel";

type Props = {}

const UpdateEditDeleteDepartmentLevel = (props: Props) => {
  return (
    <div>
     <UpdateDepartment />
    <UpdateLevel />
    </div>
  )
}

export default UpdateEditDeleteDepartmentLevel