import React, { useState, useEffect } from "react";
import VPendingTable from "./VPendingTable";
import VVisitedTable from "./VVisitedTable";
import VCanceledTable from "./VCanceledTable";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import {
  addNewSchedule,
  fetchAllSchedule,
  visitorStatus,
} from "../../services/VisitorService";
import { SET_NEW_SCHEDULE } from "../../redux/features/addNewScheduleSlice";
import { SET_ALL_SCHEDULE } from "../../redux/features/allSchedules1DaySlice";
import ActiveTable from "./VActiveTable";
import VAbsentTable from "./VAbsentTable";
import { toast } from "react-toastify";
import { CloseIcon } from "../../icons/icon";
import CreateVisitLink from "./CreateVisitLink";
import { LOGOUT, setTokenWithExpiry } from "../../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import {
  fetchNotifications,
  readNotification,
} from "../../services/notificationService";
import { SET_NOTIFICATION } from "../../redux/features/notificationSlice";
import VAllTable from "./VAllTable";

enum Tabs {
  All = "All",
  Waiting = "Waiting",
  Active = "Active",
  Completed = "Completed",
  Canceled = "Canceled",
  Absent = "Absent",
}
const initialState = {
  staffId: "",
  visitorName: "",
  visitorEmail: "",
  reasonForVisiting: "",
  timeOfVisiting: "",
};


const Visitors = () => {
  const getActiveTabFromLocalStorage = () => {
    return localStorage.getItem("activeTab") || Tabs.All;
  };
  const [activeTab, setActiveTab] = useState(() => getActiveTabFromLocalStorage());
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const { visitorName, visitorEmail, reasonForVisiting, timeOfVisiting } =
    formData;
  const [loading, setLoading] = useState(false);
  const [selectStaffId, setSelectedStaffId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validationError, setValidationError] = useState(initialState);
  const [stat, setStat] = useState([]);
  const [addVisitors, setAddVisitors] = useState([]);
  const currentDate = new Date();

  const handleAllTabClick = () => {
    localStorage.setItem("activeTab", Tabs.All);
    setActiveTab(Tabs.All);
  };
  
  const handleActiveTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Active);
    setActiveTab(Tabs.Active);
  };

  const handleWaitingTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Waiting);
    setActiveTab(Tabs.Waiting);
  };

  const handleCompletedTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Completed);
    setActiveTab(Tabs.Completed);
  };

  const handleCanceledTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Canceled);
    setActiveTab(Tabs.Canceled);
  };

  const handleAbsentTabClick = () => {
    localStorage.setItem("activeTab", Tabs.Absent);
    setActiveTab(Tabs.Absent);
  };

  const dispatch = useDispatch();
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();

  //Token
  const tokens = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  const isValidEmail = (visitorEmail: any) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    const isValid = emailRegex.test(visitorEmail);
    const message = isValid
      ? ""
      : "Invalid Email format. For example: example@gmail.com";
    return { valid: isValid, message: message };
  };

  const isValidVisitorName = (visitorName: any) => {
    const isValid = visitorName.trim().length >= 3;
    const message = isValid ? "" : "First Name must be at least 3 characters";
    return { valid: isValid, message: message };
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "visitorName" && value.trim().length < 3) {
      setValidationError((prevErrors) => ({ ...prevErrors, visitorName: " " }));
    } else if (name === "visitorEmail") {
      const emailValidation = isValidEmail(value);
      if (!emailValidation.valid) {
        setValidationError((prevErrors) => ({
          ...prevErrors,
          visitorEmail: " ",
        }));
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const tokenFromStorage = localStorage.getItem('token') || null;
  const role = useSelector((state: any) => state.auth?.user?.role);
  const adminUser = role === "admin";

  const adminSignUp = useSelector(
    (state: any) => state.auth?.newOrganization?.admin?.role
  );

  const fetchNotification = async () => {
    let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
    try {
      const allNotifications = await fetchNotifications( tokenToUse, false);
      await dispatch(SET_NOTIFICATION(allNotifications));
      await readNotification(allNotifications?.data?.viewed, tokens);
    } catch (err) {}
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  // ADD VISISTOR(S)
  const addSchedule = async (e: any) => {
    e.preventDefault();
    let hasError = false;
    const emailValidation = isValidEmail(formData.visitorEmail);
    if (!emailValidation.valid) {
      hasError = true;
      toast.error(emailValidation.message);
      setValidationError((prevErrors) => ({
        ...prevErrors,
        visitorEmail: "Invalid Email format. For example: example@gmail.com",
      }));
      return;
    }

    const visitorNameValidation = isValidVisitorName(formData.visitorName);
    if (!visitorNameValidation.valid) {
      hasError = true;
      toast.error(visitorNameValidation.message);
      setValidationError((prevErrors) => ({
        ...prevErrors,
        visitorName: "Visitor's Name must be at least 3 characters",
      }));
      return;
    }

    setLoading(true);
    try {
      const visitorExist = addVisitors.some(
        (addVisitor: any) => addVisitor.visitorEmail === visitorEmail
      );
      if (visitorExist) {
        // toast.error("Visitor's email already exist");
        setFormData(initialState);
        setSelectedStaffId("");
      } else {
        setErrorMessage("");
        const userData = {
          staffId: selectStaffId,
          visitorName,
          visitorEmail,
          reasonForVisiting,
          timeOfVisiting,
        };
        let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
        const data = await addNewSchedule(userData, tokenToUse);
        await dispatch(SET_NEW_SCHEDULE(data));
        toast.success("Visitor added successfully");
        setFormData(initialState);
        setSelectedStaffId("");
        fetchNotification();
      }
    } catch (error) {
     
    }
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const allStaffData = useSelector(
    (state: any) => state.allStaff?.staffList?.data
  );
  const staffIdentity = allStaffData;

  // GET VISITORS
  const fetchAllDay1Schedule = async () => {
    let tokenToUse;

    // Check if the user has signed up (using adminSignUp)
    if (adminUser) {
      tokenToUse = tokens;
    } else {
      // Assume the user has logged in
      tokenToUse = tokenFromStorage;
    }
    try {
      const data = await fetchAllSchedule(tokenToUse);

      await dispatch(SET_ALL_SCHEDULE(data));
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllDay1Schedule();
  }, []);


  const verifyStatus = async (ids: string, statuses: string, token: string) => {
    const userData = {
      status: statuses,
      id: ids,
    };
    setLoading(true);
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const data = await visitorStatus(userData, tokenToUse);
      setStat(data);
      setLoading(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
    }
  };
  const expiry = 47 * 60 * 60 * 1000 + 50 * 60 * 1000;

  useEffect(() => {
    dispatch<any>(setTokenWithExpiry(tokens, expiry, handleLogoutAndNavigate));
  }, [dispatch, tokens, expiry]);

  const handleLogoutAndNavigate = () => {
    dispatch(LOGOUT());
    navigate("/login"); //
  };

  const handleClose = () => {
    closeModal();
    setFormData(initialState);
    setSelectedStaffId("");
  };

  



  return (
    <div className="ml-[51px] mb-[57px] mt-[42px] mr-[34px] adminDash visitors-page pt-[80px]">
      <div className="flex justify-between items-center font-bold create-dept-level px-6  visitors-title-text py-[5px] pt-[19px] mb-[33px]">
      <h2 className="text-[#44566c] text-[32px]">{activeTab} Visitors</h2>
        <p className="text-[#6B7A99] text-base">
          {currentDate.toLocaleDateString()}
        </p>
      </div>

      {/* Tabs */}
      <div className="flex justify-between staff-status-and-link items-center">
        <div className="tabs w-fit cursor-pointer  px-[2px] rounded flex items-center justify-center bg-white shadow-md font-normal text-[15px] visitors-status-tab">
          <div
            className={`tab flex items-center justify-center border-r ${
              activeTab === Tabs.All
                ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
            }`}
            onClick={handleAllTabClick}
          >
            All
          </div>
          {/* <div className="w-[30px]  border-r text-transparent divider">i</div> */}
          <div
            className={`tab flex items-center justify-center border-r ${
              activeTab === Tabs.Waiting
                ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
            }`}
            onClick={handleWaitingTabClick}
          >
            Waiting
          </div>
          {/* <div className="w-[30px]  border-r text-transparent divider">i</div> */}
          <div
            className={`tab flex items-center justify-center border-r ${
              activeTab === Tabs.Active
                ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
            }`}
            onClick={handleActiveTabClick}
          >
            Active
          </div>
          {/* <div className="w-[30px]  border-r text-transparent divider">i</div> */}
          <div
            className={`tab flex items-center justify-center border-r ${
              activeTab === Tabs.Completed
                ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
            }`}
            onClick={handleCompletedTabClick}
          >
            Visited
          </div>
          {/* <div className="w-[30px]  border-r text-transparent divider">i</div> */}
          <div
            className={`tab flex items-center justify-center border-r ${
              activeTab === Tabs.Canceled
                ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
            }`}
            onClick={handleCanceledTabClick}
          >
            Cancelled
          </div>
          {/* <div className="w-[30px]  border-r text-transparent divider">i</div> */}
          <div
            className={`tab flex items-center justify-center ${
              activeTab === Tabs.Absent
                ? "active bg-[#0081ff] pl-0 w-[135px] h-[35px] text-white rounded text-center hover:opacity-80"
                : "px-[30px] h-[35px] hover:bg-[#F8FAFB]"
            }`}
            onClick={handleAbsentTabClick}
          >
            Absent
          </div>
        </div>
        <div>{(adminUser || adminSignUp) && <CreateVisitLink />}</div>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Add New Staff Modal"
        className="w-[42.7%] rounded-[12px] py-[30px] pb-[34px] bg-[white] add-schedule-modal add-staff-modal"
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className="w-[80%] mx-auto">
          <div className="flex justify-end mb-[34px]">
            <div onClick={handleClose} className="cursor-pointer">
              <CloseIcon />
            </div>
          </div>
          {/* Modal Content */}
          <h2 className="text-[#44566C] text-center font-bold text-[30px]">
            Add a Schedule
          </h2>

          <form onSubmit={addSchedule} className="new-staff-form h-[470px]">
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block text-[#8697A8]">
                Who are you visiting
              </label>
              <select
                name="staffId"
                className="w-full bg-[#F8FAFB] mt-3 px-[19px] py-3 h-[42px] capitalize rounded"
                value={selectStaffId}
                onChange={(e) => setSelectedStaffId(e.target.value)}
              >
                <option value="" className="normal-case">
                  Select a Staff
                </option>
                {staffIdentity?.map((staffIds: any, _id: any) => (
                  <option
                    key={_id}
                    value={staffIds?._id}
                    className="capitalize"
                  >
                    {`${staffIds?.firstName} ${staffIds?.lastName}`}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block text-[#8697A8]">Visitor's Full Name</label>
              <input
                type="text"
                name="visitorName"
                value={visitorName}
                onChange={handleInputChange}
                className="w-full bg-[#F8FAFB] mt-3 px-[19px] py-3 h-[42px] rounded"
                placeholder="Your Name"
              />
              {validationError?.visitorName && (
                <p className="text-red-500">{validationError?.visitorName}</p>
              )}
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block text-[#8697A8]">Visitor's Email</label>
              <input
                type="email"
                name="visitorEmail"
                value={visitorEmail}
                onChange={handleInputChange}
                className="w-full bg-[#F8FAFB] mt-3 px-[19px] py-3 h-[42px] rounded"
                placeholder="Your Email"
              />
              {validationError?.visitorEmail && (
                <p className="text-red-500">{validationError?.visitorEmail}</p>
              )}
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block text-[#8697A8]">
                Reason for Visitation
              </label>
              <input
                type="text"
                name="reasonForVisiting"
                value={reasonForVisiting}
                onChange={handleInputChange}
                className="w-full bg-[#F8FAFB] mt-3 px-[19px] py-3 h-[42px] rounded"
              />
            </div>
            <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
              <label className="block text-[#8697A8]">Time Visitation</label>
              <input
                type="datetime-local"
                name="timeOfVisiting"
                value={timeOfVisiting}
                onChange={handleInputChange}
                className="w-full bg-[#F8FAFB] mt-3 px-[19px] py-3 h-[42px] rounded"
              />
            </div>
            <div className="h-[42px] flex justify-center items-center  bg-[#0081ff] rounded-[5px] mt-[38px] text-center text-white text-[15px] font-medium mr-[10px] cursor-pointer">
              <button type="submit" className="w-full h-[42px]">
                Create Schedule
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {activeTab === Tabs.All && <VAllTable verifyStatus={verifyStatus} />}
      {activeTab === Tabs.Waiting && ( <VPendingTable verifyStatus={verifyStatus} />)}
      {activeTab === Tabs.Active && <ActiveTable verifyStatus={verifyStatus} />}
      {activeTab === Tabs.Completed && (
        <VVisitedTable verifyStatus={verifyStatus} />
      )}
      {activeTab === Tabs.Canceled && (
        <VCanceledTable verifyStatus={verifyStatus} />
      )}
      {activeTab === Tabs.Absent && (
        <VAbsentTable verifyStatus={verifyStatus} />
      )}
    </div>
  );
};
export default Visitors;
