import React, { useEffect, useState, useRef } from "react";
import { NotificationIcon } from "../icons/icon";
import { GreyArrowRight } from "../icons/icon";
import Badge from "@mui/material/Badge/Badge";
import {
  fetchNotifications,
  readNotification,
} from "../services/notificationService";
import { useSelector, useDispatch } from "react-redux";
import {
  MARK_NOTIFICATION_AS_VIEWED,
  SET_NOTIFICATION,
} from "../redux/features/notificationSlice";
import Modal from "react-modal";
import { useModalContext } from "../components/utilities/ModalContext";
import CircleLoader from "react-spinners/CircleLoader";

const initialState = {
  id: "",
};
const Notification = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [showAllMessages, setShowAllMessages] = useState(false);
  const [isViewAllDisabled, setIsViewAllDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const { id } = formData;
  const [selectedItemId, setSelectedItemId] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    notificationModalOpen,
    modalNotificationClose,
    modalNotificationOpen,
  } = useModalContext();

  const handleViewAllMessages = () => {
    setShowAllMessages(true);
    setIsViewAllDisabled(true);
  };

  const toggleNotification = () => {
    if (notificationModalOpen) {
      modalNotificationClose();
    } else {
      modalNotificationOpen();
    }
  };

  const tokens = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });
  const role = useSelector((state: any) => state.auth?.user?.role);
  // const rolee = useSelector( (state: any) => state.auth?.newOrganization?.admin?.role);

  const secretaryUser = role === "secretary";
  const adminUser = role === "admin";

  const adminSignUp = useSelector(
    (state: any) => state.auth?.newOrganization?.admin?.role
  );
  
  
  const tokenFromStorage = localStorage.getItem('token') || null;
  let parsedToken = null;
  if (tokenFromStorage !== null) {
    try {
      parsedToken = JSON.parse(tokenFromStorage);
    } catch (error) {}
  }

  const token = useSelector((state: any) => state.auth?.token);

  const visibleNotifications = useSelector(
    (state: any) => state.getNotifications?.notifications?.data?.notifications
  );

  // Fetch Notifications
  const fetchNotification = async () => {
    try {
      let tokenToUse;
      if (adminUser) {
        tokenToUse = token;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const allNotifications = await fetchNotifications(
       tokenToUse,
        false
      );

      setData(allNotifications?.data?.notifications);
      await dispatch(SET_NOTIFICATION(allNotifications));
      await dispatch(
        MARK_NOTIFICATION_AS_VIEWED(allNotifications?.data?.viewed)
      );
      await readNotification(allNotifications?.data?.viewed, token);
      readNotifications([id], token);
    
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  const readNotifications = async (ids: string[], token: string) => {
    setLoading(true);
    try {
       let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminUser) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const markAsReadPromises = ids.map(async (id) => {
        const userData = {
          id,
        };
        return readNotification(userData, token);
      });
      const markAsReadResults = await Promise.all(markAsReadPromises);
      markAsReadResults.forEach((result, index) => {
        const id = ids[index];
      });
      setLoading(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setFormData(initialState);
      fetchNotification();
    } catch (error) {
      setLoading(false);
      setFormData(initialState);
      // Handle the error here
    }
  };
  useEffect(() => {
    let tokenToUse;

    // Check if the user has signed up (using adminSignUp)
    if (adminUser) {
      tokenToUse = token;
    } else {
      // Assume the user has logged in
      tokenToUse = tokenFromStorage;
    }
    readNotifications([id], tokenToUse);
  }, []);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    readNotifications(selectedItemId, token);
    setSelectedItemId([]);
  };

  //CLEAR ALL NOTIFICATIONS
  const clearAllNotifications = async () => {
    setLoading(true);
    try {
      if (selectedItemId?.length > 0) {
        await readNotifications(selectedItemId, token);
      } else {
        const notificationIds = visibleNotifications.map(
          (notification: any) => notification._id
        );
        await readNotifications(notificationIds, token);
      }

      setLoading(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
    }
  };

  //Time Frame
  const formatTimestamp = (timestamp: any) => {
    const now = new Date();
    const date = new Date(timestamp);
    const timeDifference = now.getTime() - date.getTime();

    const minutesAgo = Math.floor(timeDifference / 1000 / 60);

    if (minutesAgo < 60) {
      return `${minutesAgo} minutes ago`;
    } else if (minutesAgo < 1440) {
      const hoursAgo = Math.floor(minutesAgo / 60);
      return `${hoursAgo} hour(s) ago`;
    } else {
      const daysAgo = Math.floor(minutesAgo / 1440); // 1440 minutes in a day
      return `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
    }
  };

  const timestampFromBackend = "2023-09-07T23:16:38.067Z";
  const formattedTimestamp = formatTimestamp(timestampFromBackend);

  const notificationContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (event: Event) => {
      if (
        notificationContainerRef.current &&
        !notificationContainerRef.current.contains(event.target as Node)
      ) {
        setIsNotificationOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="notification-container" ref={notificationContainerRef}>
      <Badge
        badgeContent={data?.length}
        sx={{
          "& .MuiBadge-badge": {
            color: "#fff",
            backgroundColor: "#FF0101",
            marginLeft: "8px",
            // marginBottom: "-20px",
          },
        }}
        overlap="circular"
      >
        <button
          className="notification-icon rounded-[100px] flex justify-center items-center"
          onClick={toggleNotification}
        >
          <NotificationIcon />
        </button>
      </Badge>

      <Modal
        style={{
          overlay: {
            backgroundColor: "transparent",
            marginTop: "82px",
            display: "flex",
            justifyContent: "end",
            paddingRight: "20px",
          },
          content: {
            height: "fit-content",
          },
        }}
        isOpen={notificationModalOpen}
        onRequestClose={modalNotificationClose}
        className="w-[352px] bg-[#fff] text-[15px] text-[#44566C] font-normal shadow  border border-solid border-[#ebebeb] notification-modal"
      >
        <div>
          <div className="flex justify-between items-center pt-[14px] pr-[22px] pb-[10px] pl-[15px] border-b-[1px] border-[#EAEDF0]">
            <div className="flex items-center">
              <p className="mr-[9px]">Notifications</p>
              <p className="rounded-[20px] bg-[#0081FF] px-2 text-[#fff] font-medium">
                {data?.length}
              </p>
            </div>
            <p className="cursor-pointer" onClick={clearAllNotifications}>
              Clear All
            </p>
          </div>
          {loading && (
            <div className="w-[20%] mx-auto mt-[100px]">
              <CircleLoader color="#0081FF" size={20} />
            </div>
          )}
          <ul className="notification-body">
            {showAllMessages
              ? data?.map((notification: any, index: any) => (
                  <form
                    onSubmit={handleFormSubmit}
                    className="cursor-pointer"
                    onClick={() => {
                      setSelectedItemId((prevIds) => [
                        ...prevIds,
                        notification._id,
                      ]);
                    }}
                  >
                    <li
                      key={notification._id}
                      className="pr-[12px] pl-[23px] py-[12px] notification-item"
                    >
                      <div className="flex justify-between items-center">
                        <button className="mb-[6px] font-bold cursor-pointer">
                          {notification.title}
                        </button>
                        <button
                          className="text-[13px] text-[#8697A8]"
                          onClick={() => {
                            setSelectedItemId((prevIds) => [
                              ...prevIds,
                              notification._id,
                            ]);
                          }}
                        >
                          {formatTimestamp(notification?.createdAt)}
                        </button>
                      </div>
                      <button className="text-[13px] text-left cursor-pointer">
                        {notification.message}
                      </button>
                      <div className="notification-status">
                        {notification.viewed ? (
                          <div className="circle red"></div>
                        ) : (
                          <div className="circle green"></div>
                        )}
                      </div>
                    </li>
                  </form>
                ))
              : visibleNotifications
                  ?.slice(0, 3)
                  ?.map((notification: any, index: any) => (
                    <form
                      onSubmit={handleFormSubmit}
                      className="cursor-pointer"
                      onClick={() => {
                        setSelectedItemId((prevIds) => [
                          ...prevIds,
                          notification._id,
                        ]);
                      }}
                    >
                      <li
                        key={notification._id}
                        className="pr-[12px] pl-[23px] py-[12px] notification-item"
                      >
                        <div className="flex justify-between items-center">
                          <button className="mb-[6px] font-bold cursor-pointer">
                            {notification.title}
                          </button>
                          <button className="text-[13px] text-[#8697A8]">
                            {formatTimestamp(notification?.createdAt)}
                          </button>
                        </div>
                        <button className="text-[13px] text-left cursor-pointer">
                          {notification.message}
                        </button>
                        <div className="notification-status">
                          {notification.viewed ? (
                            <div className="circle red"></div>
                          ) : (
                            <div className="circle green"></div>
                          )}
                        </div>
                      </li>
                    </form>
                  ))}
          </ul>
          <button
            className="w-full h-[45px] text-[#8697A8] text-center bg-[#F8FAFB] flex justify-center items-center hover:opacity-80"
            onClick={handleViewAllMessages}
            disabled={isViewAllDisabled}
          >
            <p className="mr-[15px]">View all Messages</p>
            <GreyArrowRight />
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Notification;
