import axios from "axios";
import { toast } from "react-toastify";

export const Backend_URL = process.env.REACT_APP_AUTH_URL;

export const fetchNotifications = async (token: string, isViewed: boolean) => {
    try {
      const response = await axios.get(
        `${Backend_URL}/notifications`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          },
          params: {
            viewed: isViewed, // Add the boolean query parameter here
          },
        }
      );
       
      // if (response.statusText === "OK") {
      //   toast.success("Successful...");
      // }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
    //   toast.error('Failed. Try again');
      throw error;
    }
  };

  export const readNotification = async (userData: any, token: string,) =>{
    try {
      const response = await axios.put(
        `${Backend_URL}/notifications/${userData.id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        }
      );
    
    //   if (response.statusText === "OK") {
    //     toast.success("Success");
    //   }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        // toast.error(message); 
        // throw new Error(message); 
        
    }
  };