import React, { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LOGOUT, SET_TOKEN } from "../redux/features/authSlice";
import { useModalContext } from "./utilities/ModalContext";
import AddSchedule from "../views/Visit/AddSchedule";
import { CLEAR_ALL_STAFF } from "../redux/features/allStaffSlice";
import { CLEAR_VISITOR_DATA } from "../redux/features/allSchedules1DaySlice";
import { motion, AnimatePresence } from "framer-motion";
import { AddIcon, BookIcon, HomeIcon, LogoutIcon, MsgIcon, PasswordIcon, ProfileIcon, Receptionist, StaffIcon } from "../icons/icon";

interface SidebarProps {
  show?: boolean;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  showDesktop?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ show, setShow, showDesktop }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {  openModal } =
    useModalContext();

  const logOut = useSelector((state: any) => state.auth?.token);

  const handleLogout = () => {
    dispatch(LOGOUT());
    dispatch(CLEAR_ALL_STAFF());
    dispatch(CLEAR_VISITOR_DATA());
    localStorage.removeItem('token');
    localStorage.removeItem('persist:root');
    navigate("/login");
  };

  const storedToken = localStorage.getItem("authToken");
  if (storedToken) {
    dispatch(SET_TOKEN(storedToken));
  }

  const role = useSelector((state: any) => state.auth?.user?.role);


  const secretaryUser = role === "secretary";

  return (
    <AnimatePresence>
      {!showDesktop ? (
        <motion.div
          className="w-[310px] sidebar-container"
          initial={show ? { x: -100, opacity: 0 } : { x: 0, opacity: 1 }}
          animate={show ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }}
          exit={{ x: -100, opacity: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          <div className="ml-[34px] pt-[15px] pb-[15px] rounded-[20px] shadow  border border-solid border-[#ebebeb]  bg-[#fcfcfc] sidebar">
            <div>
              <div>
                <div>
                  {/* BOTH */}

                  <Link
                    className={`sidebar__link ${
                      location.pathname === "/" ? "sidebar__link--active" : ""
                    }`}
                    to="/"
                  >
                    <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white">
                    <HomeIcon />
                      <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                        Dashboard
                      </p>
                    </div>
                  </Link>

                  {/* SECRETARY ONLY */}

                  {secretaryUser && (
                    <Link
                      className={`sidebar__link ${
                        location.pathname === "/secdashboard"
                          ? "sidebar__link--active"
                          : ""
                      }`}
                      to="/secdashboard"
                    >
                      <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                      <BookIcon />
                        <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                          Visitors
                        </p>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
              <div>

                {/* ADNIN ONLY */}
                {!secretaryUser  && (
                  <Link
                    className={`sidebar__link ${
                      location.pathname === "/visitors"
                        ? "sidebar__link--active"
                        : ""
                    }`}
                    to="/visitors"
                  >
                    <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                     <BookIcon />
                      <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                        Visitors
                      </p>
                    </div>
                  </Link>
                )}
              </div>

              {/* BOTH */}
              <Link
                className={`sidebar__link ${
                  location.pathname === "/staff" ? "sidebar__link--active" : ""
                }`}
                to="/staff"
              >
                <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                 <StaffIcon />
                  <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                    Staff
                  </p>
                </div>
              </Link>

              <div>
                {/* ADMIN ONLY */}
                {!secretaryUser  && (
                  <Link
                    className={`sidebar__link ${
                      location.pathname === "/profile"
                        ? "sidebar__link--active"
                        : ""
                    }`}
                    to="/profile"
                  >
                    <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                    <ProfileIcon />

                      <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                        My Profile
                      </p>
                    </div>
                  </Link>
                )}
              </div>

              {/* BOTH */}
              <>
                <div
                  className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5 cursor-pointer hover:bg-[#F8FAFB] hover:border-[2px]"
                  onClick={openModal}
                >
                  <AddIcon />
                  <button
                    className="
            text-[#0081ff] pl-5 font-bold tracking-wide"
                  >
                    Add Schedule
                  </button>
                </div>
              </>
              <AddSchedule />
              {/* <div>
              {!secretaryUser  && (
                  <Link
                    className={`sidebar__link ${
                      location.pathname === "/broadcast"
                        ? "sidebar__link--active"
                        : ""
                    }`}
                    to="/broadcast"
                  >
                    <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                     <MsgIcon />
                      <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                        Broadcast message
                      </p>
                    </div>
                  </Link>
                )}
              </div> */}
              <div>
              {!secretaryUser  && (
                  <Link
                    className={`sidebar__link ${
                      location.pathname === "/receptionist"
                        ? "sidebar__link--active"
                        : ""
                    }`}
                    to="/receptionist"
                  >
                    <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                      <Receptionist />
                      <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                        Receptionist
                      </p>
                    </div>
                  </Link>
                )}
              </div>
              {secretaryUser && (
                <Link
                  className={`sidebar__link ${
                    location.pathname === "/receptionist/changepassword"
                      ? "sidebar__link--active"
                      : ""
                  }`}
                  to="/receptionist/changepassword"
                >
                  <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                   <PasswordIcon />

                    <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                      Change Password
                    </p>
                  </div>
                </Link>
              )}
            </div>

            <div
              className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto border border-solid border-[#ebebeb] bg-white mt-2.5 cursor-pointer hover:bg-[#F8FAFB] hover:border-[2px]"
              onClick={handleLogout}
            >
           <LogoutIcon />
              <button className="text-[#ff0101] pl-4 font-bold">Logout</button>
            </div>
          </div>
        </motion.div>
      ) : (
        <div className="w-[310px] fixed overflow-y-auto desktop-sidebar-container">
          <div className="ml-[34px] pt-[15px] pb-[15px] rounded-[20px] shadow  border border-solid border-[#ebebeb]  bg-[#fcfcfc] sidebar">
            <div>
              <div>
                <div>
                  {/* BOTH */}

                  <Link
                    className={`sidebar__link ${
                      location.pathname === "/" ? "sidebar__link--active" : ''
                    }`}
                    to="/"
                  >
                    <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white">
                    
                      <span className="text-blue-500">
                      <HomeIcon />
                      </span>

                      <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                        Dashboard
                      </p>
                    </div>
                  </Link>

                  {/* SECRETARY ONLY */}

                  {secretaryUser && (
                    <Link
                      className={`sidebar__link ${
                        location.pathname === "/secdashboard"
                          ? "sidebar__link--active"
                          : ""
                      }`}
                      to="/secdashboard"
                    >
                      <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                      <span className="text-blue-500">
                       <BookIcon />
                      </span>
                      
                        <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                          Visitors
                        </p>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
              <div>
                {/* ADNIN ONLY */}
                {!secretaryUser && (
                  <Link
                    className={`sidebar__link ${
                      location.pathname === "/visitors"
                        ? "sidebar__link--active"
                        : ""
                    }`}
                    to="/visitors"
                  >
                    <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                    <span className="text-blue-500">
                       <BookIcon />
                      </span>

                      <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                        Visitors
                      </p>
                    </div>
                  </Link>
                )}
              </div>
              {/* Admin */}
             
              <Link
                className={`sidebar__link ${
                  location.pathname === "/staff" ? "sidebar__link--active" : ""
                }`}
                to="/staff"
              >
                <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                <span className="text-blue-500">
                     <StaffIcon />
                      </span>
                  <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                    Staff
                  </p>
                </div>
              </Link>
              

              {/* RECEPTIONIST ONLY */} 
              {/* {secretaryUser && (
              <Link
                className={`sidebar__link ${
                  location.pathname === "/staff" ? "sidebar__link--active" : ""
                }`}
                to="/receptionist/staff"
              >
                <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                <span className="text-blue-500">
                     <StaffIcon />
                      </span>
                  <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                    Staff
                  </p>
                </div>
              </Link>
              )} */}

              <div>
                {/* ADMIN ONLY */}
                {!secretaryUser && (
                  <Link
                    className={`sidebar__link ${
                      location.pathname === "/profile"
                        ? "sidebar__link--active"
                        : ""
                    }`}
                    to="/profile"
                  >
                    <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                    <span className="text-blue-500">
                     <ProfileIcon />
                      </span>

                      <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                        My Profile
                      </p>
                    </div>
                  </Link>
                )}
              </div>
              {/* BOTH */}
              <>
                <div
                  className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5 cursor-pointer hover:bg-[#F8FAFB] hover:border-[2px]"
                  onClick={openModal}
                >
                <span className="text-blue-500">
                     <AddIcon />
                      </span>
                  <button
                    className="
                        text-[#0081ff] pl-5 font-bold tracking-wide"
                  >
                    Add Schedule
                  </button>
                </div>
              </>
              <AddSchedule />
              <div>
                {!secretaryUser  && (
                  <Link
                    className={`sidebar__link ${
                      location.pathname === "/receptionist"
                        ? "sidebar__link--active"
                        : ""
                    }`}
                    to="/receptionist"
                  >
                    <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                      <Receptionist />
                      <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                        Receptionist
                      </p>
                    </div>
                  </Link>
                )}
              </div>
              {/* <div>
              {!secretaryUser  && (
                  <Link
                    className={`sidebar__link ${
                      location.pathname === "/broadcast"
                        ? "sidebar__link--active"
                        : ""
                    }`}
                    to="/broadcast"
                  >
                    <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                     <MsgIcon />
                      <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                        Broadcast message
                      </p>
                    </div>
                  </Link>
                )}
              </div> */}

              {/* Secretary Only */}
              {secretaryUser && (
                <Link
                  className={`sidebar__link ${
                    location.pathname === "/receptionist/changepassword"
                      ? "sidebar__link--active"
                      : ""
                  }`}
                  to="/receptionist/changepassword"
                >
                  <div className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto my-auto border border-solid border-[#ebebeb] bg-white mt-2.5">
                   <PasswordIcon />
                    <p className="text-[#0081ff] pl-5 font-bold tracking-wide">
                      Change Password
                    </p>
                  </div>
                </Link>
              )}
            </div>

            <div
              className="flex items-center h-[60px] p-[15px] rounded-[10px] w-[90%] mx-auto border border-solid border-[#ebebeb] bg-white mt-[86px] cursor-pointer hover:bg-[#F8FAFB] hover:border-[2px]"
              onClick={handleLogout}
            >
              <LogoutIcon />
              <button className="text-[#ff0101] pl-4 font-bold">Logout</button>
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Sidebar;
