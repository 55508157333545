import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ScheduleData {
  _id: string;
  staff: string;
  visitorName: string;
  visitorEmail: string;
  reasonForVisiting: string;
  timeOfVisiting: string;
  status: string;
  organization: string;
  __v: number;
  }

  interface Schedular{
    scheduleList: ScheduleData[];
  }

  const initialState: Schedular = {
    scheduleList: [],
  };

  const allScheduleSlice = createSlice({
    name: "visitor",
    initialState,
    reducers: {
      SET_ALL_SCHEDULE: (state, action: PayloadAction<ScheduleData[]>) => {
        state.scheduleList = action.payload;
      },
      CLEAR_VISITOR_DATA: (state) => {
        state.scheduleList = [];
      },
    },
  });

  
export const { SET_ALL_SCHEDULE, CLEAR_VISITOR_DATA} = allScheduleSlice.actions;

export default allScheduleSlice.reducer;
  