import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createLevel } from "../../types/CreateLevels";
import {
  deleteLevels,
  fetchLevels,
  levelCreation,
} from "../../services/authService";
import Modal from "react-modal";
import { SET_LEVELS } from "../../redux/features/levelSlice";
import { SET_NEW_LEVEL_NAME } from "../../redux/features/addlevelSlice";
import { useModalContext } from "../../components/utilities/ModalContext";
import CircleLoader from "react-spinners/CircleLoader";
import { SET_TOKEN, SET_USER } from "../../redux/features/authSlice";
import { CloseIcon } from "../../icons/icon";
import { WhiteCloseIcon } from "../../icons/icon";
import { toast } from "react-toastify";
import Group from "../../icons/ic_round-group.svg";
import Add from "../../icons/mingcute_add-fill.svg";
import { TextField, Button } from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";

const initialState = {
  levelName: "",
};

const CreateLevels = ({ onClose }: any) => {
  const [name, setName] = useState("");
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const {
    modalIsOpen,
    modalOpen,
    modalClose,
    modalLevelOpen,
    modalLevelClose,
    modalLevOpen,
  } = useModalContext();
  const dispatch = useDispatch();
  const [selectedItemId, setSelectedItemId] = useState("");
  const tokens = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });
  const [formData, setFormData] = useState(initialState);
  const { levelName } = formData;
  const [levels, setLevels] = useState<string[]>([]);
  const successMessages: string[] = [];
  const successfulLevels: any[] = [];

  const token = useSelector((state: any) => state.auth?.token);
  const tokenFromStorage = localStorage.getItem('token') || null;
  const role = useSelector((state: any) => state.auth?.user?.role);
  const secretaryUser = role === "secretary";
  const adminUser = role === "admin";

  const adminSignUp = useSelector(
    (state: any) => state.auth?.newOrganization?.admin?.role
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const handleAddLevelArray = handleSubmit(async (formData) => {
    const newLevel = formData.levelName
    setLevels([...levels, newLevel]);
    reset();
  });

  const handleRemoveLevelArray = (index: number) => {
    const updatedLevels = [...levels];
    updatedLevels.splice(index, 1);
    setLevels(updatedLevels);
  };

  const handleCreateLevels = async () => {
    if (levels.length > 0) {
      setLoading(true);
      try {
        const userData = {
          names: levels,
        };
        let tokenToUse;

     
        if (adminUser || secretaryUser) {
          tokenToUse = token;
        } else {
          // Assume the user has logged in
          tokenToUse = tokenFromStorage;
        }
        const data = await levelCreation(userData, tokenToUse);
        data?.data.forEach((level: any) => {
          if (level.error === true) {
            toast.error(level?.message);
          } else {
            successMessages.push(level?.levelName);
            successfulLevels.push(level);
          }
        });

        if (successMessages.length > 0) {
          toast.success(
            `Levels ${successMessages.join(", ")} created successfully.`
          );
        }
        await fetchAllLevels();

        //   await dispatch(SET_NEW_LEVEL_NAME(data));
        setLevels([]);
        modalClose();
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Please click the add button before submitting");
    }
  };

  const fetchAllLevels = async () => {
    try {
      let tokenToUse;

     
      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        // Assume the user has logged in
        tokenToUse = tokenFromStorage;
      }
      const allLevels = await fetchLevels(tokenToUse);
      await dispatch(SET_LEVELS(allLevels));
      // await dispatch(SET_TOKEN(allLevels?.data?.token));
      // await dispatch(SET_USER(allLevels?.data?.user));
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllLevels();
  }, []);

  return (
    <div>
      <button
        className="bg-[#0081ff] w-[190px] h-[40px] text-center rounded text-white shadow-md hover:opacity-80"
        onClick={modalOpen}
      >
        Create Level <img className="inline " src={Add} />
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          modalClose();
          reset();
          setLevels([]);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        className="w-[42%] rounded-[12px] py-[45px] bg-[white] max-w-[880px] create-level-modal"
      >
        <div className="mx-auto z-50 w-[79.5%] justify-center justify-items-center">
          <div className="flex justify-end mb-[30px]">
            <div
              onClick={() => {
                modalClose();
                reset();
                setLevels([]);
              }}
              className="cursor-pointer"
            >
              <CloseIcon />
            </div>
          </div>
          <h1 className="text-center text-[#44566c] text-3xl  font-bold">
            Create <span className="text-[#0081ff]">Level</span>
          </h1>

          <form>
            <div className=" h-[47px] border-[0.639px] border-solid border-[#C4C4C4] rounded-[5px] mt-[51px] pl-[20px] pr-[5px]">
              <Controller
                name="levelName"
                control={control}
                defaultValue=""
                rules={{
                  required: "Level name is required",
                  validate: (value) => {
                    const newLevel = value
                    if (levels.includes(newLevel)) {
                      return "Level already added";
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState }) => (
                  <div>
                    <div className="flex items-center">
                      <div className="flex flex-1">
                        <img src={Group} alt="group" />
                        <TextField
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                          placeholder="Level Name"
                          className="department-textfield"
                          variant="outlined"
                          fullWidth
                          error={!!fieldState.error}
                          {...field}
                        />
                      </div>
                      <button
                        className="bg-[#0081ff] w-[78px] rounded-[5px] h-[40px] flex justify-center items-center text-white text-[13px] font-bold ml-[10px] hover:opacity-80"
                        onClick={handleAddLevelArray}
                      >
                        <p className="mr-[9px]">Add</p>
                        <img src={Add} />
                      </button>
                    </div>
                    {fieldState.error && (
                      <FormHelperText error>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>
          </form>
          {levels.length > 0 && (
            <div className="h-[150px]  mt-4 department-content py-[10px]">
              <ul className=" grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-3">
                {levels?.map((level: any, index: any) => (
                  <li
                    className="bg-[#44566c] p-[10px] rounded-[7px] text-white flex justify-center items-center capitalize"
                    key={level._id}
                    style={{
                      wordWrap: "break-word",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <p
                      style={{
                        overflowWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        width: "100%",
                        height: "fit-content",
                      }}
                    >
                      {level}
                    </p>
                    <button onClick={() => handleRemoveLevelArray(index)}>
                      <WhiteCloseIcon />
                    </button>
                    <div></div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="w-full mt-[25px]">
            <Button
              className="create-dept-level-submit-btn"
              type="submit"
              disabled={loading}
              onClick={handleCreateLevels}
            >
              {loading ? (
                <CircleLoader color="#ffffff" loading={loading} size={20} />
              ) : (
                "Done"
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateLevels;
