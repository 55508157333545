import React, { useState, useRef } from "react";
import Eye from "../../icons/Eye.svg";
import Eye2 from "../../icons/eye-regular.svg";
import { toast } from "react-toastify";
import { resetPassword } from "../../services/authService";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";
import CircleLoader from "react-spinners/CircleLoader";
import { useForm, Controller } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

const initialState = {
  newPassword: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const [step, setStep] = useState(1);
  const [passcode, setPasscode] = useState("");
  const [viewPassword, setViewPassword] = useState("");
  const [confirmViewPassword, setConfirmViewPassword] = useState("");
  const [formData, setformData] = useState(initialState);
  const { newPassword, confirmPassword } = formData;
  const [code, setCode] = useState(["", "", "", ""]);
  const [codeError, setCodeError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const handleShowPassword = () => {
    setViewPassword((previousValue): any => !previousValue);
  };

  const handleConfirmShowPassword = () => {
    setConfirmViewPassword((previousValue): any => !previousValue);
  };

  const handleChangeNewPassword = (value: string) => {
    setformData((prevFormData) => ({
      ...prevFormData,
      newPassword: value,
    }));
    setErrorMessage("");
  };

  const handleChangeConfirmPassword = (value: string) => {
    setformData((prevFormData) => ({
      ...prevFormData,
      confirmPassword: value,
    }));
    setErrorMessage("");
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const validatePasswordMatch = (value: string) => {
    return value === watch("newPassword") || "Passwords do not match";
  };

  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const correctVerificationCode = "1234";

  const handleInputChange = (
    index: number,
    value: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (/^\d*$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (event.target.value === "") {
        if (index > 0) {
          inputRefs[index - 1].current?.focus();
        }
      } else if (value.length === 1 && index < inputRefs.length - 1) {
        inputRefs[index + 1].current?.focus();
      }
    } else {
      setCodeError("Please enter digits only");
    }
  };

  const handleStepChange = () => {
    const enteredCode = code.join("");
    if (enteredCode.length === 4) {
      setStep(step + 1);
      setCodeError("");
    } else {
      setCodeError("Please enter a 4-digit code");
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && code[index] === "") {
      event.preventDefault();
      const newCode = [...code];
      newCode[index - 1] = "";
      setCode(newCode);
      inputRefs[index - 1].current?.focus();
    }
  };

  const handlePaste = (
    index: number,
    event: React.ClipboardEvent<HTMLInputElement>
  ) => {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData("text");
    if (/^\d{4}$/.test(pastedData)) {
      // Only accept pasting if it's exactly 4 digits
      const newCode = [...code];
      for (let i = 0; i < 4; i++) {
        if (index + i < inputRefs.length) {
          newCode[index + i] = pastedData.charAt(i);
        }
      }
      setCode(newCode);
    } else {
      event.preventDefault();
      setCodeError("Please paste exactly 4 digits");
    }
  };

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const handleResetSubmit = handleSubmit(async (formData) => {
    setLoading(true);
    const verificationCode = code.join("");
    const userData = {
      newPassword: formData.newPassword,
      confirmPassword: formData.confirmPassword,
      code: verificationCode,
    };
    try {
      const data = await resetPassword(userData);
      if (data.status === true) {
        toast.success("Password reset successfully");
        reset();
        setCode(["", "", "", ""]);
        navigate("/login");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  });

  

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="w-[40.6%] sign-in-form px-[50px] py-[36px] mr-auto ml-auto  bg-white shadow-lg shadow-slate-100 rounded-[11.3px] ">
        <form
          onSubmit={handleResetSubmit}
          className="w-[90%] mr-auto ml-auto sign-in-form-inner"
        >
          <h1 className=" text-3xl font-bold text-[#44566C] text-center">
            Get <span className="text-[#0081FF]">Started</span>{" "}
          </h1>
          {step === 1 && (
            <div>
              <p className="text-[#8697A8]  text-center mr-auto ml-auto mt-6">
                We’ve sent a verification code to your email <br />
                <strong>{location?.state?.email}</strong>
                <span className="block">OTP expires after <b>thirty minutes</b></span>
              </p>

              <div className="passcode-input mt-14">
                <input
                  ref={inputRefs[0]}
                  value={code[0]}
                  onChange={(e) => handleInputChange(0, e.target.value, e)}
                  onKeyDown={(e) => handleKeyDown(0, e)}
                  onPaste={(e) => handlePaste(0, e)}
                  type="text"
                  name="code"
                  maxLength={1}
                />
                <input
                  ref={inputRefs[1]}
                  value={code[1]}
                  onChange={(e) => handleInputChange(1, e.target.value, e)}
                  onKeyDown={(e) => handleKeyDown(1, e)}
                  onPaste={(e) => handlePaste(1, e)}
                  type="text"
                  name="code"
                  maxLength={1}
                />
                <input
                  ref={inputRefs[2]}
                  value={code[2]}
                  onChange={(e) => handleInputChange(2, e.target.value, e)}
                  onKeyDown={(e) => handleKeyDown(2, e)}
                  onPaste={(e) => handlePaste(2, e)}
                  type="text"
                  name="code"
                  maxLength={1}
                />
                <input
                  ref={inputRefs[3]}
                  value={code[3]}
                  onChange={(e) => handleInputChange(3, e.target.value, e)}
                  onKeyDown={(e) => handleKeyDown(3, e)}
                  onPaste={(e) => handlePaste(3, e)}
                  type="text"
                  name="code"
                  maxLength={1}
                />
              </div>
              {codeError && (
                <p className="text-[#FF0101] text-xs mt-[3px]">{codeError}</p>
              )}

              <div className="flex gap-x-[10px] justify-between mt-[49px]">
                <Button
                  className="sign-up-btn prev"
                  type="submit"
                  onClick={() => {
                    navigate("/forgotpassword");
                  }}
                >
                  Back
                </Button>
                <Button
                  className="sign-up-btn next"
                  type="button"
                  onClick={handleStepChange}
                >
                  Next
                </Button>
              </div>
              <p className="text-center text-base mt-[48px] font-normal text-[#0081FF]">
                <Link to="/login" className="hover:opacity-80">
                  {" "}
                  Have an account?
                </Link>
              </p>
            </div>
          )}

          {step === 2 && (
            <div className="ml-auto mr-auto w-[80%]">
              <p className="text-center text-[#8697A8] text-[16px] mt-[24px] font-normal">
                Set Password
              </p>
              <div className="mt-[55px]">
                <Controller
                  name="newPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                    maxLength: {
                      value: 13,
                      message: "Password must not be more than 13 characters",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="w-full">
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          {...field}
                          id="outlined-adornment-password"
                          type={viewPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {viewPassword ? (
                                  <img src={Eye2} alt="password" />
                                ) : (
                                  <img src={Eye} alt="password" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          placeholder="Password"
                        />
                      </FormControl>
                      {fieldState.error && (
                        <p className="text-[#FF0101] text-xs mt-[3px]">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>

              <div className="mt-[22.86px]">
                <Controller
                  name="confirmPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                    maxLength: {
                      value: 13,
                      message: "Password must not be more than 13 characters",
                    },
                    validate: validatePasswordMatch,
                  }}
                  render={({ field, fieldState }) => (
                    <div className="w-full">
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          {...field}
                          id="outlined-adornment-password"
                          type={confirmViewPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleConfirmShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {confirmViewPassword ? (
                                  <img src={Eye2} alt="password" />
                                ) : (
                                  <img src={Eye} alt="password" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          placeholder="Confirm Password"
                        />
                      </FormControl>
                      {fieldState.error && (
                        <p className="text-[#FF0101] text-xs mt-[3px]">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>

              <div className="flex gap-x-[10px] justify-between mt-[25px]">
                <Button
                  className="sign-up-btn prev"
                  type="submit"
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Back
                </Button>
                <Button
                  className="sign-up-btn next"
                  type="button"
                  onClick={handleResetSubmit}
                >
                  {loading ? (
                    <CircleLoader color="#ffffff" loading={loading} size={20} />
                  ) : (
                    "Login"
                  )}
                </Button>
              </div>
              <p className="text-center text-base mt-[48px] font-normal text-[#0081FF]">
                <Link to="/login" className="hover:opacity-80">
                  {" "}
                  Have an account?
                </Link>
              </p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
