import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface linkState {
  url: string;
}

const initialState: linkState = {
  url: "",
};

const linkSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    set_Link: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
  },
});

export const { set_Link } = linkSlice.actions;
export default linkSlice.reducer;
