import React, { useState, useEffect } from "react";
import Mail from "../../icons/Dark.svg";
import Eye from "../../icons/Eye.svg";
import Eye2 from "../../icons/eye-regular.svg";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../services/authService";
import { SET_LOGIN, SET_TOKEN, SET_USER } from "../../redux/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import CircleLoader from "react-spinners/CircleLoader";
import { SET_NEW_SECRETARY } from "../../redux/features/SecretarySlice";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { toast } from "react-toastify";

const initialState = {
  email: "",
  password: "",
};

const SignIn = () => {
  const dispatch = useDispatch();
  const [viewPassword, setViewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const { password, email } = formData;
  const navigate = useNavigate();

  const handleShowPassword = () => {
    setViewPassword((previousValue): any => !previousValue);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const token = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  localStorage.setItem("authToken", token);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const handleFormSubmit = handleSubmit(async (formData) => {
    setLoading(true);
    try {
      const userData = {
        email: formData.email,
        password: formData.password,
      };
      const data = await loginUser(userData, token);
      if (data?.data?.token) {
        // Successful login
        const isOrganizationVerified = data?.data?.user?.organization?.verified;
        const email = data?.data?.user?.organization?.email;
        if (isOrganizationVerified) {
          // If organization is verified, proceed with login
          await dispatch(SET_LOGIN(true));
          await dispatch(SET_USER(data?.data?.user));
          await dispatch(SET_TOKEN(data?.data?.token));
          localStorage.setItem('token', data?.data?.token);
          await dispatch(SET_NEW_SECRETARY(data));
          navigate("/");
        } else {
          toast.error(`Please enter the OTP pin sent to your email ${email} to verify your account or resend code`);
          navigate("/verify");
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  });
  
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="w-[40.6%] sign-in-form px-[50px] py-[60px] mr-auto ml-auto  bg-white shadow-lg shadow-slate-100 rounded-[11.3px]">
        <h1 className="text-[30px] font-bold text-[#44566C] text-center">
          Welcome to <span className="text-[#0081FF]">Book Time</span>{" "}
        </h1>
        <p className="text-[#8697A8] w-[52%] text-base font-normal text-center mr-auto ml-auto mt-6 welcome-text">
          Welcome Back, Please login to your account.
        </p>
        <form
          onSubmit={handleFormSubmit}
          className="w-[80%] mr-auto ml-auto sign-in-form-inner"
        >
          <div className="mt-[55px] email-container">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Enter a valid email. E.g, example@gmail.com",
                },
              }}
              render={({ field, fieldState }) => (
                <div className="w-full">
                  <FormControl fullWidth>
                    <OutlinedInput
                      {...field}
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={Mail} alt="Mail" />
                        </InputAdornment>
                      }
                      placeholder="Email"
                    />
                  </FormControl>
                  {fieldState.error && (
                    <p className="text-[#FF0101] text-xs mt-[3px]">
                      {fieldState.error.message}
                    </p>
                  )}
                </div>
              )}
            />
          </div>
          <div className="mt-[22.6px]">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
                maxLength: {
                  value: 13,
                  message: "Password must not be more than 13 characters",
                },
              }}
              render={({ field, fieldState }) => (
                <div className="w-full">
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      {...field}
                      id="outlined-adornment-password"
                      type={viewPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {viewPassword ? (
                              <img src={Eye2} alt="password" />
                            ) : (
                              <img src={Eye} alt="password" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Password"
                    />
                  </FormControl>
                  {fieldState.error && (
                    <p className="text-[#FF0101] text-xs mt-[3px]">
                      {fieldState.error.message}
                    </p>
                  )}
                </div>
              )}
            />
          </div>
          {/* BUTTON */}
          <div className="w-full mt-[25px]">
            <Button className="sign-in-btn" type="submit" disabled={loading}>
              {loading ? (
                <CircleLoader color="#ffffff" loading={loading} size={20} />
              ) : (
                "Login"
              )}
            </Button>
          </div>
        </form>
        <div className="flex justify-between mt-[55px] w-[80%] mr-auto ml-auto">
          <p className="text-center text-base font-normal text-[#0081FF] ">
            <Link to="/signup" className="hover:opacity-80">
              Get Started
            </Link>
          </p>
          <p className="text-center text-base font-normal text-[#0081FF] ">
            <Link to="/forgotpassword" className="hover:opacity-80">
              Forgot Password?
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
