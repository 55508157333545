import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Add from "../../icons/mingcute_add-fill.svg";
import Search from "../../icons/search.svg";
import Modal from "react-modal";
import { useModalContext } from "../../components/utilities/ModalContext";
import ReactPaginate from "react-paginate";
import { ArrowDown, CloseIcon, DotsIcon, ExcelIcon } from "../../icons/icon";
import "react-phone-number-input/style.css";
import { fetchWorkers } from "../../services/staffService";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { fetchDept, fetchLevels } from "../../services/authService";

Modal.setAppElement("#root");
const ReceptionistAllStaff = () => {
  const [searchValue, setSearchValue] = useState("");
  const {
    modallOpen,
    modalStaffDetails,
    modalOpenViewDetails,
    modalCloseViewDetails,
  } = useModalContext();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [selectedUser, setSelectedUser] = useState({});
  const [staffData, setStaffData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [showLevel, setShowLevel] = useState([]);
  const [showDept, setShowDept] = useState([]);
  const [dropdownVisibility2, setDropdownVisibility2] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    (selectedUser as any)?.phoneNumber || ""
  );
  const [selectedItemPopUpId, setSelectedItemPopUpId] = useState("");

  const popUpRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(popUpRef, () => {
    setSelectedItemPopUpId("");
  });
  function formatPhoneNumber(phoneNumber: any) {
    if (phoneNumber.startsWith("0")) {
      return phoneNumber.replace(/^0/, "(+234) ");
    }
    return phoneNumber;
  }

  const tokenFromStorage = localStorage.getItem("token") || null;
  const tokens = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });

  const fetchAllStaff = async () => {
    try {
      let tokenToUse;
      if (adminUser || secretaryUser) {
        tokenToUse = tokens;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const data = await fetchWorkers(tokenToUse, "");
      setStaffData(data?.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllStaff();
  }, []);

  let allStaffData = useSelector(
    (state: any) => state.allStaff?.staffList?.data
  );

  allStaffData = staffData?.map((item: any) => ({
    ...item,
    phoneNumber: formatPhoneNumber(item.phoneNumber),
  }));

  const levels = useSelector((state: any) => state.levels?.levels?.data);
  const levelIds = levels;

  const departments = useSelector(
    (state: any) => state.departments?.departments?.data
  );
  const deptIds = departments;

  const fetchAllLevels = async () => {
    try {
      let tokenToUse;
      if (adminUser || secretaryUser) {
        tokenToUse = tokens;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const allLevels = await fetchLevels(tokenToUse);
      setShowLevel(allLevels?.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllLevels();
  }, []);

  const fetchAllDept = async () => {
    let tokenToUse;
    if (adminUser || secretaryUser) {
      tokenToUse = tokens;
    } else {
      tokenToUse = tokenFromStorage;
    }
    try {
      const allDept = await fetchDept(tokenToUse);
      setShowDept(allDept?.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllDept();
  }, []);

  const handleSearchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    try {
      let tokenToUse;
      if (adminUser) {
        tokenToUse = tokens;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const allStaff = await fetchWorkers(tokenToUse, value); // Pass the search value to the backend
      setStaffData(allStaff?.data);
      setSearchValue(value);
      setCurrentPage(0);
    } catch (err) {}
  };

  const staffIdToNameMap: Record<string, string> = {};
  const staffIdToNameMaps: Record<string, string> = {};

  showLevel?.forEach((level: any) => {
    staffIdToNameMap[level._id] = `${level.levelName}`;
  });

  showDept?.forEach((currDept: any) => {
    staffIdToNameMaps[currDept._id] = `${currDept?.departmentName}`;
  });

  const filteredStaff = staffData?.filter((staff: any) => {
    const filterNameAndEmail =
      staff?.firstName?.toLowerCase().includes(searchValue.toLowerCase()) ||
      staff?.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
      (staff?.level &&
        staffIdToNameMap[staff.level]
          ?.toLowerCase()
          .includes(searchValue.toLowerCase())) ||
      (staff.currDept &&
        staffIdToNameMaps[staff.currDept]
          ?.toLowerCase()
          .includes(searchValue.toLowerCase()));
    return filterNameAndEmail;
  });

  const pageCount = Math.ceil(filteredStaff?.length / itemsPerPage);

  const handlePageChange = (selectedPage: any) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = filteredStaff?.slice(offset, offset + itemsPerPage);

  const role = useSelector((state: any) => state.auth?.user?.role);

  const secretaryUser = role === "secretary";
  const adminUser = role === "admin";

  const sortedTableDataWithNames = currentPageData
    ? currentPageData.map((staff: any) => {
        const dept = deptIds?.find(
          (dept: any) => dept._id === staff.department
        );
        const departmentName = dept ? `${dept.departmentName} ` : "";

        const level = levelIds?.find((level: any) => level._id === staff.level);
        const levelName = level ? `${level.levelName} ` : "";

        return {
          ...staff,
          departmentName,
          levelName,
        };
      })
    : [];

  const columnsToExport = [
    "firstName",
    "lastName",
    "email",
    "phoneNumber",
    "levelName",
    "departmentName",
    "gender",
    "oracleNumber",
  ];

  const filteredDataForExport = sortedTableDataWithNames.map((item: any) => {
    const filteredItem: Record<string, any> = {};
    columnsToExport?.forEach((column) => {
      filteredItem[column] = item[column];
    });
    return filteredItem;
  });

  function convertToCSV(data: any) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((staffData: any) =>
      Object.values(staffData).join(",")
    );
    return [header, ...rows].join("\n");
  }

  const handleDownload = () => {
    const csvData = convertToCSV(filteredDataForExport);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "allStaff.csv";
    a.click();
    URL.revokeObjectURL(url);
    setDropdownVisibility2(false);
  };

  const toggleDropDownVisibility2 = () => {
    setDropdownVisibility2(!dropdownVisibility2);
  };

  useEffect(() => {
    setPhoneNumber((selectedUser as any)?.phoneNumber || "");
  }, [selectedUser]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisibility2(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const capitalizeFirstLetter = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const renderDetails = (item: any) => {
    if (selectedItemPopUpId === item._id) {
      return (
        <div>
          <div className="staff-dots-modal shadow-xl" ref={popUpRef}>
            <ul className="h-[100%] w-[160px] z-50 pt-2">
              {secretaryUser && (
                <li
                  onClick={() => {
                    setSelectedItemId(item?._id);
                    modalOpenViewDetails();
                    setSelectedItemPopUpId("");
                    setSelectedUser(item);
                  }}
                  className="text-[#44566c] text-[14px] hover:bg-[#F8FAFB] cursor-pointer"
                >
                  <button className="w-full text-left pl-5 pr-2 py-[10px]">
                    View Details
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      );
    }
    return null;
  };

  const viewDetails = () => {
    return (
      <Modal
        isOpen={modalStaffDetails}
        onRequestClose={() => {
          modalCloseViewDetails();
        }}
        contentLabel="Add New Staff Modal"
        className="w-[35%] bg-[white]  rounded-[12px] py-[30px] max-w-[800px]"
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className="w-[95%] mx-auto">
          <div className="flex justify-end mb-[10px]">
            <div
              onClick={() => {
                modalCloseViewDetails();
                window.location.reload();
              }}
              className="cursor-pointer"
            >
              <CloseIcon />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex">
              <p className="rounded-[7px] bg-[#F3F6FF] p-[10px] leading-none text-[#0081FF] uppercase w-fit ml-4 font-bold">
                {" "}
                {`${(selectedUser as any)?.firstName?.charAt(0) || ""}${
                  (selectedUser as any)?.lastName
                    ? ` ${(selectedUser as any)?.lastName
                        .split(" ")[0]
                        ?.charAt(0)}`
                    : ""
                }`}
              </p>
              <p
                className="ml-[12px] text-[#44566C] font-semibold"
                style={{ fontFamily: "Inter" }}
              >
                {(selectedUser as any).firstName}{" "}
                {(selectedUser as any).lastName}
                <br />
                <span className="text-[14px] font-medium">
                  {(selectedUser as any).email}
                </span>
              </p>
            </div>
          </div>
          <div className="w-[71%] mx-auto">
            <p className="mt-8 text-[#44566C] font-bold text-[14px]">
              Phone Number: &nbsp;
              {(selectedUser as any).phoneNumber}
            </p>
            <p className="mt-3 text-[#44566C] font-bold text-[14px]">
              Gender: &nbsp;
              {(selectedUser as any).gender
                ? capitalizeFirstLetter((selectedUser as any).gender)
                : ""}
            </p>
            {!secretaryUser && (
              <p className="mt-3 text-[#44566C] font-bold text-[14px]">
                Oracle Number: &nbsp;
                {(selectedUser as any).oracleNumber}
              </p>
            )}
            <p className="mt-3 text-[#44566C] font-bold text-[14px]">
              Level: &nbsp;
              {`${capitalizeFirstLetter(
                levelIds?.find(
                  (level: any) => level?._id === (selectedUser as any)?.level
                )?.levelName || "N/A"
              )}`}
            </p>

            <p className="mt-3 text-[#44566C] font-bold text-[14px]">
              Department: &nbsp;
              {`${capitalizeFirstLetter(
                deptIds?.find(
                  (department: any) =>
                    department?._id === (selectedUser as any)?.department
                )?.departmentName || "N/A"
              )}`}
            </p>
          </div>
          <div className="w-full flex justify-center">
            <button
              type="button"
              className="w-[302px] mx-auto h-[56px] bg-[#0081FF] text-center text-white text-[16px] font-semibold mt-5 rounded cursor-pointer hover:opacity-80"
              onClick={() => {
                modalCloseViewDetails();
                window.location.reload();
              }}
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      <div
        className="bg-white flex flex-wrap justify-between gap-[18px] border
    border-[#dedede] border-solid px-[21px] py-6 mt-[33px] visitors-search-bar
    mb-[33px]"
      >
        <div className="flex items-center px-4 py-2 border border-[#EAEAEA] border-solid flex-1 rounded-[3px] visitors-search-bar-inner">
          <img
            src={Search}
            alt="Search"
            className="w-[20px] h-[20px] mr-[9px]"
          />
          <input
            type="search"
            placeholder="Search"
            className="flex-1"
            name="searchValue"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </div>
        {!secretaryUser && (
          <button
            className="bg-[#0081FF] w-[183px] text-sm rounded-[4px] py-2 text-white flex items-center justify-center add-schedule-btn"
            onClick={modallOpen}
          >
            <img src={Add} alt="add-btn" />
            <p className="ml-2 text-sm font-normal">Add New Staff</p>
          </button>
        )}

        {!secretaryUser && (
          <div className="export-btn-container">
            <div
              className="flex w-[96px] text-sm rounded-[4px] py-2 items-center justify-center boder-solid border-2 border-[#EAEAEA] text-[#666] font-medium export-btn cursor-pointer"
              onClick={() => {
                toggleDropDownVisibility2();
              }}
            >
              <button className="cursor-pointer">Export</button>
              <span className="cursor-pointer">
                <ArrowDown />
              </span>
            </div>
            {dropdownVisibility2 && (
              <div>
                <ul className="dropdown w-[96px] shadow-md text-center ">
                  <li
                    className="text-[#44566c] h-[35px] text-[12px] cursor-pointer flex items-center justify-center excel-container"
                    onClick={handleDownload}
                  >
                    <ExcelIcon />
                    <p className="ml-[10px] text-[#44566C] font-normal text-[15px]">
                      Excel
                    </p>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="visitors-table bg-white shadow-sm  pb-[27px] px-2 w-full">
        <div style={{ maxWidth: "100%", overflowX: "auto" }}>
          <table className="w-full table-content">
            <thead className="w-full text-[14px] h-[57px] bg-[#FAFAFA] ">
              <tr>
                <th className="w-[170px] rounded-l-[10px] text-[#44566C] text-sm font-semibold text-left pl-[16px]">
                  Name
                </th>
                <th className="w-[170px] text-[#44566C] text-sm font-semibold text-left">
                  Email
                </th>
                <th className="w-[170px] text-[#44566C] text-sm font-semibold text-left">
                  Phone Number
                </th>
                {!secretaryUser && (
                  <th className="w-[170px] text-[#44566C] text-sm font-semibold text-left">
                    Oracle Number
                  </th>
                )}
                <th className="w-[170px] text-[#44566C] text-sm font-semibold text-left">
                  Level
                </th>
                <th className="w-[170px] text-[#44566C] text-sm font-semibold text-left">
                  Department
                </th>

                <th className="w-[30px] rounded-r-[10px]"></th>
              </tr>
            </thead>

            <tbody>
              {currentPageData && currentPageData?.length > 0 ? (
                currentPageData?.map((item: any) => (
                  <tr key={item._id}>
                    <td className="text-[#44566c] text-[14px] capitalize font-medium pt-[19px] flex items-center">
                      <p className="rounded-[7px] bg-[#F3F6FF] p-[10px] leading-none text-[#0081FF]  uppercase w-fit ml-4 font-bold">
                        {" "}
                        {`${item.firstName ? item.firstName?.charAt(0) : ""}${
                          item.lastName ? item.lastName?.charAt(0) : ""
                        }`}
                      </p>
                      <p className="ml-[12px]" style={{ fontFamily: "Inter" }}>
                        {`${item.firstName} ${item.lastName}`}
                      </p>
                    </td>
                    <td className="text-[#44566c] text-[14px] pt-[18px]">
                      {item.email}
                    </td>
                    <td className="text-[#44566c] text-[14px] pt-[18px]">
                      {item.phoneNumber}
                    </td>
                    {!secretaryUser && (
                      <td className="text-[#44566c] text-[14px] pt-[18px]">
                        {item.oracleNumber}
                      </td>
                    )}
                    <td className="text-[#44566c] text-[14px] pt-[18px] capitalize">
                      {levelIds?.find(
                        (currLevel: any) => currLevel._id === item.level
                      )
                        ? levelIds?.find(
                            (currLevel: any) => currLevel._id === item.level
                          )?.levelName
                        : "N/A"}
                    </td>
                    <td className="text-[#44566c] text-[14px] pt-[18px] capitalize">
                      {deptIds?.find(
                        (currDept: any) => currDept._id === item.department
                      )
                        ? deptIds?.find(
                            (currDept: any) => currDept._id === item.department
                          )?.departmentName
                        : "N/A"}
                    </td>

                    {!secretaryUser && (
                      <td className="text-[#44566c] text-[14px] pt-[18px]">
                        <div
                          className="flex justify-center items-center h-[30px] cursor-pointer"
                          onClick={() => {
                            if (selectedItemId === item._id) {
                              setSelectedItemId("");
                              setSelectedItemPopUpId("");
                            } else {
                              setSelectedItemId(item._id);
                              setSelectedItemPopUpId(item._id);
                            }
                          }}
                        >
                          <button className="text-[18px]">
                            <DotsIcon />
                          </button>
                        </div>
                        {renderDetails(item)}
                      </td>
                    )}

                    {secretaryUser && (
                      <td className="text-[#44566c] text-[14px] pt-[18px]">
                        <div
                          className="flex justify-center items-center h-[30px] cursor-pointer"
                          onClick={() => {
                            if (selectedItemId === item._id) {
                              setSelectedItemId("");
                              setSelectedItemPopUpId("");
                            } else {
                              setSelectedItemId(item._id);
                              setSelectedItemPopUpId(item._id);
                            }
                          }}
                        >
                          <button className="text-[18px]">
                            <DotsIcon />
                          </button>
                        </div>
                        {renderDetails(item)}
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No data found</td>
                </tr>
              )}
              {viewDetails()}
            </tbody>
          </table>
        </div>

        <div className="flex pagination-comp">
          <ReactPaginate
            className="flex bg-white mx-auto h-[40px] text-sm text-[#44566c]
          rounded-[10px] pagination mt-[33px]"
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            previousLinkClassName={"previous-page"}
            nextLinkClassName={"next-page"}
            disabledClassName={"disabled-page"}
            activeClassName={"active-page"}
            breakLabel={"..."}
          />
        </div>
      </div>
    </div>
  );
};

export default ReceptionistAllStaff;
