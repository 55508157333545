import React, { useState } from "react";
import Avartar from "../icons/User-avatar.svg.png";
import User from "../icons/circle-user-regular (1).svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MenuCloseIcon, MenuIcon } from "../icons/icon";
import { motion } from "framer-motion";
import Notification from "./Notification";

interface NavbarProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const Navbar: React.FC<NavbarProps> = ({ show, setShow }) => {
  // AT SIGN UP
  const companyNames = useSelector(
    (state: any) => state.auth?.newOrganization?.organizationName
  );
  const name = companyNames;

  const companyLogos = useSelector(
    (state: any) => state.auth?.newOrganization?.logo
  );
  const icon = companyLogos;


  // AT LOGIN
  const companyLogoss = useSelector(
    (state: any) => state.auth?.user?.organization?.logo
  );

  const icons = companyLogoss;

  const companyNamess = useSelector(
    (state: any) => state.auth?.user?.organization?.organizationName
  );

  const names = companyNamess;

  const handleMenuClick = () => {
    setShow(!show);
  };

  const role = useSelector((state: any) => state.auth?.user?.role);
  const showNavbar = useSelector((state: any) => state?.navbarDisplay?.showNavbar);



  return (
    <>
      {showNavbar &&
        <nav className="bg-white w-full py-[10px] flex justify-between items-center pl-[37px] pr-[50px] navbar fixed">
          <div className="flex items-center">
            <motion.div
              onClick={handleMenuClick}
              className="hidden navbar-menu-icon mr-[20px]"
              initial={false}
              animate={{ rotate: show ? 0 : 180 }}
              transition={{ ease: "easeOut", duration: 0.3 }}
            >
              {show ? <MenuCloseIcon /> : <MenuIcon />}
            </motion.div>
            <Link to="/" onClick={() => setShow(false)}>
              {icons || icon ? (
                <img
                  src={icons ? icons : icon}
                  alt="Company Logo"
                  className="h-[60px]  w-[60px] rounded-full mr-[20px] shadow"
                />
              ) : (
                <img
                  src={User}
                  alt="Company Logo"
                  className="h-[60px]  w-[60px] rounded-full mr-[20px] shadow"
                />
              )}
            </Link>
            <p className="font-black text-lg text-[#44566c]">
              {names ? names : name}
            </p>
          </div>
          <div onClick={() => setShow(false)} className="flex">
            <Notification />
          </div>
        </nav>
      }
    </>
  );
};

export default Navbar;
