import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  fetch1DaySchedule,
  visitReceptionistFilter,
} from "../../services/VisitorService";



interface AllVisitors {
  _id: string;
  staff: string;
  visitorName: string;
  visitorEmail: string;
  reasonForVisiting: string;
  timeOfVisiting: string;
  status: string;
  organization: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface AllData {
  status: boolean;
  message: string;
  data: {
    data: AllVisitors[];
    page: string; // Add page property
    limit: string; // Add limit property
    totalPages: number; // Add totalPages property
  };
}

const Cards2 = () => {
  const [pendingAndActiveData, setPendingAndActiveData] = useState<AllData[]>([]);

  const token = useSelector((state: any) => {
    if (state.auth?.isLoggedIn) {
      return state.auth?.token;
    } else {
      return null;
    }
  });
  
  const role = useSelector((state: any) => state.auth?.user?.role);
  const secretaryUser = role === "secretary";
  const adminUser = role === "admin";

  const tokenFromStorage = localStorage.getItem("token") || null;

  const handlePageChange = async (selectedPage: { selected: number }) => {
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      const formattedStartDate = today.toISOString();
      const formattedEndDate = tomorrow.toISOString();
      const itemsPerPage = 10;
      const pendingStatus = "pending";
      const activeStatus = "active";
      let tokenToUse;
      if (adminUser || secretaryUser) {
        tokenToUse = token;
      } else {
        tokenToUse = tokenFromStorage;
      }
      const pendingData = await visitReceptionistFilter(
        tokenToUse,
        formattedStartDate,
        formattedEndDate,
        pendingStatus,
        selectedPage.selected + 1,
        itemsPerPage
      );
      const activeData = await visitReceptionistFilter(
        tokenToUse,
        formattedStartDate,
        formattedEndDate,
        activeStatus,
        selectedPage.selected + 1, // Use the selected page number
        itemsPerPage
      );
      const combinedData: AllData[] = [pendingData, activeData];
      setPendingAndActiveData(combinedData);
    } catch (error) {
    }
  };

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    today.setHours(0, 0, 0, 0);
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow.setDate(today.getDate() + 1); 

    const formattedStartDate = today.toISOString(); 
    const formattedEndDate = tomorrow.toISOString(); 
    handlePageChange({ selected: 0 });
  }, []); 

  pendingAndActiveData.forEach((data) => {
    const reasons = data.data.data.map((visitor) => visitor.visitorName);
  });

  return (
    <div>
      <div >
        {pendingAndActiveData.length > 0 ? (
          pendingAndActiveData
            .filter((data) =>
              data.data.data.some((visitor) => visitor.status === "pending")
            )
            .map((data, dataIndex) => (
              <div
                key={dataIndex}
                className="grid lg:grid-cols-6 md:grid-cols-3 h-[200px] hidecard-content sm:grid-cols-2 gap-[17px] mt-[26px] grid-container"
                style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
              >
                {data.data.data.map((visitor, visitorIndex) => (
                  <div
                    key={visitorIndex}
                    className="bg-white flex flex-col shadow border border-solid border-[#EBEBEB] text-center rounded-[10px] px-[23px] py-[17px] items-center"
                    style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}      
                  >
                    <p className="rounded-[7px] bg-[#F3F6FF] p-[10px] leading-none text-[#0081FF] text-[13px] uppercase font-bold w-fit mb-[23px]">
                      {" "}
                      {`${
                        visitor.visitorName
                          ? visitor.visitorName.split(" ")[0]?.charAt(0)
                          : ""
                      }${
                        visitor.visitorName &&
                        visitor.visitorName.split(" ").length > 1
                          ? visitor.visitorName.split(" ")[1]?.charAt(0)
                          : ""
                      }`}
                    </p>
                    <p
                      className=" text-[#4D5E80] mb-[23px] text-[13px] font-bold capitalize"
                      style={{
                        overflowWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {visitor.visitorName}
                    </p>
                    <p className="text-[#4D5E80] text-[13px] font-bold">
                      {new Date(visitor.timeOfVisiting).toLocaleString(
                        "en-US",
                        {
                          timeZone: "Africa/Lagos",
                        }
                      )}
                    </p>
                  </div>
                ))}
              </div>
            ))
        ) : (
          <div className="rounded-[10px] bg-[white] border border-solid border-[#EBEBEB] h-[148px] flex items-center justify-center mt-[26px] text-2xl text-[#4D5E80] col-span-6">
            <p className="text-center">There are no pending visitors</p>
          </div>
        )}

        {pendingAndActiveData.length > 0 ? (
          pendingAndActiveData
            .filter((data) =>
              data.data.data.some((visitor) => visitor.status === "active")
            )
            .map((data, dataIndex) => (
              <div
                key={dataIndex}
                className="grid lg:grid-cols-6 md:grid-cols-3 h-[200px] hidecard-content sm:grid-cols-2 gap-[17px] mt-[26px] grid-container"
                style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
              >
                {data.data.data.map((visitor, visitorIndex) => (
                  <div
                    key={visitorIndex}
                    className="bg-white flex flex-col shadow border border-solid border-[#EBEBEB] text-center rounded-[10px] px-[23px] py-[17px] items-center"
                    style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}      
                  >
                    <p className="rounded-[7px] bg-[#F3F6FF] p-[10px] leading-none text-[#0081FF] text-[13px] uppercase font-bold w-fit mb-[23px]">
                      {" "}
                      {`${
                        visitor.visitorName
                          ? visitor.visitorName.split(" ")[0]?.charAt(0)
                          : ""
                      }${
                        visitor.visitorName &&
                        visitor.visitorName.split(" ").length > 1
                          ? visitor.visitorName.split(" ")[1]?.charAt(0)
                          : ""
                      }`}
                    </p>
                    <p
                      className=" text-[#4D5E80] mb-[23px] text-[13px] font-bold capitalize"
                      style={{
                        overflowWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {visitor.visitorName}
                    </p>
                    <p className="text-[#4D5E80] text-[13px] font-bold">
                      {new Date(visitor.timeOfVisiting).toLocaleString(
                        "en-US",
                        {
                          timeZone: "Africa/Lagos",
                        }
                      )}
                    </p>
                  </div>
                ))}
              </div>
            ))
        ) : (
          <div className="rounded-[10px] bg-[white] border border-solid border-[#EBEBEB] h-[148px] flex items-center justify-center mt-[26px] text-2xl text-[#4D5E80] col-span-6">
            <p className="text-center">There are no active visitors</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cards2;
