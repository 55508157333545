import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField, Button, FormHelperText } from "@mui/material";
import Modal from "react-modal";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { CloseIcon, DotsIcon, PersonIcon, WhiteCloseIcon } from "../../icons/icon";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useModalContext } from "../../components/utilities/ModalContext";
import { deleteDepartments, deleteLevels, editDepartment, fetchDept, fetchLevels } from "../../services/authService";
import {  SET_DEPARTMENTS } from "../../redux/features/deptSlice";
import { SET_LEVELS } from "../../redux/features/levelSlice";
import CircleLoader from "react-spinners/CircleLoader";

type Props = {}
const initialState = {
    id: "",
  };

  const initialState2 = {
    _id: "",
    name: ""
  };

const UpdateDepartment = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedItemPopUpId, setSelectedItemPopUpId] = useState("");
    const [selectedUser, setSelectedUser] = useState({});
    const [formData, setFormData] = useState(initialState);
    const [dataForm, setDataForm] = useState(initialState2);
    const [showDept, setShowDept] = useState([]);
    const {
      handleSubmit,
      control,
      reset,
      formState: { errors },
      setValue,
    } = useForm();
    const {
      modalDelDeptOpen,
      modalDeleteDeptClose,
      modalDeleteDeptOpen,
      modalEditDeptOpen,
      modalEditDepartmentClose,
      modalEditDepartmentOpen,
    } = useModalContext();
  
    const dispatch = useDispatch();

    const token = useSelector((state: any) => {
        if (state?.auth?.isLoggedIn) {
          return state?.auth?.token;
        } else {
          return null;
        }
      });
    
      const adminSignUp = useSelector(
        (state: any) => state.auth?.newOrganization?.admin?.role
      );

      const role = useSelector((state: any) => state.auth?.user?.role);
  
      const adminUser = role === "admin";
    
      const tokenFromStorage = localStorage.getItem("token") || null;


      const fetchAllDept = async () => {
        let tokenToUse;
        if (adminUser) {
          tokenToUse = token;
        } else {
          tokenToUse = tokenFromStorage;
        }
      try {
        const allDept = await fetchDept(tokenToUse);
        setShowDept(allDept?.data)
      } catch (err) {}
    };
    
    useEffect(() => {
      fetchAllDept()
    }, [])

      const departments = useSelector(
        (state: any) => state.departments?.departments?.data
      );
      const deptIds = departments;

      const sortedDeptIds = deptIds && deptIds.length > 0
  ? [...deptIds].sort((a, b) => a.departmentName.localeCompare(b.departmentName))
  : [];

      const popUpDeptRef = useRef<HTMLDivElement>(null);
      const dropdownRef = useRef<HTMLDivElement | null>(null);

      useOnClickOutside(popUpDeptRef, () => {
        setSelectedItemPopUpId("");
      });

        //DELETE DEPARTMENT
  const handleDeleteSubmit = async (e: any) => {
    e.preventDefault();
    let tokenToUse;

    // Check if the user has signed up (using adminSignUp)
    if (adminUser) {
      tokenToUse = token;
    } else {
      // Assume the user has logged in
      tokenToUse = tokenFromStorage;
    }
  
    if (selectedDepartment) {
      try {
        setLoading(true);
  
        // Call the deleteDepartments function
        const userData = { id: selectedDepartment };
        await deleteDepartments(tokenToUse, userData);
        // Reset the form data and selected department
        setFormData(initialState);
        setSelectedDepartment("");
        window.location.reload();
      
      } catch (error) {
      
        // toast.error("Failed to delete department");
      } finally {
        setLoading(false);
      }
    }
  };


  //EDIT DEPT

  const editDepartmentDetails = handleSubmit(async (dataForm) => {
    const userData = {id: selectedDepartment, name: dataForm.name, };
    setLoading(true);
    try {
      let tokenToUse;

      // Check if the user has signed up (using adminSignUp)
      if (adminSignUp !== true) {
        // User has signed up
        tokenToUse = tokenFromStorage;
      } else {
        // User has logged in
        tokenToUse = token;
      }
      const data = await editDepartment(userData, tokenToUse);
    
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setDataForm(initialState2);
      window.location.reload()
      // setSelectedDepartment("");
      // fetchAllStaff();
      // modalStaffClose();
    } catch (error) {
      setLoading(false);
      // setSelectedDepartment("");
      setDataForm(initialState2);
    }
  });
  
  

  const renderDepartmentDetails = (item: any) => {
    if (selectedItemPopUpId === item?._id) {
      return (
        <div>
          <div className="staff-dots-modal shadow-xl" ref={popUpDeptRef}>
            <ul className="h-[120px] w-[160px] z-50 pt-2">
              <li
                onClick={() => {
                  setSelectedDepartment(item?._id);
                  modalEditDepartmentOpen();
                  setSelectedItemPopUpId("");
                  setSelectedUser(item);
                }}
                className="text-[#44566c] text-[14px] hover:bg-[#F8FAFB] cursor-pointer"
              >
                <button className="w-full text-left pl-5 pr-2 py-[10px]">
                  Edit
                </button>
              </li>

              <li
                 className="text-[#fff] font-medium text-[14px] h-[40px] mt-3 rounded-[6px] bg-[#FF0101] hover:opacity-80 cursor-pointer mx-1"
                onClick={() => {
                  setSelectedDepartment(item?._id);
                  modalDeleteDeptOpen(selectedDepartment);
                  setSelectedItemPopUpId("");
                  setSelectedUser(item);
                }}
              >
                <button className="w-full text-left pl-5 pr-2 py-[10px]">
                  Delete
                </button>
              </li>
            </ul>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderDeleteModal = () => {
    return (
      <Modal
        isOpen={modalDelDeptOpen}
        onRequestClose={() => {
          modalDeleteDeptClose();
          // setFormData(initialState);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        contentLabel="Add New Staff Modal"
        className="w-[40%] bg-[white]  rounded-[12px] py-[30px] max-w-[880px]  create-dept-modal "
      >
        <div className="w-[85%] mx-auto ">
          <div className="w-20 mx-auto">
            <PersonIcon />
          </div>
          <h1 className="text-center text-[#44566C] text-[20px] font-semibold capitalize">
            Are you sure you want to Delete <br />
            {(selectedUser as any)?.departmentName}?
          </h1>
          <div className="flex gap-4 justify-center items-center mx-auto mt-6 flex-wrap">
            <button
              className="bg-[#F1F1F1] w-[200px] text-[#44566C] h-12 rounded-md hover:border-[2px] hover:border-[#C4C4C4] cursor-pointer"
              onClick={() => {
                modalDeleteDeptClose();
                setFormData(initialState);
              }}
            >
              Cancel
            </button>
           <form onSubmit={handleDeleteSubmit}>
              <button
                className="bg-[#FF0101] w-[232px] text-[#FCFCFD] h-12 rounded-md hover:opacity-80 cursor-pointer"
                type="submit"
                
              >
                Delete
              </button>
              </form>
          </div>
        </div>
      </Modal>
    );
  };

  const renderEditModal = () => {
    return (
      <Modal
        isOpen={modalEditDeptOpen}
        onRequestClose={() => {
          modalEditDepartmentClose();
          // setFormData(initialState);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(42, 42,42, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        contentLabel="Add New Staff Modal"
        className="w-[40%] bg-[white]  rounded-[12px] py-[30px] max-w-[880px]  create-dept-modal "
      >
          <div className="mx-auto z-50 w-[79.5%] justify-center justify-items-center">
          <div className="flex justify-end mb-[30px]">
            <div
              onClick={() => {
                modalEditDepartmentClose();
                reset();
                // setDepartments([]);
              }}
              className="cursor-pointer"
            >
              <CloseIcon />
            </div>
          </div>
          <h1 className="text-center text-[#44566c] text-3xl  font-bold">
            Edit <span className="text-[#0081ff]">Department</span>
          </h1>
          <form  
          // onSubmit={editDepartmentDetails}
          >
          <div className="mt-[38px] font-normal text-[15px] mr-[10px]">
            
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "DepartName name is required",
                  // minLength: {
                  //   value: 3,
                  //   message: "Name must be at least 3 characters",
                  // },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Department Name"
                    defaultValue={(selectedUser as any)?.departmentName || ""}
                    className="w-full  px-[19px] py-3 rounded"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    {...field}
                  />
                )}
              />
            </div>
          </form>
         
          <div className="w-full mt-[25px]">
            <Button
              className="create-dept-level-submit-btn"
              type="submit"
              disabled={loading}
              onClick={editDepartmentDetails}
            >
             Update
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div>
         <div className="w-full bg-white rounded-[20px] py-[22px] pr-[24px] pl-[14px] text-[#44566C] mt-[40px]">
        <p className="pl-5 text-[#44566C] font-semibold text-[15px]">
          Departments
        </p>
        {showDept && showDept?.length > 0 ? (
          showDept?.map((item: any) => (
            <div key={item?._id} className="flex mt-7 justify-between">
              <div className="flex">
                <p className="rounded-[7px] bg-[#F3F6FF] p-[10px] leading-none text-[#0081FF]  uppercase w-fit ml-4 font-bold">
                  {" "}
                  {`${
                    item.departmentName ? item.departmentName?.charAt(0) : ""
                  }`}
                </p>
                <p
                  className="ml-[12px] pt-2 text-[#44566C] text-[14px] font-normal"
                  style={{ fontFamily: "Inter" }}
                >
                  {item?.departmentName?.charAt(0)?.toUpperCase() +
                    item?.departmentName?.slice(1)}
                </p>
              </div>
              <div
                className="flex justify-center items-center h-[30px] cursor-pointer mr-6"
                onClick={() => {
                  if (selectedDepartment === item?._id) {
                    setSelectedDepartment(item?._id); 
                    setSelectedItemPopUpId("");
                  } else {
                    setSelectedDepartment(item?._id); // Select the department
                    setSelectedItemPopUpId(item?._id);
                  }
                }}
              >
                <button className="text-[18px]">
                  <DotsIcon />
                </button>
                {renderDepartmentDetails(item)}
              </div>
            </div>
          ))
        ) : (
          <tr>
            <td colSpan={4}>No data found</td>
          </tr>
        )}
        {renderDeleteModal()}
        {renderEditModal()}
      
      </div>
    </div>
  )
}

export default UpdateDepartment