// AuthGuard.tsx
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface AuthGuardProps {
  element: React.ReactNode;
  unauthorizedMessage?: string;
  isAdminSignUp?: boolean;
  isSecretary?: boolean; // New prop to indicate secretary
}

const AuthGuard: React.FC<AuthGuardProps> = ({ element, unauthorizedMessage }) => {
  const isLoggedIn = localStorage.getItem('token') != null;
  const adminUser = useSelector((state: any) => state.auth?.user?.role);

  const adminSignUp = useSelector(
    (state: any) => state.auth?.newOrganization?.admin?.role
  );

  const secretaryUser = useSelector((state: any) => state.auth?.user?.role);
  if (!isLoggedIn) {
    // Trigger a toast notification with the unauthorizedMessage
    toast.error(unauthorizedMessage || 'Unauthorized Access');
    // Redirect to the login page
    return <Navigate to="/login" />;
  }

  // Check if isAdminSignUp or isSecretary is true, and return the appropriate content
  if (adminSignUp || adminUser) {
    // You might want to handle admin sign-up logic here
    return <>{element}</>;
  }

  if (secretaryUser) {
    // You might want to handle secretary logic here
    return <>{element}</>;
  }

  return <>{element}</>;
};

export default AuthGuard;
