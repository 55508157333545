import axios from "axios";
import { toast } from "react-toastify";

export const Backend_URL = process.env.REACT_APP_AUTH_URL;

export const emailValidation = (email: any) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const updateName = async (nameData: any, token: string) => {
  try {
    const response = await axios.put(
      `${Backend_URL}/organization/name`,
      nameData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.statusText === "OK") {
      toast.success("Organization Name changed successfully");
    }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    if (error?.response?.data?.error) {
      toast.error(error?.response?.data?.error);
    } else {
      toast.error(message); 
        throw new Error(message); 
    }
  }
};

export const updateLogo = async (userData: any, token: string) => {
  try {
    const response = await axios.put(
      `${Backend_URL}/organization/logo`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.statusText === "OK") {
      toast.success("Organization Logo changed successfully");
    }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    if (error?.response?.data?.error) {
      toast.error(error?.response?.data?.error);
    } else {
      toast.error(message); 
      throw new Error(message); 
    }
  }
};

export const changePassword = async (userData: any) => {
  try {
    const response = await axios.put(
      `${Backend_URL}/user/password/${userData.id}`,
      userData
    );
    if (response.statusText === "OK") {
      toast.success("Password changed successfully");
    }
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    if (error?.response?.data?.error) {
      toast.error(error?.response?.data?.error);
    } else {
      toast.error(message); 
        throw new Error(message); 
    }
  }
};
